<div class="desc-provided-by-card">
    <div class="position-relative">
        <img src="assets/cards/default-provided-by-img.jpg" *ngIf="!data.cover_src || !data.cover_src[0]" class="desc-provided-by-cover-img" alt="provided by cover pic" />
        <img *ngIf="data.cover_src && data.cover_src[0]" [src]="data.cover_src[0]" class="desc-provided-by-cover-img" alt="provided by cover pic" />
        <div class="desc-provided-by-card-rating rounded-pill position-absolute px-2">
            <ng-container *ngFor="let star of [1, 2, 3, 4, 5]">
                <span [ngClass]="{'filled': star <= data.rating, 'empty': star > data.rating}" class="star">&#9733;</span>
            </ng-container>
        </div>
        <img [src]="data.src[0]" *ngIf="data.src && data.src[0]" class="desc-provided-by-profile-img position-absolute" alt="provided by merchant profile pic" />
        <img src="assets/icons/default-profile-pic.png" *ngIf="!data.src || !data.src[0]" class="desc-provided-by-profile-img position-absolute" alt="provided by merchant profile pic" />
    </div>
    <div class="mx-2">
        <h5 class="desc-provided-by-merchant-name text-truncate">{{data.name}}</h5>
        <div class="d-flex align-items-center justify-content-between">
            <h3 class="desc-provided-by-merchant-shop-name mb-1 text-truncate">{{data.shopName}}</h3>
            <a href="javascript:void(0)" (click)="openMerchantProfile()" class="desc-provided-by-view-profile">View profile</a>
        </div>
        <hr class="des-provided-by-hr">
        <h4 class="desc-provided-by-price mb-1">₹ {{data.min_price}} <span class="desc-provided-by-per-person">/person</span></h4>
        <hr class="des-provided-by-hr">
        <a class="me-2 des-provided-by-card-navigation text-truncate w-50" href="javascript:void(0)" (click)="openMap($event)"><img src="/assets/cards/km.svg"
                alt="Km icon"> {{data.distanceAway}} <img src="/assets/cards/link-icon.svg" alt="link icon"></a>
        <p class="desc-provided-by-card-duration mt-3" ><img src="assets/cards/duration.svg" alt="duration icon" style="margin-right: 4px;"> {{data.time_from}} - {{data.time_to}}</p>
    </div>
    <button type="button" class="btn des-provided-by-book-btn px-3" (click)="book_merchant.emit(data.id)">Book Now</button>
</div>