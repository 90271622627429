import { HttpClient } from '@angular/common/http';
import { Component, Inject } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/auth/auth.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-search-location-popup',
  templateUrl: './search-location-popup.component.html',
  styleUrls: ['./search-location-popup.component.css']
})
export class SearchLocationPopupComponent {

  popularLocations:any=[];
  userId:any;
  searchData:any="";

  constructor(private bottomSheetRef: MatBottomSheetRef<SearchLocationPopupComponent>, @Inject(MAT_BOTTOM_SHEET_DATA) public data: any,  private http: HttpClient,private router: Router,  public auth: AuthService){ 
    this.auth.userInfo.subscribe(info => {
      if (info !== null) {
        this.auth.UserType.subscribe(type => {
          if (type === "user") {
            this.userId = info.id;
          }
        })
      }
    })
   }

 async ngOnInit(){
  try{
    let indexCount: any = await this.http.get(environment.apiUrl + `/locations/get-all-location` , { responseType: 'json' }).toPromise();
    if (indexCount.status === "SUCCESS") {
      this.popularLocations = indexCount.location;
    }
  }catch(err){
    console.log(err)
  }
    

    
  }
  close(): void {
    this.bottomSheetRef.dismiss(); // This closes the bottom sheet
  }

  highlightInputGroup(para: any) {
    const inputGroup: any = document.querySelector(`.input-group.${para}`);
    inputGroup.classList.add('highlight-input-group');
  }

  removeHighlightInputGroup(para: any) {
    const inputGroup: any = document.querySelector(`.input-group.${para}`);
    inputGroup.classList.remove('highlight-input-group');
  }

  onLocationChange(name:any){
    if(this.searchData ==  "" && name == ''){
      return
    }
    if ((this.searchData && this.searchData.trim() !== '') || name != '') {
      let userInput:any ={};
      if(this.userId){
        userInput.userId = this.userId;
      }
      this.close();
      if(name != ''){
        this.http.post(environment.apiUrl + `/usersearch/create`, { ...userInput, page: 'home', searchData: name }, { responseType: 'json' }).subscribe(async (data: any) => { })
        this.router.navigate(['/search'], { queryParams: { q: name } });
      }else{
        this.http.post(environment.apiUrl + `/usersearch/create`, { ...userInput, page: 'home', searchData: this.searchData }, { responseType: 'json' }).subscribe(async (data: any) => { })
        this.router.navigate(['/search'], { queryParams: { q: this.searchData } });
      }
    } else {
      this.router.navigate(['/search']);
    }
  }

}
