import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SharedServiceService {

  constructor() { }

  formatDate(inputDate:any) {
    const months = [
      "January", "February", "March", "April", "May", "June",
      "July", "August", "September", "October", "November", "December"
    ];
  
    // Parse input date string
    const date = new Date(inputDate);
  
    // Extract components
    const year = date.getFullYear();
    const monthIndex = date.getMonth();
    const day = date.getDate();
  
    // Format date
    const formattedDate = `${months[monthIndex]} ${day}, ${year}`;
  
    return formattedDate;
  }
}
