import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DataService } from 'src/app/data.service';
import { Router } from '@angular/router';
import { FormatDateRangeService } from 'src/app/services/format-date-range.service';


interface details {
  id: any;
  name: any;
  shopName: any;
  src: any;
  cover_src: any;
  distanceAway: any;
  rating: any;
  min_price:any;
  time_from:any;
  time_to:any;
  latitude:any;
  longitude:any;
  locationName:any;
  locationid:any;
  category:any;
  difficulty:any;
  totalaltitude:any;
  numberofdays:any;
  available:any;
  merchantactivityid:any;
  totaldistance:any;
  dataFor:any;
  months:any;
  hasCoupon:any;
}



@Component({
  selector: 'app-des-provided-by-card',
  templateUrl: './des-provided-by-card.component.html',
  styleUrls: ['./des-provided-by-card.component.css']
})
export class DesProvidedByCardComponent {
  @Input() data: details;
  @Output() book_merchant = new EventEmitter<string>();
  @Output() enquire_merchant = new EventEmitter<string>();
  @Output() book_slot = new EventEmitter<any>();

  selectedSlot:any;


  constructor(public dataService: DataService, private router: Router, public formatDate: FormatDateRangeService) {
  }

  openSlot(slot:any){
    if(this.selectedSlot == slot){
      this.selectedSlot = null;
    }else{
      this.selectedSlot = slot;
    }
  }

  openMap(event: any) {
    event.stopPropagation()
    this.dataService.openMap(this.data.latitude, this.data.longitude);
  }

  openMerchantProfile(){
    let locationname = this.data.locationName.trim().replace(/[^a-zA-Z0-9\s]/g, '-').replace(/\s+/g, '-').toLowerCase();
    let name = this.data.name.trim().replace(/[^a-zA-Z0-9\s]/g, '-').replace(/\s+/g, '-').toLowerCase();
    this.router.navigate(['/merchant-profile','location',this.data.locationid,'merchantid',this.data.id,locationname,name])
  }

  book_slot_merchant(available:any, slot:any){
    if(available != 0){
      this.book_slot.emit({slot: slot, merchantId:this.data.id});
    }
  }

}
