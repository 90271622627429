import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { DataService } from 'src/app/data.service';


interface details {
  id: any;
  name: any;
  shopName: any;
  src: any;
  cover_src: any;
  distanceAway: any;
  rating: any;
  locationName:any;
  locationid:any;
  latitude:any;
  longitude:any;
}

@Component({
  selector: 'app-mindesc-provided-by-card',
  templateUrl: './mindesc-provided-by-card.component.html',
  styleUrls: ['./mindesc-provided-by-card.component.css']
})
export class MindescProvidedByCardComponent {
  @Input() data: details;

  constructor(public dataService: DataService, private router: Router) {}

  openMerchantProfile(){
    let locationname = this.data.locationName.trim().replace(/[^a-zA-Z0-9\s]/g, '-').replace(/\s+/g, '-').toLowerCase();
    let name = this.data.name.trim().replace(/[^a-zA-Z0-9\s]/g, '-').replace(/\s+/g, '-').toLowerCase();
    this.router.navigate(['/merchant-profile','location',this.data.locationid,'merchantid',this.data.id,locationname,name])
  }

  openMap(event: any) {
    event.stopPropagation()
    this.dataService.openMap(this.data.latitude, this.data.longitude);
  }
}
