import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, ViewChild } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import * as mapboxgl from 'mapbox-gl';
import { DataService } from 'src/app/data.service';
import { MobileActivitiesShowMoreComponent } from 'src/app/popups/mobile-activities-show-more/mobile-activities-show-more.component';
import { MobileReviewsShowMoreComponent } from 'src/app/popups/mobile-reviews-show-more/mobile-reviews-show-more.component';
import { WebActivitiesShowMoreComponent } from 'src/app/popups/web-activities-show-more/web-activities-show-more.component';
import { WebReviewsShowMoreComponent } from 'src/app/popups/web-reviews-show-more/web-reviews-show-more.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-rental-description',
  templateUrl: './rental-description.component.html',
  styleUrls: ['./rental-description.component.css']
})
export class RentalDescriptionComponent {
  homeData: any;
  locationName:any ="";
  locationId:any;
  userLocation = { latitude: 0, longitude: 0 };
  filteredRentalMerchants: any = [];
  filteredInventoryMerchants: any = [];
  rentalMerchant:any=[];
  userAgent: string = "";
  merchantId:any;
  merchantData: any;
  map!: mapboxgl.Map;
  style = 'mapbox://styles/mapbox/streets-v12';
  reviewPage: number = 1;
  reviews: any = [];
  loadMoreReviews: boolean = false;
  viewCount: number = 4;
  topAdventuristsLoadMore: boolean = false;
  topAdventuristsPage: number = 1;
  topAdventuristsData: any = [];
  providedByMerchant:any=[];
  variationid: any = [];
  variationValueId: any = [];
  variations: any = [];
  variationValues: any = [];
  inventoryVariations: any = [];
  inventoryVariationValues: any = [];
  inventoryVariationValueIds: any = [];
  allBikeCard: any = [];

  @ViewChild('activitycontentDiv') activitycontentDiv: ElementRef;
  @ViewChild('providedbyDiv') providedbyDiv: ElementRef;
  constructor(private router: Router, private http: HttpClient, private snackBar: MatSnackBar, private datePipe: DatePipe, public dialog: MatDialog,  private route: ActivatedRoute, private sanitizer: DomSanitizer, public dataService: DataService, private bottomSheet: MatBottomSheet, private elementRef: ElementRef) {
    this.homeData = this.router.getCurrentNavigation()?.extras.state?.['dataToPass'];
    if(!this.homeData){
      this.router.navigate(['/404-not-found']);
    }
    this.router.events.subscribe((event) => {
      setTimeout(() => {
        const element = this.elementRef.nativeElement.querySelector('.rental-description-page-fixed-margin-top');
        if (element) {
          element.scrollIntoView({ top: 0, behavior: 'smooth', block: 'start', inline: 'nearest' });
        }
      }, 500);
    });
   
    this.userAgent = window.navigator.userAgent;
  }

  ngOnInit() {
    if(localStorage.getItem('location')){
      let location = JSON.parse(localStorage.getItem('location'));
      this.locationName = location.locationname;
      this.locationId = location.id;
    }
    this.route.paramMap.subscribe(params => {
      this.merchantId = params.get('id');
      this.http.get(environment.apiUrl + `/merchants/get?id=${this.merchantId}`).subscribe(async (data: any) => {
        if (data.status === "SUCCESS" && data.merchant) {
          this.merchantData = data.merchant;
          const description = typeof  this.merchantData.description === 'string' ?  this.merchantData.description.trim() : '';
          const strippedDescription = description.replace(new RegExp('(<p><br></p>|<br>)$'), '').trim();
          if (strippedDescription) {
            this.merchantData.description = this.sanitizer.bypassSecurityTrustHtml( this.merchantData.description);
          } else {
            this.merchantData.description = '';
          }
          this.map = new mapboxgl.Map({
            container: 'map',
            style: this.style,
            zoom: 13,
            center: [this.merchantData.longitude, this.merchantData.latitude],
            accessToken: environment.mapbox.accessToken,
            interactive: false
          })
  
          const marker = new mapboxgl.Marker()
            .setLngLat([this.merchantData.longitude, this.merchantData.latitude])
            .addTo(this.map);
        }else{
          this.router.navigate(['/temporarily-unavailable']);
        }
        this.loadReviews();
        this.showMoreTopMerchants();
        this.loadMoreOtherVehicles();
      })
      this.loadFilteredRentalMerchants();
    })
  }

  ngAfterViewInit() {
    setTimeout(() => {
      const element = this.elementRef.nativeElement.querySelector('.rental-description-page-fixed-margin-top');
      if (element) {
        element.scrollIntoView({ top: 0, behavior: 'smooth', block: 'start', inline: 'nearest' });
      }
    })
  }


  async loadFilteredRentalMerchants() {
    // this.filterRentalMerchantsloading = true;
    let data: any = {};
    data.activityId = this.homeData.activityId;
    data.variations = [];
    data.variations.push({ variationtypeid: this.homeData.parentvariationtypeid, variationvalueid: this.homeData.parentvariationvalueid });
    data.variations.push({ variationtypeid: this.homeData.variationtypeid, variationvalueid: this.homeData.id });
    if (this.locationId != null) {
      data.locationId = this.locationId;
    }

    // if (this.rentalForm.value.inventoryvariations.length > 0) {
    //   this.rentalForm.value.inventoryvariations.forEach((x: any) => {
    //     x.variationvalueid = this.rentalForm.value.variations.find((y: any) => y.variationid == x.variationid).variationvalueid
    //   })
    // }
    // let bookingsDates: any = [];
    // let dates = this.getDates(new Date(this.rentalForm.value.date), new Date(this.rentalForm.value.dropdowndate));
    // bookingsDates = dates;
    // this.rentalForm.get('guest').setValue(bookingsDates.length);

    // if (this.rentalForm.value.inventoryvariations.length > 0) {
    //   let inventoryMerchants: any = [];
    //   let inventoryInputData: any = {};
    //   inventoryInputData.activityId = this.rentalForm.value.activityId;
    //   inventoryInputData.variations = [];
    //   this.rentalForm.value.inventoryvariations.forEach((x: any) => {
    //     inventoryInputData.variations.push({ variationid: x.variationid, variationvalueid: x.variationvalueid })
    //   })
    //   if (this.locationId != null) {
    //     inventoryInputData.locationId = this.locationId;
    //   }
    //   let inventoryRes: any = await this.http.post(environment.apiUrl + `/merchantinventories/filterMerchant`, inventoryInputData).toPromise();
    //   if (inventoryRes.status === "SUCCESS") {
    //     inventoryMerchants = inventoryRes.merchantinventories;
    //   }

    //   if (inventoryMerchants.length == 0) {
    //     this.snackBar.open('Some error occured. Please try again.', 'close', { duration: 2000, verticalPosition: 'top' });
    //   } else {
    //     this.filteredInventoryMerchants = [];
    //     for (let i = 0; i < inventoryMerchants.length; i++) {
    //       let inventoryRes: any = await this.http.post(environment.apiUrl + `/merchantinventories/checkavailabilitydates`, { bookingDate: bookingsDates, merchantInventoryId: inventoryMerchants[i].id }).toPromise();
    //       if (inventoryRes.status === "SUCCESS") {
    //         if (inventoryRes.availability) {
    //           this.filteredInventoryMerchants.push(inventoryMerchants[i]);
    //         }
    //       }
    //     }
    //   }
    // }
    this.http.post(environment.apiUrl + `/merchantactivities/filterMerchant`, data).subscribe(async (data: any) => {
      {
        if (data.status === "SUCCESS") {
          // this.filterRentalMerchantsloading = false;
          // this.showDateField = false;
          // this.showShadow = false;
          this.filteredRentalMerchants = [];
          this.rentalMerchant=[];
          this.providedByMerchant=[];
          for (let i=0; i<data.merchantactivities.length; i++){
            // if (this.filteredInventoryMerchants.find((y: any) => y.merchantid == data.merchantactivities[i].merchantid)) {
              data.merchantactivities[i].rating = Math.round(data.merchantactivities[i].average_star);
              try {
                let price_res:any = await this.http.get(environment.apiUrl + `/range/minpricebymerchantactivityid?merchantActivityId=${data.merchantactivities[i].id}`).toPromise();
                  if (price_res.status === "SUCCESS") {
                    data.merchantactivities[i].price= price_res.min_price;
                  }
              } catch (error) {
                console.log(error)
              }
              if(!this.filteredRentalMerchants.find((x:any)=> x.id === data.merchantactivities[i].id)?.id){
                this.filteredRentalMerchants.push(data.merchantactivities[i]);
              }
            }
          // }
          this.filteredRentalMerchants.sort((a, b) => Number(a.price) - Number(b.price));
          this.filteredRentalMerchants.forEach((merchant: any) => {
            let values: any = {};
            merchant.src = [];
            values['src']= [];
            values['id'] = merchant.merchantid;
            values['shopName'] = merchant.merchantshopname;
            values['name'] = merchant.merchantname;
            values['locationName'] = merchant.merchantlocationname;
            values['locationid'] = merchant.merchantlocationid;
            values['rating'] = merchant.merchantavgrating;
            values['min_price'] = merchant.price;
            values["time_from"] = merchant.timefrom;
            values["time_to"] = merchant.timeto;
            values['availability'] = true;
            values['category'] = "bikerental";
            values['merchantactivityid'] = merchant.id;
            try {
              this.http.get(environment.apiUrl + `/uploads/listbydatafor?datafor=merchantprofile&dataforid=${merchant.merchantid}`).subscribe((data: any) => {
                if (data.status === "SUCCESS") {
                  for (const upload of data.uploads) {
                    this.http.get(environment.apiUrl + `/uploads/getThumbnail?id=${upload.id}`).subscribe((res: any) => {
                      if (res.status === "SUCCESS") {
                        merchant.src.push(res.upload.thumbnail);
                        values['src'].push(res.upload.thumbnail);
                      }
                    });
                  }
                }
              })
            } catch (error) {
              console.log(error)
            }
            merchant.cover_src = [];
            values['cover_src'] = [];
            try {
              this.http.get(environment.apiUrl + `/uploads/listbydatafor?datafor=merchantcoverpic&dataforid=${merchant.id}`).subscribe((data: any) => {
                if (data.status === "SUCCESS") {
                  for (const upload of data.uploads) {
                    this.http.get(environment.apiUrl + `/uploads/getThumbnail?id=${upload.id}`).subscribe((res: any) => {
                      if (res.status === "SUCCESS") {
                        merchant.cover_src.push(res.upload.thumbnail);
                        values['cover_src'].push(res.upload.thumbnail);
                      }
                    });
                  }
                }
              })
            } catch (error) {
              console.log(error)
            }
            if(values.id != this.merchantId){
              this.rentalMerchant.push(values);
            }else{
              values.dataFor = "booking";
              this.providedByMerchant.push(values);
            }
          })
          setTimeout(()=>{
            const sourceHeight = this.activitycontentDiv.nativeElement.clientHeight;
            this.providedbyDiv.nativeElement.style.maxHeight = `${sourceHeight - 50}px`;
          },500)
        }
      }
    })
  }

  formatDate(date: any) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }
  
  getDates(startDate: any, endDate: any) {
    const dateArray = [];
    let currentDate = new Date(startDate);
    while (currentDate <= endDate) {
      dateArray.push(this.formatDate(currentDate));
      currentDate.setDate(currentDate.getDate() + 1);
    }

    return dateArray;
  }

  
  ifMobileApp() {
    if (this.userAgent.includes('AdvenzoneMobileApp')) {
      return true;
    } else {
      return false;
    }
  }

  loadReviews() {
    let data = { dataFor: 'merchant', dataForId: this.merchantId, count: 3, page: this.reviewPage, sortColumn: 'id' };
    this.http.post(environment.apiUrl + `/reviews/list`, data, { responseType: 'json' }).subscribe(async (data: any) => {
      if (data.status === "SUCCESS") {
        data.reviews.forEach(async (review: any) => {
          review.src = "";
          try {
            this.http.get(environment.apiUrl + `/uploads/listbydatafor?datafor=userprofile&dataforid=${review.createdby}`).subscribe((data: any) => {
              if (data.status === "SUCCESS") {
                for (const upload of data.uploads) {
                  this.http.get(environment.apiUrl + `/uploads/getThumbnail?id=${upload.id}`).subscribe((res: any) => {
                    if (res.status === "SUCCESS") {
                      review.src = res.upload.thumbnail;
                    }
                  });
                }
              }
            })
          } catch (error) {
            console.log(error)
          }
        })
        this.reviews.push(...data.reviews);
        if (Number(data.total) > this.reviews.length && Number(data.total) != this.reviews.length) {
          this.loadMoreReviews = true;
          this.reviewPage += 1;
        } else {
          this.loadMoreReviews = false;
        }
      }
    });
  }

  async loadMoreOtherVehicles() {
    let activityVariation: any = await this.http.get(environment.apiUrl + `/merchantactivities/activityVariationDetails?category=bikerental${(this.locationId != null) ? '&locationId=' + this.locationId : ''}&merchantid=${this.merchantId}`).toPromise();

    this.http.get(environment.apiUrl + `/merchantinventories/viewInventory?category=bikerental${(this.locationId != null) ? '&locationId=' + this.locationId : ''}&merchantId=${this.merchantId}`).subscribe(async (data: any) => {
      if (data.status === "SUCCESS") {
        if (data.merchantinventories.length > 0) {
          let inventoryVariationIds = [];
          data.merchantinventories.forEach((x: any) => {
            if (!inventoryVariationIds.includes(x.variationid)) inventoryVariationIds.push(x.variationid);
            this.inventoryVariationValueIds.push(x.variationvalueid);
          })
          const inventoryVariationRequests = inventoryVariationIds.map((x: any) => {
            return this.http.get(environment.apiUrl + `/variations/get?id=${x}`).toPromise(); // Convert the Observable to a Promise
          });

          const loadVariationRequests = await Promise.all(inventoryVariationRequests);
          loadVariationRequests.forEach((x: any) => {
            this.inventoryVariations.push(x.variations)
          })

          const sortByParentVariation = (data) => {
            const sortedData = [...data];
            sortedData.sort((a, b) => {
              if (a.parentvariation === '0') {
                return -1;
              } else if (b.parentvariation === '0') {
                return 1;
              } else {
                return a.parentvariation.localeCompare(b.parentvariation);
              }
            });
            return sortedData;
          };
          this.inventoryVariations = sortByParentVariation(this.inventoryVariations);

          const inventoryVariationValueRequests = this.inventoryVariationValueIds.map((x: any) => {
            return this.http.get(environment.apiUrl + `/variationvalues/get?id=${x}`).toPromise(); // Convert the Observable to a Promise
          });
          const loadVariationValueRequests = await Promise.all(inventoryVariationValueRequests);
          loadVariationValueRequests.forEach((x: any) => {
            if (x.variationvalues.parentvariationvalueid != null && x.variationvalues.parentvariationvalueid != 0) {
              // this.bikeCard.push(x.variationvalues);
              this.allBikeCard.push(x.variationvalues);
            }
          });
          this.allBikeCard.forEach((x: any) => {
            let variationTime = activityVariation.activityVariationDetails.filter((activity: any) => {
              return activity.merchants.some((y: any) =>
                y.variationtypeid == x.variationtypeid &&
                y.variationvalueid == x.id
              );
            });
            x.price = "";
            try {
             this.http.get(environment.apiUrl + `/range/minpricebymerchantactivityid?merchantActivityId=${variationTime[0].id}`).subscribe((data: any)=>{
                if (data.status === "SUCCESS") {
                  x.min_price= data.min_price;
                }
              });
            } catch (error) {
              console.log(error)
            }
            // if (variationTime.length > 0) x.price = variationTime[0].price;
            x.src = [];
            x.availability = true;
            try {
              this.http.post(environment.apiUrl + '/coupons/is-coupon-available', { status: 1, locationId: this.locationId, merchantId: 0, activityId: activityVariation.activityVariationDetails[0].activityid, currentTimeStamp: new Date().getTime(), isMobileApp: this.ifMobileApp() }, { responseType: 'json' }).subscribe((data: any) => {
                if (data.status === "SUCCESS") {
                  x.hasCoupon = data.coupons;
                } else {
                  x.hasCoupon = false;
                }
              })
            } catch (error) {
              x.hasCoupon = false;
              console.log(error);
            }
            this.http.get(environment.apiUrl + `/uploads/listbydatafor?datafor=variationvalueimage&dataforid=${x.id}`).subscribe((data: any) => {
              if (data.status == "SUCCESS") {
                for (const upload of data.uploads) {
                  this.http.get(environment.apiUrl + `/uploads/getThumbnail?id=${upload.id}`).subscribe((res: any) => {
                    if (res.status === "SUCCESS") {
                      x.src.push(res.upload.thumbnail)
                    }
                  });
                }
              }
            })
          })
          this.allBikeCard.sort((a, b) => Number(a.price) - Number(b.price));
        }
      }
    })
  }

  
  view_merchant(id:any){
    this.router.navigate(['/rental-description/merchant/'+ id], {
      state: { dataToPass: this.homeData}
    });
  }

  book_merchant(id:any){
    this.router.navigate(['/rental-booking/merchant/'+ id], {
      state: { dataToPass: {...this.homeData, merchantactivityid: this.providedByMerchant[0].merchantactivityid}}
    });
  }


  
  convertTimestampToDateFormat(timestamp: any): string {
    timestamp = Number(timestamp)
    const date = new Date(timestamp);
    const formattedDate = this.datePipe.transform(date, 'MMMM dd, yyyy');
    return formattedDate || '';
  }

  showMoreReviews(){
    const isMobile = window.innerWidth < 768;
    let sizes = {
      height: '90vh',
      width: '80%',
      maxWidth: 'unset',
      panelClass: 'web-reviews-show-more-class',
    };
    if (isMobile) {
      this.bottomSheet.open(MobileReviewsShowMoreComponent, {
        data: { activityName: this.locationName + ' Bike Rental', merchantId: this.merchantId, totalReview: this.merchantData.total_persons_rated, avgReview: this.merchantData.avg_rating, reviews: this.reviews}
      });
    }else{
      let data :any = { activityName: this.locationName + ' Bike Rental', merchantId: this.merchantId, totalReview: this.merchantData.total_persons_rated, avgReview: this.merchantData.avg_rating, reviews: this.reviews};
    
      this.dialog.open(WebReviewsShowMoreComponent, {
        ...sizes,
        disableClose: true,
        data: data,
      });
    }
  }

  showMoreTopMerchants() {
    let filter = { count: this.viewCount, locationId: this.locationId, status: 1, page: this.topAdventuristsPage };
    this.topAdventuristsLoadMore = false;
    this.http.post(environment.apiUrl + `/merchants/topmerchants-card`, { ...filter, sortColumn: "average_star" }, { responseType: 'json' }).subscribe(async (data: any) => {
      {
        if (data.status === "SUCCESS") {
          data.merchants.forEach((merchant: any) => {
            let values: any = {};
            values["id"] = merchant.id;
            values["name"] = merchant.name;
            values["description"] = merchant.description;
            values["locationId"] = merchant.locationid;
            values["locationName"] = merchant.locationname;
            values["address"] = merchant.addressline1;
            values["activityCount"] = merchant.activitycount;
            values["src"] = [];
            values["reviewed_user_pic"] = [];
            try{
              this.http.post(environment.apiUrl + '/coupons/is-coupon-available',{status:1,locationId:this.locationId, merchantId:merchant.id,activityId:0 ,currentTimeStamp: new Date().getTime(), isMobileApp: this.ifMobileApp()}, { responseType: 'json' }).subscribe((data: any) => {
                if (data.status === "SUCCESS" ) {
                  merchant.hasCoupon = data.coupons;
                  values["hasCoupon"] = data.coupons;
                  }else{
                    merchant.hasCoupon = false;
                    values["hasCoupon"] = false;
                 }
                  })
            }catch(error){
              console.log(error);
              merchant.hasCoupon = false;
              values["hasCoupon"] = false;
            }
            merchant.src = [];
            merchant.shopsrc = [];
            try {
              this.http.get(environment.apiUrl + `/uploads/listbydatafor?datafor=merchantprofile&dataforid=${merchant.id}`).subscribe((data: any) => {
                if (data.status === "SUCCESS") {
                  for (const upload of data.uploads) {
                    this.http.get(environment.apiUrl + `/uploads/getThumbnail?id=${upload.id}`).subscribe((res: any) => {
                      if (res.status === "SUCCESS") {
                        merchant.src.push(res.upload.thumbnail);
                        values["src"].push(res.upload.thumbnail);
                      }
                    });
                  }
                }
              })
            } catch (error) {
              console.log(error)
            }
            let data = { dataFor: 'merchant', merchantId: merchant.id, count: 4, page: 1, sortColumn: 'id' };
            this.http.post(environment.apiUrl + `/reviews/merchantlist`, data, { responseType: 'json' }).subscribe(async (data: any) => {
              if (data.status === "SUCCESS") {
                data.reviews.forEach(async (review: any) => {
                  try {
                    this.http.get(environment.apiUrl + `/uploads/listbydatafor?datafor=userprofile&dataforid=${review.createdby}`).subscribe((data: any) => {
                      if (data.status === "SUCCESS") {
                        if (data.uploads.length > 0) {
                          for (const upload of data.uploads) {
                            this.http.get(environment.apiUrl + `/uploads/getThumbnail?id=${upload.id}`).subscribe((res: any) => {
                              if (res.status === "SUCCESS") {
                                values["reviewed_user_pic"].push(res.upload.thumbnail);
                              }
                            });
                          }
                        } else {
                          values["reviewed_user_pic"].push(null);
                        }
                      }
                    })
                  } catch (error) {
                    console.log(error)
                  }
                })
              }
            });
            merchant.averagestar = parseInt(merchant.average_star);
            values["averagestar"] = parseInt(merchant.average_star) || 0;
            this.topAdventuristsData.push(values);
          });
          if (data.merchants && data.merchants.length > 0) {
            if (Number(data.count) >  this.viewCount) {
              this.topAdventuristsLoadMore = true;
              this.topAdventuristsPage += 1;
            } else {
              this.topAdventuristsLoadMore = false;
            }
          } else {
            this.topAdventuristsLoadMore = false;
          }
        }
      }
    })
  }

  ShowMoreTopAdventurists(dataFor:any){
    const isMobile = window.innerWidth < 768;
    let sizes = {
      height: '90vh',
      width: '80%',
      maxWidth: 'unset',
      panelClass: 'web-activities-show-more-class',
    };
    let data :any = { locationId: this.locationId, locationName: this.locationName};
    if(dataFor === 'merchant'){
      data.category = 'topadventurists';
      data.topAdventuristsData = this.topAdventuristsData;
    }else{
      data.category = 'bikerental';
      data.activityData = this.allBikeCard;
    }
    if (isMobile) {
      this.bottomSheet.open(MobileActivitiesShowMoreComponent, {
        data: data,
        panelClass: 'mobile-activities-show-more-class',
      });
    }else{
        this.dialog.open(WebActivitiesShowMoreComponent, {
          ...sizes,
          disableClose: true,
          data: data,
        });
    }
    
  }
}
