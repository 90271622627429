<div class="pb-2 camping-card shadow bg-white">
    <div class="position-relative">
        <ngb-carousel class="camping-img-carousel-height" *ngIf="data.src && data.src.length > 0">
            <ng-template *ngFor="let data of data.src" ngbSlide (click)="$event.stopPropagation()">
                <div class="camping-wrapper">
                    <img [src]="data.img" alt="image" class="image-data-src">
                </div>
            </ng-template>
        </ngb-carousel>
        <div class="camping-has-coupon pe-3" *ngIf="data.hasCoupon">
            <h6 class="mb-0 ps-3">Offer Available</h6>
        </div>
        <img class="position-absolute info pointer" src="/assets/cards/info.svg" alt="info"  (click)="openInfoDialog($event)" />
        <div class="camping-card-rating rounded-pill position-absolute px-2">
            <ng-container *ngFor="let star of [1, 2, 3, 4, 5]">
                <span [ngClass]="{'filled': star <= data.rating, 'empty': star > data.rating}" class="star">&#9733;</span>
            </ng-container>
        </div>
    </div>
    <div class="px-2">
        <h3 class="camping-title mt-2 text-truncate">{{data.campingName}}</h3>
        <div class="d-flex camping-content">
            <a class="me-2 camping-card-navigation text-truncate w-50" href="javascript:void(0)" (click)="openMap($event, data.campingLatitude, data.campingLongitude)"><img src="/assets/cards/km.svg" alt="Km icon"> {{data.distanceAway}} <img src="/assets/cards/link-icon.svg" alt="link icon"></a>
            <p class="me-2 camping-card-activityname w-50"><img src="/assets/cards/camping-icon.svg" alt="camping icon"> {{data.activityname}}</p>
        </div>
        <a class="me-2 camping-card-navigation text-truncate d-block" href="javascript:void(0)" (click)="openMerchantPage()"><img src="/assets/cards/person-icon.svg" alt="person icon"> {{data.merchantname}} <img src="/assets/cards/link-icon.svg" alt="link icon"></a>
        <hr>
        <div class="d-flex justify-content-between align-items-end mb-2" style="min-height: 57px;">
            <div *ngIf="data.min_price != 0">
                <h6 class="camping-starts-from mb-1">Starting from</h6>
                <h4 class="camping-price mb-1">₹ {{data.min_price}} <span class="camping-per-person">/person</span></h4>
            </div>
            <div *ngIf="data.min_price == 0">
                <button type="button" class="btn rounded-pill px-4 camping-enquiry" (click)="openEnquiryDialog()">Enquiry</button>
            </div>
            <div>
                <button type="button" class="btn camping-view-details rounded-pill px-3" (click)="openCampingPage()">View details</button>
            </div>
        </div>
    </div>
</div>