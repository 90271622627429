<section class="fixed-margin-top overflow-hidden" >
    <div class="mx-md-5 mx-3"  [class.location-details-mobile-margin-bottom]="ifMobileApp()">
        <div class="">
            <div class="position-relative">
                <ngb-carousel class="location-img-carousel-height" *ngIf="landingPageImages.length > 0">
                    <ng-template *ngFor="let data of landingPageImages" ngbSlide (click)="$event.stopPropagation()">
                        <div class="wrapper">
                            <img [src]="data.src" *ngIf="data.src != null" alt="image" class="image-data-src">
                        </div>
                    </ng-template>
                </ngb-carousel>
                <div id="carouselExampleControls" class="location-top-activities-carousel carousel slide"
                    data-bs-ride="carousel">
                    <div class="carousel-inner">
                        <div class="carousel-item" *ngFor="let chunk of topActivityChunkCards; let i = index"
                            [ngClass]="{'active': i === 0}">
                            <div class="row">
                                <div class="col-6" *ngFor="let card of chunk">
                                    <div class="card location-top-activities-card">
                                        <div class="card-body px-1">
                                            <div class="d-flex">
                                                <img [src]="card.src[0]" *ngIf="card.src.length > 0"
                                                    class="location-top-activities-img rounded" alt="">
                                                <div class="location-top-activities-content ps-2" [ngStyle]="(card.src.length > 0) ? {'width': '60%'} : {'width':'100%'}">
                                                    <h5 class="landing-overlay-activity-title text-truncate mb-1">
                                                        {{(card.activitiescategory == 'camping')? card.campingName :
                                                        card.activitiesaddressline1}}</h5>
                                                    <p class="landing-overlay-activity-description"
                                                        [innerHTML]="(card.activitiescategory == 'camping')? card.description : card.activitiesdescription">
                                                    </p>
                                                    <button type="button"
                                                        class="btn landing-overlay-activity-view-details rounded-pill"
                                                        (click)="openTopActivity(card.activitiescategory, card.id, card.activityid)">View
                                                        details</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="most-popular-activities-container d-md-none d-block">
                <h3 class="landing-popular-activities-title mb-0 mt-3" *ngIf="topActivityCards.length > 0">Most Popular
                    Activities</h3>
                <div class="parent" #elemt1 (mousedown)="startDragging($event, false, elemt1,'mouse', child1,'')"
                    (mouseup)="stopDragging($event, false,'mouse')" (mouseleave)="stopDragging($event, false,'mouse')"
                    (mousemove)="moveEvent($event, elemt1,'mouse', child1)"
                    (touchstart)="startDragging($event, false, elemt1, 'touch', child1, '')"
                        (touchend)="stopDragging($event, false, 'touch')"
                        (touchmove)="moveEvent($event, elemt1, 'touch', child1)">
                    <div class="child  d-flex justify-content-center ps-0" #child1>
                        <div class="card landing-popular-activities-card me-2" *ngFor="let card of topActivityCards">
                            <div class="card-body p-2">
                                <div class="d-flex">
                                    <img [src]="card.src[0]" *ngIf="card.src.length > 0"
                                        class="location-top-activities-img rounded" alt="">
                                    <div class="location-popular-activities-content ps-2" [ngStyle]="(card.src.length > 0) ? {'width': '60%'} : {'width':'100%'}">
                                        <h4 class="landing-popular-activity-title text-truncate mb-1">
                                            {{(card.activitiescategory == 'camping')? card.campingName :
                                            card.activitiesaddressline1}}</h4>
                                        <p class="landing-popular-activity-description"
                                            [innerHTML]="(card.activitiescategory == 'camping')? card.description : card.activitiesdescription">
                                        </p>
                                        <button type="button"
                                            class="btn landing-popular-activity-view-details rounded-pill"
                                            (click)="openTopActivity(card.activitiescategory, card.id, card.activityid)">View
                                            details</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="description-container px-md-5 pt-md-5">
                <h1 class="welcome-title mb-2 mt-md-0 mt-2">Welcome to {{location?.locationname}}</h1>
                <div class="row mx-0">
                    <div class="col-md-6 px-0">
                        <!-- <h2 class="location-title">One of our most exhilarating adventure hotspots</h2> -->
                        <p class="location-description" [innerHTML]="locationDescription"></p>
                        <button type="button" (click)="showPhase('')"  *ngIf="((indexData.rafting && indexData.rafting > 0) || (indexData.trekking && indexData.trekking > 0) || (indexData.camping && indexData.camping > 0) || (indexData.bikerental && indexData.bikerental > 0) || (indexData.tour && indexData.tour > 0) || (indexData.quickactivities && indexData.quickactivities > 0) || (indexData.topadventurists && indexData.topadventurists > 0)) || (category_labels && category_labels.length > 0)"
                            class="btn explore-btn rounded-pill px-4 mx-auto d-inline d-block py-2 mt-3">Explore our services <span class="ms-2">→</span></button>
                    </div>
                    <div class="col-md-6 d-md-block d-none">
                        <div class="position-relative ms-5" style="min-height: 450px;">
                            <div class="position-absolute">
                                <img src="/assets/location/location-static-pic-2.png" class="location-static-img"
                                    alt="location-img">
                            </div>
                            <div class="position-absolute" style="top:100px; left:150px;">
                                <img src="/assets/location/location-static-pic.png" class="location-static-img"
                                    alt="location-img">
                            </div>
                            <div class="position-absolute" style="top:250px; left:10px;">
                                <img src="/assets/location/location-static-pic-1.png" class="location-static-img"
                                    alt="location-img">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="px-md-5 pt-md-5 mt-md-0 mt-5">
                <h1 class="welcome-title text-center">Services We Offer</h1>
                <div id="list-catogory" class="mt-md-4 mt-1">
                    <div class="parent" #elemt0 (mousedown)="startDragging($event, false, elemt0,'mouse', child0,'')"
                        (mouseup)="stopDragging($event, false,'mouse')"
                        (mouseleave)="stopDragging($event, false,'mouse')"
                        (mousemove)="moveEvent($event, elemt0,'mouse', child0)"
                        (touchstart)="startDragging($event, false, elemt0, 'touch', child0, '')"
                        (touchend)="stopDragging($event, false, 'touch')"
                        (touchmove)="moveEvent($event, elemt0, 'touch', child0)">
                        <div class="child  d-flex justify-content-center " #child0>
                            <ng-container *ngFor="let data of category_labels;">
                                <div class="landing-mobile-category rounded-pill px-4 py-1 mx-2"
                                    (click)="showPhase(data.id)"  *ngIf="data.count && data.count > 0">
                                    <div class="d-flex gap-2 justify-content-center mt-1 text-nowarp">
                                        <img [src]="data.icon" width="30px" *ngIf="data.icon && data.icon != ''" alt="category icon" class="pe-2" />
                                        <span class="mb-0 landing-mobile-category-title">{{data.label}}</span>
                                    </div>
                                </div>
                            </ng-container>
                            <ng-container *ngFor="let data of default_category_labels;">
                                <div class="landing-mobile-category rounded-pill px-4 py-1 mx-2"
                                    (click)="showPhase(data.category)"  *ngIf="data.count && data.count > 0">
                                    <div class="d-flex gap-2 justify-content-center mt-1 text-nowarp">
                                        <img src="/assets/landing-page/icons/rafting.svg" *ngIf="data.category == 'rafting'" class="icon mx-auto  d-block" alt="icon" />
                                        <img src="/assets/landing-page/icons/camping.svg" *ngIf="data.category == 'camping'" class="icon mx-auto  d-block" alt="icon" />
                                        <img src="/assets/landing-page/icons/trekking.svg" *ngIf="data.category == 'trekking'" class="icon mx-auto  d-block" alt="icon" />
                                        <img src="/assets/landing-page/icons/tour.svg" *ngIf="data.category == 'tour'" class="icon mx-auto  d-block" alt="icon" />
                                        <img src="/assets/landing-page/icons/quick-activities.svg" *ngIf="data.category == 'activity'" class="icon mx-auto  d-block" alt="icon" />
                                        <img src="/assets/landing-page/icons/bike-rental.svg" *ngIf="data.category == 'bikerental'" class="icon mx-auto  d-block" alt="icon" />
                                        <span class="mb-0 landing-mobile-category-title" *ngIf="data.category == 'camping'">Camping</span>
                                        <span class="mb-0 landing-mobile-category-title" *ngIf="data.category == 'trekking'">Trekking</span>
                                        <span class="mb-0 landing-mobile-category-title" *ngIf="data.category == 'tour'">Tours</span>
                                        <span class="mb-0 landing-mobile-category-title" *ngIf="data.category == 'activity'">Quick Activities</span>
                                        <span class="mb-0 landing-mobile-category-title" *ngIf="data.category == 'bikerental'">Bike Rentals</span>
                                        <span class="mb-0 landing-mobile-category-title" *ngIf="data.category == 'rafting'">Rafting</span>
                                    </div>
                                 </div>
                            </ng-container>
                            <div class="landing-mobile-category rounded-pill px-4 py-1 mx-2"  *ngIf="category_labels.length > 0 && topadventuristsCount != 0" 
                                (click)="showPhase('topAdventurists')">
                                <div class="d-flex gap-2 justify-content-center mt-1 text-nowarp">
                                    <img src="/assets/landing-page/icons/top-adventurists.svg" width="30px" alt="top adventurists icon" class="pe-2" />
                                    <span class="mb-0 landing-mobile-category-title">Top Adventurists</span>
                                </div>
                            </div>
                            <ng-container  *ngIf="category_labels.length == 0">
                                <div class="landing-mobile-category rounded-pill px-4 py-1 mx-2"
                                    *ngIf="!indexLoading && indexData.rafting > 0" (click)="showPhase('rafting')">
                                    <div class="d-flex gap-2 justify-content-center mt-1 text-nowarp">
                                        <img src="/assets/landing-page/icons/rafting.svg" class="icon-landing mb-0" alt="icon" />
                                        <span class="mb-0 landing-mobile-category-title">Rafting</span>
                                    </div>
                                </div>
                                <div class="landing-mobile-category rounded-pill px-4 py-1 mx-2"
                                    *ngIf="!indexLoading && indexData.bikerental > 0" (click)="showPhase('bikerental')">
                                    <div class=" d-flex gap-2 justify-content-center mt-1 text-nowarp">
                                        <img src="/assets/landing-page/icons/bike-rental.svg" class="icon-landing mb-0" alt="icon" />
                                        <span class="mb-0 landing-mobile-category-title">Bike Rentals</span>
                                    </div>
                                </div>
                                <div class="landing-mobile-category rounded-pill px-4 py-1 mx-2"
                                    *ngIf="!indexLoading && indexData.camping > 0" (click)="showPhase('camping')">
                                    <div class="d-flex gap-2 justify-content-center mt-1 text-nowarp">
                                        <img src="/assets/landing-page/icons/camping.svg" class="icon-landing mb-0" alt="icon" />
                                        <span class="mb-0 landing-mobile-category-title">Camping</span>
                                    </div>
                                </div>
                                <div class="landing-mobile-category rounded-pill px-4 py-1 mx-2"
                                    *ngIf="!indexLoading && indexData.trekking > 0" (click)="showPhase('trekking')">
                                    <div class="d-flex gap-2 justify-content-center mt-1 text-nowarp">
                                        <img src="/assets/landing-page/icons/trekking.svg" class="icon-landing mb-0" alt="icon" />
                                        <span class="mb-0 landing-mobile-category-title">Trekking</span>
                                    </div>
                                </div>
                                <div class="landing-mobile-category rounded-pill px-4 py-1 mx-2"
                                    *ngIf="!indexLoading && indexData.tour > 0" (click)="showPhase('tour')">
                                    <div class="d-flex gap-2 justify-content-center mt-1 text-nowarp">
                                        <img src="/assets/landing-page/icons/bike-tour.svg" class="icon-landing mb-0" alt="icon" />
                                        <span class="mb-0 landing-mobile-category-title">Tours</span>
                                    </div>
                                </div>
                                <div class="landing-mobile-category rounded-pill px-4 py-1 mx-2"
                                    *ngIf="!indexLoading && indexData.quickactivities > 0" (click)="showPhase('activity')">
                                    <div class="d-flex gap-2 justify-content-center mt-1 text-nowarp">
                                        <img src="/assets/landing-page/icons/quick-activities.svg" class="icon-landing mb-0"
                                            alt="icon" />
                                        <span class="mb-0 landing-mobile-category-title">Quick Activities</span>
                                    </div>
                                </div>
                                <div class="landing-mobile-category rounded-pill px-4 py-1 mx-2"
                                    *ngIf="!indexLoading && indexData.topadventurists > 0"
                                    (click)="showPhase('topadventurists')">
                                    <div class="d-flex gap-2 justify-content-center mt-1 text-nowarp">
                                        <img src="/assets/landing-page/icons/top-adventurists.svg" class="icon-landing mb-0"
                                            alt="icon" />
                                        <span class="mb-0 landing-mobile-category-title">Top Adventurists</span>
                                    </div>
                                </div>
                        </ng-container>
                        </div>
                    </div>
                </div>
                <div class="row pt-3 pt-md-0 w-100 mx-0">
                    <div class="col-md-8 col-12">
                        <div id="section-activities" class="section-activities">
                            <ng-container *ngIf="category_labels.length == 0">
                            <section id="rafting-section" class="rafting-section mb-5" *ngIf="raftingDetails.length > 0">
                                <div class="d-flex justify-content-between mb-2">
                                    <h2 class="category-title mb-0">Explore River Rafting</h2>
                                    <img src="/assets/location/right-arrow.svg" class="pointer section-right-arrow"
                                        alt="right arrow" (click)="showPhase('rafting')" />
                                    <img src="/assets/location/mobile-right-arrow.svg" class="pointer mobile-section-right-arrow"
                                    alt="right arrow" (click)="showPhase('rafting')" />    
                                </div>
                                <div class="row">
                                    <div class="col-md-6 col-12" *ngFor="let data of  raftingDetails; let j= index">
                                        <app-rafting-card [data]="data"></app-rafting-card>
                                    </div>
                                </div>
                            </section>
                            <section id="camping-section" class="camping-section mb-5"
                                *ngIf="CampingDetails.length > 0">
                                <div class="d-flex justify-content-between mb-2">
                                    <h2 class="category-title mb-0">Explore Camping</h2>
                                    <img src="/assets/location/right-arrow.svg" class="pointer section-right-arrow"
                                        alt="right arrow" (click)="showPhase('camping')" />
                                    <img src="/assets/location/mobile-right-arrow.svg" class="pointer mobile-section-right-arrow"
                                    alt="right arrow" (click)="showPhase('camping')" />    
                                </div>
                                <div class="row">
                                    <div class="col-md-6 col-12" *ngFor="let data of  CampingDetails; let j= index">
                                        <app-camping-card [data]="data"></app-camping-card>
                                    </div>
                                </div>
                            </section>
                            <section id="bike-rental-section" class="bike-rental-section mb-5"
                                *ngIf="rentalDetails.length > 0">
                                <div class="d-flex justify-content-between mb-2">
                                    <h2 class="category-title mb-0">Explore Bike Rental</h2>
                                    <img src="/assets/location/right-arrow.svg" class="pointer section-right-arrow"
                                        alt="right arrow" (click)="showPhase('bikerental')" />
                                    <img src="/assets/location/mobile-right-arrow.svg" class="pointer mobile-section-right-arrow"
                                    alt="right arrow" (click)="showPhase('bikerental')" />    
                                </div>
                                <div class="row">
                                    <div class="col-md-6 col-12"
                                        *ngFor="let data of  rentalDetails | slice:0:cardCount; let j= index">
                                        <app-bike-rental-card [data]="data"></app-bike-rental-card>
                                    </div>
                                </div>
                            </section>
                            <section id="trekking-section" class="trekking-section mb-5"
                                *ngIf="trekkingDetails.length > 0">
                                <div class="d-flex justify-content-between mb-2">
                                    <h2 class="category-title mb-0">Explore Trekking</h2>
                                    <img src="/assets/location/right-arrow.svg" class="pointer section-right-arrow"
                                        alt="right arrow" (click)="showPhase('trekking')" />
                                    <img src="/assets/location/mobile-right-arrow.svg" class="pointer mobile-section-right-arrow"
                                    alt="right arrow" (click)="showPhase('camping')" />
                                </div>
                                <div class="row">
                                    <div class="col-md-6 col-12" *ngFor="let data of  trekkingDetails; let j= index">
                                        <app-trekking-card [data]="data"></app-trekking-card>
                                    </div>
                                </div>
                            </section>
                            <section id="bike-tour-section" class="tour-section mb-5"
                                *ngIf="bikeTourDetails.length > 0">
                                <div class="d-flex justify-content-between mb-2">
                                    <h2 class="category-title mb-0">Explore Tours</h2>
                                    <img src="/assets/location/right-arrow.svg" class="pointer section-right-arrow"
                                        alt="right arrow" (click)="showPhase('tour')" />
                                    <img src="/assets/location/mobile-right-arrow.svg" class="pointer mobile-section-right-arrow"
                                    alt="right arrow" (click)="showPhase('tour')" />    
                                </div>
                                <div class="row">
                                    <div class="col-md-6 col-12" *ngFor="let data of  bikeTourDetails; let j= index">
                                        <app-tour-card [data]="data"></app-tour-card>
                                    </div>
                                </div>
                            </section>
                            <section id="quick-activities-section" class="quick-activities-section mb-5"
                                *ngIf="quickActivityDetails.length > 0">
                                <div class="d-flex justify-content-between mb-2">
                                    <h2 class="category-title mb-0">Explore Quick Activities</h2>
                                    <img src="/assets/location/right-arrow.svg" class="pointer section-right-arrow"
                                        alt="right arrow" (click)="showPhase('activity')" />
                                    <img src="/assets/location/mobile-right-arrow.svg" class="pointer mobile-section-right-arrow"
                                    alt="right arrow" (click)="showPhase('activity')" />    
                                </div>
                                <div class="row">
                                    <div class="col-md-6 col-12"
                                        *ngFor="let data of  quickActivityDetails; let j= index">
                                        <app-quick-activities-card [data]="data"></app-quick-activities-card>
                                    </div>
                                </div>
                            </section>
                            <section id="top-adventurists-section" class="top-adventurists-section mb-5"
                                *ngIf="topAdventuristsData.length > 0">
                                <div class="d-flex justify-content-between mb-2">
                                    <h2 class="category-title mb-0">Top Adventurists</h2>
                                    <img src="/assets/location/right-arrow.svg" class="pointer section-right-arrow"
                                        alt="right arrow" (click)="showPhase('topAdventurists')" />
                                    <img src="/assets/location/mobile-right-arrow.svg" class="pointer mobile-section-right-arrow"
                                        alt="right arrow" (click)="showPhase('topAdventurists')" />    
                                </div>
                                <div class="row">
                                    <div class="col-md-6 col-12"
                                        *ngFor="let data of  topAdventuristsData.slice(0, 2); let j= index">
                                        <app-new-merchant-profile-card [data]="data"></app-new-merchant-profile-card>
                                    </div>
                                </div>
                            </section>
                            </ng-container>
                            <div *ngIf="category_labels.length > 0">
                                <ng-container *ngFor="let data of category_labels;">
                                    <section class="dynamic-category-card-section mb-5" *ngIf="data.activityCards.length > 0">
                                        <div class="d-flex justify-content-between mb-2">
                                            <h2 class="category-title mb-0">Explore {{data.label}}</h2>
                                            <img src="/assets/location/right-arrow.svg" class="pointer section-right-arrow"
                                                alt="right arrow" (click)="showPhase(data.id)" />
                                            <img src="/assets/location/mobile-right-arrow.svg" class="pointer mobile-section-right-arrow"
                                            alt="right arrow" (click)="showPhase(data.id)" />    
                                        </div>
                                        <div class="row" *ngIf="data.activityCards[0].category == 'bikerental'">
                                            <div class="col-md-6 col-12" *ngFor="let data of data.activityCards | slice:0:cardCount; let j= index">
                                                 <app-bike-rental-card [data]="data"></app-bike-rental-card>
                                            </div>
                                        </div>
                                        <div class="row" *ngIf="data.activityCards[0].category != 'bikerental'">
                                            <div class="col-md-6 col-12" *ngFor="let card of  data.activityCards; let j= index">
                                                <app-bike-rental-card [data]="card"  *ngIf="card.category == 'bikerental'"></app-bike-rental-card>
                                                <app-tour-card [data]="card" *ngIf="card.category == 'tour'"></app-tour-card>
                                                <app-camping-card [data]="card" *ngIf="card.category == 'camping'"></app-camping-card>
                                                <app-rafting-card [data]="card" *ngIf="card.category == 'rafting'"></app-rafting-card>
                                                <app-quick-activities-card [data]="card" *ngIf="card.category == 'activity'"></app-quick-activities-card>
                                                <app-trekking-card [data]="card" *ngIf="card.category == 'trekking'"></app-trekking-card>
                                            </div>
                                        </div>
                                        </section>
                                </ng-container>
                                <ng-container *ngFor="let data of default_category_labels;">
                                    <section class="dynamic-category-card-section mb-5" *ngIf="data.count > 0">
                                        <div class="d-flex justify-content-between mb-2">
                                            <h2 class="category-title mb-0" *ngIf="data.category == 'rafting'">Explore River Rafting</h2>
                                            <h2 class="category-title mb-0" *ngIf="data.category == 'bikerental'">Explore Bike Rental</h2>
                                            <h2 class="category-title mb-0" *ngIf="data.category == 'camping'">Explore Camping</h2>
                                            <h2 class="category-title mb-0" *ngIf="data.category == 'trekking'">Explore Trekking</h2>
                                            <h2 class="category-title mb-0" *ngIf="data.category == 'tour'">Explore Tours</h2>
                                            <h2 class="category-title mb-0" *ngIf="data.category == 'activity'">Explore Quick Activities</h2>
                                            <img src="/assets/location/right-arrow.svg" class="pointer section-right-arrow"
                                                alt="right arrow" (click)="showPhase(data.category)" />
                                            <img src="/assets/location/mobile-right-arrow.svg" class="pointer mobile-section-right-arrow"
                                            alt="right arrow" (click)="showPhase(data.category)" />    
                                        </div>
                                        <div class="row" *ngIf="data.category == 'bikerental'">
                                            <div class="col-md-6 col-12" *ngFor="let data of data.activityCards | slice:0:cardCount; let j= index">
                                                 <app-bike-rental-card [data]="data"></app-bike-rental-card>
                                            </div>
                                        </div>
                                        <div class="row" *ngIf="data.category != 'bikerental'">
                                            <div class="col-md-6 col-12" *ngFor="let card of  data.activityCards; let j= index">
                                                <app-bike-rental-card [data]="card"  *ngIf="card.category == 'bikerental'"></app-bike-rental-card>
                                                <app-tour-card [data]="card" *ngIf="card.category == 'tour'"></app-tour-card>
                                                <app-camping-card [data]="card" *ngIf="card.category == 'camping'"></app-camping-card>
                                                <app-rafting-card [data]="card" *ngIf="card.category == 'rafting'"></app-rafting-card>
                                                <app-quick-activities-card [data]="card" *ngIf="card.category == 'activity'"></app-quick-activities-card>
                                                <app-trekking-card [data]="card" *ngIf="card.category == 'trekking'"></app-trekking-card>
                                            </div>
                                        </div>
                                        </section>
                                </ng-container>
                                <section class="top-adventurists-section mb-5" *ngIf="topAdventuristsData.length > 0">
                                    <div class="d-flex justify-content-between mb-2">
                                        <h2 class="category-title mb-0">Top Adventurists</h2>
                                        <img src="/assets/location/right-arrow.svg" class="pointer section-right-arrow"
                                            alt="right arrow" (click)="showPhase('topAdventurists')" />
                                        <img src="/assets/location/mobile-right-arrow.svg" class="pointer mobile-section-right-arrow"
                                            alt="right arrow" (click)="showPhase('topAdventurists')" />    
                                    </div>
                                    <div class="row">
                                        <div class="col-md-6 col-12"
                                            *ngFor="let data of  topAdventuristsData.slice(0, 2); let j= index">
                                            <app-new-merchant-profile-card [data]="data"></app-new-merchant-profile-card>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4 col-12">
                        <div class="p-4 sidebar-content bg-white location-category-content"  *ngIf="((indexData.rafting && indexData.rafting > 0) || (indexData.trekking && indexData.trekking > 0) || (indexData.camping && indexData.camping > 0) || (indexData.bikerental && indexData.bikerental > 0) || (indexData.tour && indexData.tour > 0) || (indexData.quickactivities && indexData.quickactivities > 0) || (indexData.topadventurists && indexData.topadventurists > 0)) || (category_labels && category_labels.length > 0)">
                            <h4 class="landing-sidebar-title mb-0">Categories</h4>
                            <hr style="opacity: 0.1;">
                            <div *ngIf="category_labels.length == 0">
                                <div class="d-flex justify-content-between pointer landing-category-title" *ngIf="indexData.rafting && indexData.rafting > 0"
                                    (click)="showPhase('rafting')">
                                    <h6 class="index-category"><img src="/assets/icons/rafting.svg" alt="rafting icon">
                                        Rafting</h6>
                                    <h6 class="index-category-count">{{indexData.rafting}}</h6>
                                </div>
                                <hr style="opacity: 0.1;"  *ngIf="indexData.rafting && indexData.rafting > 0">
                                <div class="d-flex justify-content-between pointer landing-category-title"  *ngIf="indexData.bikerental && indexData.bikerental > 0"
                                    (click)="showPhase('bikerental')">
                                    <h6 class="index-category"><img src="/assets/icons/bike-rental.svg"
                                            alt="rental icon"> Bike Rentals</h6>
                                    <h6 class="index-category-count">{{indexData.bikerental}}</h6>
                                </div>
                                <hr style="opacity: 0.1;"   *ngIf="indexData.bikerental && indexData.bikerental > 0">
                                <div class="d-flex justify-content-between pointer landing-category-title"   *ngIf="indexData.camping && indexData.camping > 0"
                                    (click)="showPhase('camping')">
                                    <h6 class="index-category"><img src="/assets/icons/camping.svg" alt="camping icon">
                                        Camping</h6>
                                    <h6 class="index-category-count">{{indexData.camping}}</h6>
                                </div>
                                <hr style="opacity: 0.1;"   *ngIf="indexData.camping && indexData.camping > 0">
                                <div class="d-flex justify-content-between  pointer landing-category-title"   *ngIf="indexData.trekking && indexData.trekking > 0"
                                    (click)="showPhase('trekking')">
                                    <h6 class="index-category"><img src="/assets/icons/trekking.svg"
                                            alt="trekking icon"> Trekking</h6>
                                    <h6 class="index-category-count">{{indexData.trekking}}</h6>
                                </div>
                                <hr style="opacity: 0.1;" *ngIf="indexData.trekking && indexData.trekking > 0">
                                <div class="d-flex justify-content-between pointer landing-category-title" *ngIf="indexData.tour && indexData.tour > 0"
                                    (click)="showPhase('tour')">
                                    <h6 class="index-category"><img src="/assets/icons/bike-tour.svg"
                                            alt="bike tour icon"> Tours</h6>
                                    <h6 class="index-category-count">{{indexData.tour}}</h6>
                                </div>
                                <hr style="opacity: 0.1;" *ngIf="indexData.tour && indexData.tour > 0">
                                <div class="d-flex justify-content-between pointer landing-category-title" *ngIf="indexData.quickactivities && indexData.quickactivities > 0"
                                    (click)="showPhase('activity')">
                                    <h6 class="index-category"><img src="/assets/icons/quick-activities.svg"
                                            alt="quick activities icon"> Quick Activities</h6>
                                    <h6 class="index-category-count">{{indexData.quickactivities}}</h6>
                                </div>
                                <hr style="opacity: 0.1;"  *ngIf="indexData.quickactivities && indexData.quickactivities > 0">
                                <div class="d-flex justify-content-between pointer landing-category-title"  *ngIf="indexData.topadventurists && indexData.topadventurists > 0"
                                    (click)="showPhase('topAdventurists')">
                                    <h6 class="index-category"><img src="/assets/icons/top-adventurists.svg"
                                            alt="adventurists icon"> Top Adventurists</h6>
                                    <h6 class="index-category-count">{{indexData.topadventurists}}</h6>
                                </div>
                            </div>
                            <div *ngIf="category_labels.length > 0">
                                <ng-container *ngFor="let data of category_labels;">
                                    <div class="d-flex justify-content-between pointer landing-category-title"
                                        (click)="showPhase(data.id)"  *ngIf="data.count && data.count > 0">
                                        <h6 class="index-category"><img [src]="data.icon" *ngIf="data.icon && data.icon != ''" width="30px"
                                                alt="category icon" class="pe-2" />{{data.label}}</h6>
                                        <h6 class="index-category-count">{{data.count}}</h6>
                                    </div>
                                    <hr style="opacity: 0.1;"  *ngIf="data.count && data.count > 0">
                                </ng-container>
                                <ng-container *ngIf="default_category_labels.length > 0">
                                    <ng-container *ngFor="let data of default_category_labels">
                                        <div class="d-flex justify-content-between pointer landing-category-title"
                                        (click)="showPhase(data.category)"  *ngIf="data.count && data.count > 0">
                                            <h6 *ngIf="data.category == 'rafting'" class="index-category"><img src="/assets/icons/rafting.svg" class="pe-2" alt="rafting icon" width="30px" />Rafting</h6>
                                            <h6 *ngIf="data.category == 'camping'" class="index-category"><img src="/assets/icons/camping.svg" class="pe-2" alt="camping icon" width="30px" />Camping</h6>
                                            <h6 *ngIf="data.category == 'trekking'" class="index-category"><img src="/assets/icons/trekking.svg" class="pe-2" alt="trekking icon" width="30px" />Trekking</h6>
                                            <h6 *ngIf="data.category == 'tour'" class="index-category"><img src="/assets/icons/bike-tour.svg" class="pe-2" alt="tour icon" width="30px" />Tours</h6>
                                            <h6 *ngIf="data.category == 'activity'" class="index-category"><img src="/assets/icons/quick-activities.svg" class="pe-2" alt="quick activities icon" width="30px" />Quick Activities</h6>
                                            <h6 *ngIf="data.category == 'bikerental'" class="index-category"><img src="/assets/icons/bike-rental.svg" class="pe-2" alt="bikerental icon" width="30px" />Bike Rentals</h6>
                                            <h6 class="index-category-count">{{data.count}}</h6>
                                        </div>
                                        <hr style="opacity: 0.1;"  *ngIf="data.count && data.count > 0">
                                    </ng-container>
                                </ng-container>
                                <div class="d-flex justify-content-between pointer landing-category-title" *ngIf="topadventuristsCount > 0"
                                    (click)="showPhase('topAdventurists')">
                                    <h6 class="index-category"><img src="/assets/icons/top-adventurists.svg" width="30px"
                                            alt="adventurists icon"> Top Adventurists</h6>
                                    <h6 class="index-category-count">{{topadventuristsCount}}</h6>
                                </div>
                            </div>
                        </div>
                        <div class="p-4 sidebar-content my-3  bg-white" *ngIf="topAdventuristsData.length > 0">
                            <div id="carouselExampleIndicators" class="carousel slide" data-bs-ride="true" >
                                <div class="carousel-indicators top-ventors-indicators me-auto justify-content-end top-0">
                                  <button
                                    *ngFor="let data of topAdventuristsData; let z = index"
                                    type="button"
                                    [attr.data-bs-target]="'#carouselExampleIndicators'"
                                    [attr.data-bs-slide-to]="z"
                                    [class.active]="z === 0"
                                    [attr.aria-current]="z === 0 ? 'true' : null"
                                    [attr.aria-label]="'Slide ' + (z + 1)">
                                  </button>
                                </div>
                                <div class="carousel-inner">
                                  <div
                                    *ngFor="let data of topAdventuristsData; let j = index"
                                    class="carousel-item"
                                    [ngClass]="{ active: j === 0 }">
                                    <app-top-vendor-card [data]="data"></app-top-vendor-card>
                                    <!-- <img src="/assets/location/location-static-pic-2.png" alt="Adventure Image" /> -->
                                  </div>
                                </div>
                              </div>
                        </div>
                        <div class="p-4 sidebar-content my-3  bg-white">
                            <h4 class="landing-sidebar-title mb-0">Recent Blogs</h4>
                            <hr style="opacity: 0.1;">
                            <div>
                                <ng-container *ngFor="let data of recentBlogs">
                                    <div class="d-flex justify-content-between pointer"
                                        (click)="openBlog(data.id, data.title.rendered)">
                                        <img [src]="data.src[0]" *ngIf="data.src.length > 0" alt="image"
                                            style="object-fit: cover; object-position: center; width: 25%;">
                                        <img src="/assets/location/location-static-pic.png" *ngIf="data.src.length == 0"
                                            alt="blog" style="object-fit: cover; object-position: center; width: 25%;">
                                        <div class="w-75 ps-2">
                                            <h4 class="recent-blog-title mb-1">{{data.title.rendered}}</h4>
                                            <div class="recent-blog-createdon"><img class="me-2" style="width: 16px;"
                                                    src="/assets/location/calender-icon.svg"
                                                    alt="time">{{sharedService.formatDate(data.date)}}</div>
                                        </div>
                                    </div>
                                    <hr style="opacity: 0.1;">
                                </ng-container>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="faq-section mb-4 px-md-5" *ngIf="faq.length > 0">
                <h1 class="faq-title mb-1 mt-md-3 text-truncate">Frequently Asked Questions (FAQs)</h1>
                <hr>
                <section class="mt-3">
                    <div class="content  mx-2 ">
                        <div *ngFor="let faq of faq; let i = index">
                            <div class="d-flex pointer" (click)="toggleContent(i)">
                                <div class="col-10">
                                  <h3 class="faq-question mb-0">{{faq.question}}</h3>
                                </div>
                                <div class="col-2 pointer  text-end">
                                  <img src="/assets/location/faq-down-arrow.svg" [ngClass]="(visibilityStates[i] ? 'transform-180' : '')" class="ms-4 pointer arrow" alt="downArrow">
                                </div>
                              </div>
                              <div class="faq-answer mt-3" *ngIf="visibilityStates[i]" [innerHTML]="faq.answer"></div>
                              <hr>
                        </div>
                    </div>
                  </section>
            </div>
        </div>

    </div>
</section>

<app-footer></app-footer>
<app-footer-icon></app-footer-icon>