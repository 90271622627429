import { Component } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet'; 
import { CategoriesPopupComponent } from '../categories-popup/categories-popup.component';
import { SearchLocationPopupComponent } from '../search-location-popup/search-location-popup.component';

@Component({
  selector: 'app-footer-icon',
  templateUrl: './footer-icon.component.html',
  styleUrls: ['./footer-icon.component.css']
})
export class FooterIconComponent {

  constructor( private bottomSheet: MatBottomSheet,){}

  openCategory(){
    const bottomSheetRef =  this.bottomSheet.open(CategoriesPopupComponent, {
      data: "opened",
    });
  }

  openSearchLocation(){
    const bottomSheetRef =  this.bottomSheet.open(SearchLocationPopupComponent, {
      data: "opened"
    });
  }

  openLocation(){
    
  }
}
