import { HttpClient, HttpResponse } from '@angular/common/http';
import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { catchError, count } from 'rxjs/operators';
import { of } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';
import { DataService } from 'src/app/data.service';
import { AppService } from 'src/app/app.service';
import { SharedServiceService } from 'src/app/blogs/shared-service.service';
import { ActivityFilterService } from 'src/app/services/activity-filter.service';

interface dataCount {
  rafting: any;
  topadventurists: any;
  camping: any;
  bikerental: any;
  trekking: any;
  tour: any;
  quickactivities: any;
  rafting_min_price:any;
  rafting_max_price:any;
  camping_min_price:any;
  camping_max_price:any;
  trekking_min_price:any;
  trekking_max_price:any;
  tour_min_price:any;
  tour_max_price:any;
  quickactivities_min_price:any;
  quickactivities_max_price:any;
  bikerentals_min_price:any;
  bikerentals_max_price:any;
}

@Component({
  selector: 'app-location-details',
  templateUrl: './location-details.component.html',
  styleUrls: ['./location-details.component.css']
})
export class LocationDetailsComponent {

  locationId: any;
  location: any;
  locationname: any;
  landingPageImages: any = [];
  locationDescription: any;
  raftingDetails: any = [];
  nonFiteredCampingData: any = [];
  CampingDetails: any = [];
  bikeRentalDetails: any = [];
  trekkingDetails: any = [];
  bikeTourDetails: any = [];
  quickActivityDetails: any = [];
  topAdventuristsData: any = [];
  rentalDetails: any = [];
  highRatedActivities: any = [];
  windowInnerWidth: any = 0;
  cardCount: Number = 2;
  userLocation = { latitude: 0, longitude: 0 };
  userAgent: string = "";
  campingPage: number = 1;
  category_id: number = environment.wp_category_id;
  recentBlogs: any = [];
  topActivityCards: any = [];
  topActivityChunkCards: any[][] = [];
  indexLoading: boolean = true;
  mouseDown = false;
  startX: any;
  startY: any;
  cursorDown = { x: 0, y: 0 };
  cursorUp = { x: 0, y: 0 };
  scrollLeft: any;
  faq:any =[];
  visibilityStates: boolean[] = [];

  public indexData: dataCount = {
    rafting: 0,
    topadventurists: 0,
    camping: 0,
    bikerental: 0,
    trekking: 0,
    tour: 0,
    quickactivities: 0,
    rafting_min_price :0,
    rafting_max_price :0,
    trekking_min_price :0,
    trekking_max_price :0,
    camping_min_price :0,
    camping_max_price :0,
    tour_min_price :0,
    tour_max_price :0,
    quickactivities_min_price : 0,
    quickactivities_max_price: 0,
    bikerentals_min_price:0,
    bikerentals_max_price:0
  }



  constructor(private http: HttpClient, private router: Router, private route: ActivatedRoute, private sanitizer: DomSanitizer, private dataService: DataService, public sharedService: SharedServiceService, private appService: AppService, private activityFilterService: ActivityFilterService) {
    this.windowInnerWidth = window.innerWidth;
    if (this.windowInnerWidth > 600) {
      this.cardCount = 2;
    } else {
      this.cardCount = 1;
    }
    this.userAgent = window.navigator.userAgent;
    if (navigator.geolocation) {
      if (!this.ifMobileApp()) {
        navigator.geolocation.getCurrentPosition(position => {
          this.appService.userLocation.next({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude
          })
        });
      }
    }
    this.appService.userLocation.subscribe(data => {
      this.userLocation = data;
    })
    this.topActivityChunkCards = this.chunk(this.topActivityCards, 2);
  }

  // Helper method to split array into chunks
  chunk(arr: any[], size: number) {
    let result = [];
    for (let i = 0; i < arr.length; i += size) {
      result.push(arr.slice(i, i + size));
    }
    return result;
  }
 ngOnInit() {
    this.route.paramMap.subscribe(params => {
      this.locationId = params.get('id');
      this.http.get(environment.apiUrl + `/locations/get?id=${this.locationId}`).pipe(
        catchError((error) => {
          this.router.navigate(['404-not-found']);
          return of({ status: 'ERROR', location: null });
        })
      ).subscribe(async (data: any) => {
        if (data.status === "SUCCESS") {
          if (data.location.status == "0") {
            this.router.navigate(['/temporarily-unavailable']);
          } else {
            this.landingPageImages=[];
            this.location = data.location;
            this.locationId = data.location.id;
            this.locationname = data.location.locationname;
            this.loadIndexOfCategories();
            this.loadBlogData();
            this.loadTopActivities();
            this.loadFaq();
            const description = typeof data.location.description === 'string' ? data.location.description.trim() : '';
            const strippedDescription = description.replace(new RegExp('(<p><br></p>|<br>)$'), '').trim();
            if (strippedDescription) {
              this.locationDescription = this.sanitizer.bypassSecurityTrustHtml(data.location.description);
            } else {
              this.locationDescription = '';
            }

            let additionalinfo: any = "", sorted_images = [];
            if (data.location.additionalinfo && data.location.additionalinfo != null && data.location.additionalinfo != "") {
              additionalinfo = JSON.parse(data.location.additionalinfo);
              additionalinfo.forEach((x) => {
                if (x.visible == "true") {
                  sorted_images.push(x.imageid);
                }
              });
            }
            try {
              this.http.get(environment.apiUrl + `/uploads/listbydatafor?datafor=location&dataforid=${this.locationId}`).subscribe((data: any) => {
                if (data.status === "SUCCESS") {
                  if (sorted_images.length != 0) {
                    data.uploads.sort((a, b) => {
                      const indexA = sorted_images.indexOf(a.id);
                      const indexB = sorted_images.indexOf(b.id);

                      // Handle cases where id is not found
                      if (indexA === -1 && indexB === -1) return 0; // Keep original order
                      if (indexA === -1) return 1; // Place a after b
                      if (indexB === -1) return -1; // Place a before b

                      return indexA - indexB;
                    });
                  }
                  const filteredActivities = data.uploads.filter(activity => sorted_images.includes(activity.id));
                  filteredActivities.forEach((x: any, i) => {
                    this.landingPageImages.push({ ...x, url: null, src: null });
                    this.http.get(environment.apiUrl + `/uploads/get?id=${x.id}`).subscribe((res: any) => {
                      if (res.status === "SUCCESS") {
                        this.landingPageImages[i].src = res.upload.base64data;
                        if (additionalinfo && additionalinfo.length > 0) {
                          this.landingPageImages[i].url = additionalinfo[i].url;
                        }
                      }
                    });
                  })
                }
              })
            } catch (error) {
              console.log(error)
            }
          }
        } else {
          this.router.navigate(['/temporarily-unavailable']);
          this.location = null;
        }
      });
    })



  }

  startDragging(e: any, flag: any, el: { offsetLeft: number; scrollLeft: any; clientWidth: any, offsetTop: number }, type: string, child: any, spot: string) {   
    this.mouseDown = true;
    this.startX = e.pageX - el.offsetLeft;
    this.cursorDown.x = e.pageX;
    this.cursorDown.y = e.pageY;
    if (type === 'mouse') {
      this.startX = e.pageX - el.offsetLeft;
    }
    else if(type==='touch'){
      this.startX = e.touches[0].pageX - el.offsetLeft;
    }

    this.scrollLeft = el.scrollLeft;
  }

  stopDragging(e: any, flag: any, type: string) {
    this.cursorUp.x = e.pageX;
    this.cursorUp.y = e.pageY;
    this.mouseDown = false;
  }

  moveEvent(e: any, el: { offsetLeft: number; scrollLeft: number; offsetTop: number }, type: string, child: any) {
    if (!this.mouseDown) {
      return;
    }
    let x = 0, y = 0;
    if (type === 'mouse') {
      x = e.pageX - el.offsetLeft;
    }
    else if(type==='touch'){
      x = e.touches[0].pageX - el.offsetLeft;
    }
    const scrollX = x - this.startX;
    const scrollY = y - this.startY;
    if (Math.abs(scrollY) > Math.abs(scrollX)) {
      document.body.scrollTo(window.scrollX, window.scrollY - scrollY);
    } else {
      el.scrollLeft = this.scrollLeft - scrollX;
    }
  }


  async loadIndexOfCategories() {
    this.indexLoading = true;
    let indexCount: any = await this.http.post(environment.apiUrl + `/activities/indexcount`, { status: 1, locationId: this.locationId }, { responseType: 'json' }).toPromise();
    if (indexCount.status === "SUCCESS") {
      this.indexData.rafting = indexCount.rafting;
      this.indexData.camping = indexCount.camping;
      this.indexData.trekking = indexCount.trekking;
      this.indexData.topadventurists = indexCount.topAdventurists;
      this.indexData.bikerental = indexCount.bikeRentals;
      this.indexData.tour = indexCount.tour;
      this.indexData.quickactivities = indexCount.quickactivities;
      this.indexData.rafting_min_price = indexCount.rafting_min_price;
      this.indexData.rafting_max_price = indexCount.rafting_max_price;
      this.indexData.camping_min_price = indexCount.camping_min_price;
      this.indexData.camping_max_price = indexCount.camping_max_price;
      this.indexData.quickactivities_min_price = indexCount.quickactivities_min_price;
      this.indexData.quickactivities_max_price = indexCount.quickactivities_max_price;
      this.indexData.bikerentals_min_price = indexCount.bikerentals_min_price;
      this.indexData.bikerentals_max_price = indexCount.bikerentals_max_price;
      this.indexData.trekking_min_price = indexCount.trekking_min_price;
      this.indexData.trekking_max_price = indexCount.trekking_max_price;
      this.indexData.tour_min_price = indexCount.tour_min_price;
      this.indexData.tour_max_price = indexCount.tour_max_price;
      this.activityFilterService.setCategoryIndexCountData(this.indexData);
      this.indexLoading = false;
      this.raftingDetails =[];
      this.CampingDetails =[];
      this.bikeRentalDetails =[];
      this.rentalDetails =[];
      this.bikeTourDetails =[];
      this.trekkingDetails =[];
      this.topAdventuristsData =[];
      this.quickActivityDetails =[];
      if (this.indexData.rafting > 0) {
        this.loadRaftingData();
      }
      if (this.indexData.camping > 0) {
        this.loadCampingData();
      }
      if (this.indexData.trekking > 0) {
        this.loadTrekkingData();
      }
      if (this.indexData.tour > 0) {
        this.loadTourData();
      }
      if (this.indexData.quickactivities > 0) {
        this.loadQuickActivitiesData();
      }
      if (this.indexData.topadventurists > 0) {
        this.loadTopAdventuristsData();
      }
      if (this.indexData.bikerental > 0) {
        this.loadRentalData();
      }
    }
  }

  loadRaftingData() {
    this.raftingDetails = [];
    let filterData: any = { category: 'rafting', count: this.cardCount, locationId: this.locationId, status: 1 };
    this.http.post(environment.apiUrl + `/activities/card-list`, filterData, { responseType: 'json' }).subscribe(async (data: any) => {
      {
        if (data.status === "SUCCESS") {
          data.activities.forEach((activity: any) => {
            let values: any = {};
            values['id'] = activity.id;
            values['name'] = activity.addressline1;
            values['category'] = activity.category;
            values['src'] = [];
            values['description'] = '';
            values['locationid'] = activity.locationid;
            values['locationname'] = activity.locationname;
            activity.src = [];
            const description = typeof activity.description === 'string' ? activity.description.trim() : '';
            const strippedDescription = description.replace(new RegExp('(<p><br></p>|<br>)$'), '').trim();
            if (strippedDescription) {
              values['description'] = this.sanitizer.bypassSecurityTrustHtml(activity.description);
            } else {
              values['description'] = '';
            }
            try {
              this.http.get(environment.apiUrl + `/uploads/listbydatafor?datafor=activity&dataforid=${activity.id}`).subscribe((data: any) => {
                if (data.status === "SUCCESS") {
                  for (const upload of data.uploads) {
                    this.http.get(environment.apiUrl + `/uploads/getThumbnail?id=${upload.id}`).subscribe((res: any) => {
                      if (res.status === "SUCCESS") {
                        activity.src.push(res.upload.thumbnail);
                        values.src.push(res.upload.thumbnail);
                      }
                    });
                  }
                }
              })
            } catch (error) {
              console.log(error)
            }
            try {
              this.http.post(environment.apiUrl + `/reviews/reviewstar`, { dataFor: 'activity', dataForId: activity.id }, { responseType: 'json' }).subscribe((data: any) => {
                if (data.status === "SUCCESS") {
                  activity.avgReview = Math.round(data.reviews.average);
                  values["rating"] = Math.round(data.reviews.average);
                }
              });
            } catch (error) {
              console.log(error)
            }
            try {
              this.http.post(environment.apiUrl + '/coupons/is-coupon-available', { status: 1, locationId: this.locationId, merchantId: 0, activityId: activity.id, currentTimeStamp: new Date().getTime(), isMobileApp: this.ifMobileApp() }, { responseType: 'json' }).subscribe((data: any) => {
                if (data.status === "SUCCESS") {
                  activity.hasCoupon = data.coupons;
                  values["hasCoupon"] = data.coupons;
                } else {
                  activity.hasCoupon = false;
                  values["hasCoupon"] = false;
                }
              })
            } catch (error) {
              console.log(error);
              activity.hasCoupon = false;
              values["hasCoupon"] = false;
            }
            activity.min_price = "";
            values["min_price"] = "";
            try {
              this.http.get(environment.apiUrl + `/range/minpricebyactivityid?activityId=${activity.id}`).subscribe((data: any) => {
                if (data.status === "SUCCESS") {
                  activity.min_price = data.min_price;
                  values["min_price"] = data.min_price;
                }
              });
            } catch (error) {
              console.log(error)
            }
            if (activity.additionalinfo != null && activity.additionalinfo != "") {
              activity.raftingkm = JSON.parse(activity.additionalinfo).raftingkm;
              values['raftingkm'] = activity.raftingkm;
            }
            this.raftingDetails.push(values);
          });
        }
      }
    })
  }

  loadCampingData() {
    this.CampingDetails = [];
    let filter: any = { count: this.cardCount, locationId: this.locationId, page: this.campingPage, status: 1 };
    this.http.post(environment.apiUrl + `/merchantactivities/list`, { ...filter, category: 'camping' }, { responseType: 'json' }).subscribe(async (data: any) => {
      if (data.status === "SUCCESS") {
        this.nonFiteredCampingData.push(...data.merchantactivities);
        data.merchantactivities = this.removeDuplicates(data.merchantactivities, "activityid", "merchantid", this.CampingDetails);
        data.merchantactivities.forEach((activity: any) => {
          let value: any = {};
          value["id"] = activity.id;
          value["campingName"] = "";
          value["description"] = activity.description;
          value["activityname"] = activity.activitiesaddressline1;
          value["merchantname"] = activity.merchantname;
          value["merchantid"] = activity.merchantid;
          value["locationid"] = activity.activitieslocationid;
          value["locationname"] = activity.activitieslocationname;
          activity.campingName = "";
          try {
            this.http.post(environment.apiUrl + '/coupons/is-coupon-available', { status: 1, locationId: this.locationId, merchantId: activity.merchantid, activityId: activity.activityid, currentTimeStamp: new Date().getTime(), isMobileApp: this.ifMobileApp() }, { responseType: 'json' }).subscribe((data: any) => {
              if (data.status === "SUCCESS") {
                activity.hasCoupon = data.coupons;
                value["hasCoupon"] = data.coupons;
              } else {
                activity.hasCoupon = false;
                value["hasCoupon"] = false;
              }
            })
          } catch (error) {
            console.log(error);
            activity.hasCoupon = false;
            value["hasCoupon"] = false;
          }
          if (activity.additionalinfo && activity.additionalinfo != null && activity.additionalinfo != "") {
            if (JSON.parse(activity.additionalinfo).campingname) {
              activity.campingName = JSON.parse(activity.additionalinfo).campingname;
              value["campingName"] = JSON.parse(activity.additionalinfo).campingname;
            }
            if (JSON.parse(activity.additionalinfo).latitude != undefined && JSON.parse(activity.additionalinfo).longitude != undefined && JSON.parse(activity.additionalinfo).latitude != null && JSON.parse(activity.additionalinfo).longitude != null && JSON.parse(activity.additionalinfo).latitude != 0 && JSON.parse(activity.additionalinfo).longitude) {
              activity.campingLatitude = JSON.parse(activity.additionalinfo).latitude;
              activity.campingLongitude = JSON.parse(activity.additionalinfo).longitude;
              value["campingLatitude"] = JSON.parse(activity.additionalinfo).latitude;
              value["campingLongitude"] = JSON.parse(activity.additionalinfo).longitude;
              this.dataService.getActivitiesDistance(this.userLocation.latitude, this.userLocation.longitude, JSON.parse(activity.additionalinfo).latitude, JSON.parse(activity.additionalinfo).longitude).subscribe({
                next: (res: any) => {
                  let km = Math.round(res.distance_in_km)
                  activity.distanceAway = `${km}${(Number(km) > 1) ? " Kms away" : "Km away"}`;
                  value["distanceAway"] = `${km}${(Number(km) > 1) ? " Kms away" : "Km away"}`;
                }, error: (error) => {
                  console.log(error)
                }
              }
              );
            } else {
              activity.campingLatitude = 0;
              activity.campingLongitude = 0;
              activity.distanceAway = "0Km away";
              value["distanceAway"] = "0Km away";
              value["campingLatitude"] = 0;
              value["campingLongitude"] = 0;
            }
          }

          activity.src = [];
          value["src"] = [];
          if (activity.mapimages && activity.mapimages != null && activity.mapimages != "") {
            let Images = JSON.parse(activity.mapimages)
            Images.forEach((x: any) => {
              this.http.get(environment.apiUrl + `/uploads/getThumbnail?id=${x}`).subscribe((resValue: any) => {
                if (resValue.status === "SUCCESS") {
                  activity.src.push({ id: resValue.upload.id, img: resValue.upload.thumbnail });
                  value.src.push({ id: resValue.upload.id, img: resValue.upload.thumbnail });
                }
              })
            })
          }
          try {
            this.http.post(environment.apiUrl + `/reviews/reviewstar`, { dataFor: 'activity', dataForId: activity.activityid }, { responseType: 'json' }).subscribe((data: any) => {
              if (data.status === "SUCCESS") {
                activity.avgReview = Math.round(data.reviews.average);
                value["rating"] = Math.round(data.reviews.average);
                activity.total = data.reviews.total;
              }
            });
          } catch (error) {
            console.log(error)
          }
          try {
            this.http.get(environment.apiUrl + `/range/minpricebymerchantactivityid?merchantActivityId=${activity.id}`).subscribe((data: any) => {
              if (data.status === "SUCCESS") {
                activity.min_price = data.min_price;
                value["min_price"] = data.min_price;
              }
            });
          } catch (error) {
            console.log(error)
          }
          this.CampingDetails.push(value);
        });
        if (this.CampingDetails && (this.CampingDetails.length < this.cardCount) && (Number(data.count) > this.nonFiteredCampingData.length)) {
          this.loadCampingData();
        }
      }
    });
  }

  loadTrekkingData() {
    this.trekkingDetails = [];
    let filter: any = { count: this.cardCount, locationId: this.locationId, status: 1 };
    this.http.post(environment.apiUrl + `/activities/card-list`, { ...filter, category: 'trekking' }, { responseType: 'json' }).subscribe(async (data: any) => {
      {
        if (data.status === "SUCCESS") {
          data.activities.forEach((activity: any, index: number) => {
            let values: any = {};
            values['id'] = activity.id;
            values['name'] = activity.addressline1;
            values['category'] = activity.category;
            values['locationid'] = activity.locationid;
            values['src'] = [];
            values['description'] = '';
            values['locationname'] = activity.locationname;
            activity.src = [];
            const description = typeof activity.description === 'string' ? activity.description.trim() : '';
            const strippedDescription = description.replace(new RegExp('(<p><br></p>|<br>)$'), '').trim();
            if (strippedDescription) {
              values['description'] = this.sanitizer.bypassSecurityTrustHtml(activity.description);
            } else {
              values['description'] = '';
            }
            if (activity.additionalinfo && activity.additionalinfo != null && activity.additionalinfo != "") {
              let parsedfrom = JSON.parse(activity.additionalinfo);
              activity.difficulty = parsedfrom.difficulty;
              activity.totalaltitude = parsedfrom.totalaltitude;
              activity.numberofdays = parsedfrom.numberofdays;
              values['difficulty'] = parsedfrom.difficulty;
              values['totalaltitude'] = parsedfrom.totalaltitude;
              values['numberofdays'] = parsedfrom.numberofdays;
            }
            activity.slot_available = "";
            values["slot_available"] = "";
            try {
              this.http.post(environment.apiUrl + '/coupons/is-coupon-available', { status: 1, locationId: this.locationId, merchantId: 0, activityId: activity.id, currentTimeStamp: new Date().getTime(), isMobileApp: this.ifMobileApp() }, { responseType: 'json' }).subscribe((data: any) => {
                if (data.status === "SUCCESS") {
                  activity.hasCoupon = data.coupons;
                  values["hasCoupon"] = data.coupons;
                } else {
                  activity.hasCoupon = false;
                  values["hasCoupon"] = false;
                }
              })
            } catch (error) {
              console.log(error);
              activity.hasCoupon = false;
              values["hasCoupon"] = false;
            }
            try {
              this.http.get(environment.apiUrl + `/merchantactivities/packagetourslots?category=trekking&activityId=${activity.id}`).subscribe((data: any) => {
                if (data.status === "SUCCESS") {
                  if (data.slots.length > 0) {
                    activity.slot_available = true;
                    values["slot_available"] = true;
                  } else {
                    activity.slot_available = false;
                    values["slot_available"] = false;
                  }
                }
              });
            } catch (error) {
              console.log(error)
            }
            try {
              this.http.post(environment.apiUrl + `/reviews/reviewstar`, { dataFor: 'activity', dataForId: activity.id }, { responseType: 'json' }).subscribe((data: any) => {
                if (data.status === "SUCCESS") {
                  activity.avgReview = Math.round(data.reviews.average);
                  activity.total = data.reviews.total;
                  values["rating"] = Math.round(data.reviews.average);
                }
              });
            } catch (error) {
              console.log(error)
            }
            try {
              this.http.get(environment.apiUrl + `/uploads/listbydatafor?datafor=activity&dataforid=${activity.id}`).subscribe((data: any) => {
                if (data.status === "SUCCESS") {
                  for (const upload of data.uploads) {
                    this.http.get(environment.apiUrl + `/uploads/getThumbnail?id=${upload.id}`).subscribe((res: any) => {
                      if (res.status === "SUCCESS") {
                        activity.src.push(res.upload.thumbnail)
                        values.src.push(res.upload.thumbnail)
                      }
                    });
                  }
                }
              })
            } catch (error) {
              console.log(error)
            }
            activity.min_price = "";
            values["min_price"] = "";
            try {
              this.http.get(environment.apiUrl + `/range/minpricebyactivityid?activityId=${activity.id}`).subscribe((data: any) => {
                if (data.status === "SUCCESS") {
                  activity.min_price = data.min_price;
                  values["min_price"] = data.min_price;
                }
              });
            } catch (error) {
              console.log(error)
            }
            this.trekkingDetails.push(values);
          });
        }
      }
    });

  }

  loadTourData() {
    this.bikeTourDetails = [];
    let filter: any = { count: this.cardCount, locationId: this.locationId, status: 1 };
    this.http.post(environment.apiUrl + `/activities/card-list`, { ...filter, category: 'tour' }, { responseType: 'json' }).subscribe(async (data: any) => {
      {
        if (data.status === "SUCCESS") {
          data.activities.forEach((activity: any, index: number) => {
            let values: any = {};
            values["id"] = activity.id;
            values["name"] = activity.addressline1;
            values["src"] = [];
            activity.src = [];
            values['locationname'] = activity.locationname;
            values['locationid'] = activity.locationid;
            const description = typeof activity.description === 'string' ? activity.description.trim() : '';
            const strippedDescription = description.replace(new RegExp('(<p><br></p>|<br>)$'), '').trim();
            if (strippedDescription) {
              values['description'] = this.sanitizer.bypassSecurityTrustHtml(activity.description);
            } else {
              values['description'] = '';
            }
            try {
              this.http.post(environment.apiUrl + '/coupons/is-coupon-available', { status: 1, locationId: this.locationId, merchantId: 0, activityId: activity.id, currentTimeStamp: new Date().getTime(), isMobileApp: this.ifMobileApp() }, { responseType: 'json' }).subscribe((data: any) => {
                if (data.status === "SUCCESS") {
                  activity.hasCoupon = data.coupons;
                  values["hasCoupon"] = data.coupons;
                } else {
                  activity.hasCoupon = false;
                  values["hasCoupon"] = false;
                }
              })
            } catch (error) {
              console.log(error);
              activity.hasCoupon = false;
              values["hasCoupon"] = false;
            }
            if (activity.additionalinfo && activity.additionalinfo != null && activity.additionalinfo != "") {
              let parsedfrom = JSON.parse(activity.additionalinfo);
              activity.totaldistance = parsedfrom.totaldistance;
              activity.numberofdays = parsedfrom.numberofdays;
              activity.difficulty = parsedfrom.difficulty;
              values["totaldistance"] = parsedfrom.totaldistance;
              values["numberofdays"] = parsedfrom.numberofdays;
              values["difficulty"] = parsedfrom.difficulty;
            }
            activity.slot_available = "";
            values["slot_available"] = "";
            try {
              this.http.get(environment.apiUrl + `/merchantactivities/packagetourslots?category=tour&activityId=${activity.id}`).subscribe((data: any) => {
                if (data.status === "SUCCESS") {
                  if (data.slots.length > 0) {
                    activity.slot_available = true;
                    values["slot_available"] = true;
                  } else {
                    activity.slot_available = false;
                    values["slot_available"] = false;
                  }
                }
              });
            } catch (error) {
              console.log(error)
            }
            try {
              this.http.post(environment.apiUrl + `/reviews/reviewstar`, { dataFor: 'activity', dataForId: activity.id }, { responseType: 'json' }).subscribe((data: any) => {
                if (data.status === "SUCCESS") {
                  activity.avgReview = Math.round(data.reviews.average);
                  activity.total = data.reviews.total;
                  values["rating"] = Math.round(data.reviews.average);
                }
              });
            } catch (error) {
              console.log(error)
            }
            try {
              this.http.get(environment.apiUrl + `/uploads/listbydatafor?datafor=activity&dataforid=${activity.id}`).subscribe((data: any) => {
                if (data.status === "SUCCESS") {
                  for (const upload of data.uploads) {
                    this.http.get(environment.apiUrl + `/uploads/getThumbnail?id=${upload.id}`).subscribe((res: any) => {
                      if (res.status === "SUCCESS") {
                        activity.src.push(res.upload.thumbnail);
                        values.src.push(res.upload.thumbnail);
                      }
                    });
                  }
                }
              })
            } catch (error) {
              console.log(error)
            }
            activity.min_price = "";
            values["min_price"] = "";
            try {
              this.http.get(environment.apiUrl + `/range/minpricebyactivityid?activityId=${activity.id}`).subscribe((data: any) => {
                if (data.status === "SUCCESS") {
                  activity.min_price = data.min_price;
                  values["min_price"] = data.min_price;
                }
              });
            } catch (error) {
              console.log(error)
            }
            this.bikeTourDetails.push(values);
          });
        }
      }
    });
  }

  loadQuickActivitiesData() {
    this.quickActivityDetails=[];
    let filter: any = { count: this.cardCount, locationId: this.locationId, status: 1 };
    this.http.post(environment.apiUrl + `/activities/card-list`, { ...filter, category: 'activity' }, { responseType: 'json' }).subscribe(async (data: any) => {
      {
        if (data.status === "SUCCESS") {
          data.activities.forEach((activity: any) => {
            let values: any = {};
            values['id'] = activity.id;
            values['name'] = activity.addressline1;
            values['category'] = activity.category;
            values['src'] = [];
            values['description'] = '';
            values['locationid'] = activity.locationid;
            values['locationname'] = activity.locationname;
            activity.src = [];
            const description = typeof activity.description === 'string' ? activity.description.trim() : '';
            const strippedDescription = description.replace(new RegExp('(<p><br></p>|<br>)$'), '').trim();
            if (strippedDescription) {
              values['description'] = this.sanitizer.bypassSecurityTrustHtml(activity.description);
            } else {
              values['description'] = '';
            }
            try {
              this.http.post(environment.apiUrl + '/coupons/is-coupon-available', { status: 1, locationId: this.locationId, merchantId: 0, activityId: activity.id, currentTimeStamp: new Date().getTime(), isMobileApp: this.ifMobileApp() }, { responseType: 'json' }).subscribe((data: any) => {
                if (data.status === "SUCCESS") {
                  activity.hasCoupon = data.coupons;
                  values["hasCoupon"] = data.coupons;
                } else {
                  activity.hasCoupon = false;
                  values["hasCoupon"] = false;
                }
              })
            } catch (error) {
              console.log(error);
              activity.hasCoupon = false;
              values["hasCoupon"] = false;
            }
            try {
              this.http.get(environment.apiUrl + `/uploads/listbydatafor?datafor=activity&dataforid=${activity.id}`).subscribe((data: any) => {
                if (data.status === "SUCCESS") {
                  for (const upload of data.uploads) {
                    this.http.get(environment.apiUrl + `/uploads/getThumbnail?id=${upload.id}`).subscribe((res: any) => {
                      if (res.status === "SUCCESS") {
                        activity.src.push(res.upload.thumbnail);
                        values.src.push(res.upload.thumbnail);
                      }
                    });
                  }
                }
              })
            } catch (error) {
              console.log(error)
            }
            try {
              this.http.post(environment.apiUrl + `/reviews/reviewstar`, { dataFor: 'activity', dataForId: activity.id }, { responseType: 'json' }).subscribe((data: any) => {
                if (data.status === "SUCCESS") {
                  activity.avgReview = Math.round(data.reviews.average);
                  values["rating"] = Math.round(data.reviews.average);
                }
              });
            } catch (error) {
              console.log(error)
            }
            activity.min_price = "";
            values["min_price"] = "";
            try {
              this.http.get(environment.apiUrl + `/range/minpricebyactivityid?activityId=${activity.id}`).subscribe((data: any) => {
                if (data.status === "SUCCESS") {
                  activity.min_price = data.min_price;
                  values["min_price"] = data.min_price;
                }
              });
            } catch (error) {
              console.log(error)
            }
            if (activity.additionalinfo != null && activity.additionalinfo != "") {
              activity.difficulty = JSON.parse(activity.additionalinfo).difficulty;
              values['difficulty'] = activity.difficulty;
            }
            this.quickActivityDetails.push(values);
          });
        }
      }
    })
  }

  loadTopAdventuristsData() {
    this.topAdventuristsData = [];
    let filter = { count: 3, locationId: this.locationId, status: 1 };
    this.http.post(environment.apiUrl + `/merchants/topmerchants-card`, { ...filter, sortColumn: "average_star" }, { responseType: 'json' }).subscribe(async (data: any) => {
      {
        if (data.status === "SUCCESS") {
          data.merchants.forEach((merchant: any) => {
            let values: any = {};
            values["id"] = merchant.id;
            values["name"] = merchant.name;
            values["description"] = merchant.description;
            values["locationId"] = merchant.locationid;
            values["locationName"] = merchant.locationname;
            values["address"] = merchant.addressline1;
            values["activityCount"] = merchant.activitycount;
            values["src"] = [];
            values["reviewed_user_pic"] = [];
            merchant.src = [];
            try {
              this.http.post(environment.apiUrl + '/coupons/is-coupon-available', { status: 1, locationId: this.locationId, merchantId: merchant.id, activityId: 0, currentTimeStamp: new Date().getTime(), isMobileApp: this.ifMobileApp() }, { responseType: 'json' }).subscribe((data: any) => {
                if (data.status === "SUCCESS") {
                  merchant.hasCoupon = data.coupons;
                  values["hasCoupon"] = data.coupons;
                } else {
                  merchant.hasCoupon = false;
                  values["hasCoupon"] = false;
                }
              })
            } catch (error) {
              console.log(error);
              merchant.hasCoupon = false;
              values["hasCoupon"] = false;
            }
            let data = { dataFor: 'merchant', merchantId: merchant.id, count: 4, page: 1, sortColumn: 'id' };
            this.http.post(environment.apiUrl + `/reviews/merchantlist`, data, { responseType: 'json' }).subscribe(async (data: any) => {
              if (data.status === "SUCCESS") {
                data.reviews.forEach(async (review: any) => {
                  try {
                    this.http.get(environment.apiUrl + `/uploads/listbydatafor?datafor=userprofile&dataforid=${review.createdby}`).subscribe((data: any) => {
                      if (data.status === "SUCCESS") {
                        if (data.uploads.length > 0) {
                          for (const upload of data.uploads) {
                            this.http.get(environment.apiUrl + `/uploads/getThumbnail?id=${upload.id}`).subscribe((res: any) => {
                              if (res.status === "SUCCESS") {
                                values["reviewed_user_pic"].push(res.upload.thumbnail);
                              }
                            });
                          }
                        } else {
                          values["reviewed_user_pic"].push(null);
                        }
                      }
                    })
                  } catch (error) {
                    console.log(error)
                  }
                })
              }
            });
            try {
              this.http.get(environment.apiUrl + `/uploads/listbydatafor?datafor=merchantprofile&dataforid=${merchant.id}`).subscribe((data: any) => {
                if (data.status === "SUCCESS") {
                  for (const upload of data.uploads) {
                    this.http.get(environment.apiUrl + `/uploads/getThumbnail?id=${upload.id}`).subscribe((res: any) => {
                      if (res.status === "SUCCESS") {
                        merchant.src.push(res.upload.thumbnail);
                        values["src"].push(res.upload.thumbnail);
                      }
                    });
                  }
                }
              })
            } catch (error) {
              console.log(error)
            }
            merchant.averagestar = parseInt(merchant.average_star);
            values["averagestar"] = parseInt(merchant.average_star) || 0;
            this.topAdventuristsData.push(values);
          });
        }
      }
    })
  }

  async loadRentalData() {
    let activityVariation: any = await this.http.get(environment.apiUrl + `/merchantactivities/activityVariationDetails?category=bikerental${(this.locationId != null) ? '&locationId=' + this.locationId : ''}`).toPromise();

    let inventoryVariationValueIds: any = [], inventoryVariations: any = [], nonFilteredBikeCard: any = [];
    this.http.get(environment.apiUrl + `/merchantinventories/viewInventory?category=bikerental${(this.locationId != null) ? '&locationId=' + this.locationId : ''}`).subscribe(async (data: any) => {
      if (data.status === "SUCCESS") {
        if (data.merchantinventories.length > 0) {
          let inventoryVariationIds = [];
          data.merchantinventories.forEach((x: any) => {
            if (!inventoryVariationIds.includes(x.variationid)) inventoryVariationIds.push(x.variationid);
            inventoryVariationValueIds.push(x.variationvalueid);
          })
          const inventoryVariationRequests = inventoryVariationIds.map((x: any) => {
            return this.http.get(environment.apiUrl + `/variations/get?id=${x}`).toPromise(); // Convert the Observable to a Promise
          });

          const loadVariationRequests = await Promise.all(inventoryVariationRequests);
          loadVariationRequests.forEach((x: any) => {
            inventoryVariations.push(x.variations)
          })

          const sortByParentVariation = (data) => {
            const sortedData = [...data];
            sortedData.sort((a, b) => {
              if (a.parentvariation === '0') {
                return -1;
              } else if (b.parentvariation === '0') {
                return 1;
              } else {
                return a.parentvariation.localeCompare(b.parentvariation);
              }
            });
            return sortedData;
          };
          inventoryVariations = sortByParentVariation(inventoryVariations);
          const inventoryVariationValueRequests = inventoryVariationValueIds.map((x: any) => {
            return this.http.get(environment.apiUrl + `/variationvalues/get?id=${x}`).toPromise(); // Convert the Observable to a Promise
          });
          const loadVariationValueRequests = await Promise.all(inventoryVariationValueRequests);
          loadVariationValueRequests.forEach((x: any) => {
            if (x.variationvalues.parentvariationvalueid != null && x.variationvalues.parentvariationvalueid != 0) {
              nonFilteredBikeCard.push(x.variationvalues)
            }
          });

          nonFilteredBikeCard.forEach((x: any) => {
            x.locationname = this.locationname;
            x.locationid = this.locationId;
            let variationTime = activityVariation.activityVariationDetails.filter((activity: any) => {
              return activity.merchants.some((y: any) =>
                y.variationtypeid == x.variationtypeid &&
                y.variationvalueid == x.id
              );
            });
            x.price = "";
            if (variationTime.length > 0) {
              let merchantActivityId: any = [];
              variationTime.forEach((x) => {
                merchantActivityId.push(x.id)
              })
              try {
                this.http.get(environment.apiUrl + `/range/minpricebymerchantactivityid?merchantActivityId=${merchantActivityId.map((id: any) => `${id.trim()}`).join(',')}`).subscribe((data: any) => {
                  if (data.status === "SUCCESS") {
                    x.min_price = data.min_price;
                  }
                });
              } catch (error) {
                console.log(error)
              }
            } else {
              x.min_price = 0;
            }
            try {
              this.http.post(environment.apiUrl + '/coupons/is-coupon-available', { status: 1, locationId: this.locationId, merchantId: 0, activityId: activityVariation.activityVariationDetails[0].activityid, currentTimeStamp: new Date().getTime(), isMobileApp: this.ifMobileApp() }, { responseType: 'json' }).subscribe((data: any) => {
                if (data.status === "SUCCESS") {
                  x.hasCoupon = data.coupons;
                } else {
                  x.hasCoupon = false;
                }
              })
            } catch (error) {
              x.hasCoupon = false;
              console.log(error);
            }
            x.src = [];
            x.availability = true;
            x.name = x.value;
            this.http.get(environment.apiUrl + `/uploads/listbydatafor?datafor=variationvalueimage&dataforid=${x.id}`).subscribe((data: any) => {
              if (data.status == "SUCCESS") {
                for (const upload of data.uploads) {
                  this.http.get(environment.apiUrl + `/uploads/getThumbnail?id=${upload.id}`).subscribe((res: any) => {
                    if (res.status === "SUCCESS") {
                      x.src.push(res.upload.thumbnail)
                    }
                  });
                }
              }
            })
          })
          nonFilteredBikeCard.sort((a, b) => Number(a.min_price) - Number(b.min_price));
          this.rentalDetails = nonFilteredBikeCard;
        }
      }
    })
  }

  loadTopActivities() {
    this.topActivityCards = [];
    let filter: any = { count: 6, page: 1, status: 1, sort: "DESC", sortColumn: "total_star", locationId: this.locationId };
    this.http.post(environment.apiUrl + `/merchantactivities/topactivity`, filter, { responseType: 'json' }).subscribe(async (data: any) => {
      {
        if (data.status === "SUCCESS") {
          data.merchantactivities.forEach((activity: any, index: number) => {
            activity.src = [];
            if (activity.activitiescategory == "camping") {
              activity.campingName = "";
              if (activity.additionalinfo && activity.additionalinfo != null && activity.additionalinfo != "") {
                if (JSON.parse(activity.additionalinfo).campingname) {
                  activity.campingName = JSON.parse(activity.additionalinfo).campingname;
                }
              }
            }
              if (activity.activitiescategory == "camping" || activity.activitiescategory == "bikerental") {
                if (activity.mapimages && activity.mapimages != null && activity.mapimages != "") {
                  let Images = JSON.parse(activity.mapimages)
                  Images.forEach((x: any) => {
                    this.http.get(environment.apiUrl + `/uploads/getThumbnail?id=${x}`).subscribe((value: any) => {
                      if (value.status === "SUCCESS") {
                        activity.src.push(value.upload.thumbnail)
                      }
                    })
                  })
                };
              } else {
                try {
                  this.http.get(environment.apiUrl + `/uploads/listbydatafor?datafor=activity&dataforid=${activity.activityid}`).subscribe((data: any) => {
                    if (data.status === "SUCCESS") {
                      for (const upload of data.uploads) {
                        this.http.get(environment.apiUrl + `/uploads/getThumbnail?id=${upload.id}`).subscribe((res: any) => {
                          if (res.status === "SUCCESS") {
                            activity.src.push(res.upload.thumbnail)
                          }
                        });
                      }
                    }
                  })
                } catch (error) {
                  console.log(error)
                }
              }
            const strippedDescription = activity.description.replace(new RegExp('(<p><br></p>|<br>)$'), '').trim();
            if (strippedDescription) {
              activity.description = this.sanitizer.bypassSecurityTrustHtml(activity.description);
              const tempElement = document.createElement('div');
              tempElement.innerHTML = activity.description;
              let innerText:any = tempElement.innerText || tempElement.textContent;
              innerText = innerText.replace(/SafeValue must use \[property\]=binding:/g, '');
              activity.description = innerText;
              tempElement.remove();
            } else {
              activity.description = '';
            }
            const strippedDescription1 = activity.activitiesdescription.replace(new RegExp('(<p><br></p>|<br>)$'), '').trim();
            if (strippedDescription1) {
              activity.activitiesdescription = this.sanitizer.bypassSecurityTrustHtml(activity.activitiesdescription);
              const tempElement = document.createElement('div');
              tempElement.innerHTML = activity.activitiesdescription;
              let innerText:any = tempElement.innerText || tempElement.textContent;
              innerText = innerText.replace(/SafeValue must use \[property\]=binding:/g, '');
              activity.activitiesdescription = innerText;
              tempElement.remove();
            } else {
              activity.activitiesdescription = '';
            }
          });
          this.topActivityCards = data.merchantactivities;
          this.topActivityChunkCards = this.chunk(this.topActivityCards, 2);
        }
      }
    });
  }

  loadBlogData() {
    this.recentBlogs = [];
    this.http.get(environment.apiUrl + `/blogs/posts?categories=${this.category_id}&page=1&per_page=4&_embed`, { observe: 'response' }).subscribe(
      (response: HttpResponse<any>) => {
        response.body.output.forEach((x: any) => {
          x.src = [];
          if (x._embedded["wp:featuredmedia"]) {
            x._embedded["wp:featuredmedia"].forEach((y: any) => {
              x.src.push(y.link)
            })
          }
        });
        this.recentBlogs = response.body.output;
      },
      (error) => {
        console.log('An error occurred:', error);
      }
    );
  }

  loadFaq() {
    this.http.post(environment.apiUrl + '/locations/faq-list', {locationid:this.locationId, page:1, count:100}).subscribe((data:any) => {
      if(data.status == "SUCCESS"){
          this.visibilityStates = Array(data.faq.length).fill(false); 
          data.faq.forEach((x:any) => {
            const strippedDescription1 = x.answer.replace(new RegExp('(<p><br></p>|<br>)$'), '').trim();
            if (strippedDescription1) {
              x.answer = this.sanitizer.bypassSecurityTrustHtml(x.answer);
            } else {
              x.answer = '';
            }

          })
          this.faq = data.faq;
      }
    });

  }

  toggleContent(index: number) {
    // Set all other questions to false and toggle only the clicked one
    this.visibilityStates = this.visibilityStates.map((state, i) => i === index ? !state : false);
  }


  openBlog(id:any, title:any){
    this.router.navigate(['/blogs-detail', id, title.trim().replace(/[^a-zA-Z0-9\s]/g, '-').replace(/\s+/g, '-').toLowerCase()])
  }

  openHomePage(category: any) {
    switch (category) {
      case 'rafting':
        sessionStorage.setItem('userData', JSON.stringify({ category: 'rafting' }))
        break;
      case 'trekking':
        sessionStorage.setItem('userData', JSON.stringify({ category: 'trekking' }))
        break;
      case 'camping':
        sessionStorage.setItem('userData', JSON.stringify({ category: 'camping' }))
        break;
      case 'bike-rental':
        sessionStorage.setItem('userData', JSON.stringify({ category: 'bike-rental' }))
        break;
      case 'tour':
        sessionStorage.setItem('userData', JSON.stringify({ category: 'tour' }))
        break;
      case 'activity':
        sessionStorage.setItem('userData', JSON.stringify({ category: 'activity' }))
        break;
      case 'topAdventurists':
        sessionStorage.setItem('userData', JSON.stringify({ category: 'topAdventurists' }))
        break;
    }
    let name = this.locationname.trimEnd().toLowerCase().replaceAll(" ", "-");
    this.router.navigate(['/home', 'location', this.locationId, name]);
  }

  showPhase(category: any) {
    switch (category) {
      case 'rafting':
        sessionStorage.setItem('userData', JSON.stringify({ category: 'rafting' }))
        break;
      case 'bike-rental':
        sessionStorage.setItem('userData', JSON.stringify({ category: 'bike-rental' }))
        break;
      case 'trekking':
        sessionStorage.setItem('userData', JSON.stringify({ category: 'trekking' }))
        break;
      case 'tour':
        sessionStorage.setItem('userData', JSON.stringify({ category: 'tour' }))
        break;
      case 'activity':
        sessionStorage.setItem('userData', JSON.stringify({ category: 'activity' }))
        break;
      case 'topAdventurists':
        sessionStorage.setItem('userData', JSON.stringify({ category: 'topAdventurists' }))
        break;
      case 'camping':
        sessionStorage.setItem('userData', JSON.stringify({ category: 'camping' }))
        break;
    }
    let name = this.locationname.trimEnd().toLowerCase().replaceAll(" ", "-");
    this.router.navigate(['/home', 'location', this.locationId, name]);
  }

  openTopActivity(category: any, merchantactivityId: any, activityId: any) {
    if (category == 'camping') {
      let locationName = this.locationname.trimEnd().toLowerCase().replaceAll(" ", "-");
      let locationId = this.topActivityCards.find((x: any) => x.id == merchantactivityId).locationid;
      let activityName = this.topActivityCards.find((x: any) => x.id == merchantactivityId).campingName.trimEnd().toLowerCase().replaceAll(" ", "-");
      this.router.navigate(['location', locationId, 'camping-details', merchantactivityId, locationName, activityName]);
    } else if (category == 'bikerental') {
      sessionStorage.setItem('userData', JSON.stringify({ category: 'bike-rental' }));
      this.router.navigate(['home', 'location', this.locationId, this.locationname]);
    } else if (category == 'tour') {
      let locationName = this.locationname.trimEnd().toLowerCase().replaceAll(" ", "-");
      let locationId = this.topActivityCards.find((x: any) => x.activityid == activityId).locationid;
      let activityName = this.topActivityCards.find((x: any) => x.activityid == activityId).activitiesaddressline1.trimEnd().toLowerCase().replaceAll(" ", "-");
      this.router.navigate(['location', locationId, 'package-tour-detail', activityId, locationName, activityName]);
    } else if (category == 'trekking') {
      let locationName = this.locationname.trimEnd().toLowerCase().replaceAll(" ", "-");
      let locationId = this.topActivityCards.find((x: any) => x.activityid == activityId).locationid;
      let activityName = this.topActivityCards.find((x: any) => x.activityid == activityId).activitiesaddressline1.trimEnd().toLowerCase().replaceAll(" ", "-");
      this.router.navigate(['location', locationId, 'trekking-detail', activityId, locationName, activityName]);
    }
     else {
      let locationName = this.locationname.trimEnd().toLowerCase().replaceAll(" ", "-");
      let locationId = this.topActivityCards.find((x: any) => x.activityid == activityId).locationid;
      let activityName = this.topActivityCards.find((x: any) => x.activityid == activityId).activitiesaddressline1.trimEnd().toLowerCase().replaceAll(" ", "-");
      this.router.navigate(['location', locationId, 'activity-details', activityId, locationName, activityName]);
    }
  }

  scrollToSection(sectionId: string): void {
    const container = document.getElementById('section-activities');  // Target the container
    const element = document.getElementById(sectionId); 
    if (container && element) {
      const containerTop = container.getBoundingClientRect().top;  // Get container's top position
      const elementTop = element.getBoundingClientRect().top;  // Get element's top position
  
      const yPosition = elementTop - containerTop + container.scrollTop ;  // Calculate relative position inside the container
  
      container.scrollTo({
        top: yPosition,
        behavior: 'smooth'
      });
    }
  }


  ifMobileApp() {
    if (this.userAgent.includes('AdvenzoneMobileApp')) {
      return true;
    } else {
      return false;
    }
  }

  removeDuplicates(arr: any[], prop1: string, prop2: string, prevArr: any[]) {
    arr = arr.filter((obj, index, self) =>
      index === self.findIndex((o) =>
        o[prop1] === obj[prop1] && o[prop2] === obj[prop2]
      )
    );

    arr = arr.filter((obj, index, self) =>
      prevArr.findIndex((o) =>
        o[prop1] === obj[prop1] && o[prop2] === obj[prop2]
      ) == -1
    );
    return arr;
  }

}
