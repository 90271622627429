import { Component, ElementRef, Renderer2, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AuthService } from '../auth.service';
import { FormControl, FormBuilder, AbstractControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { MerchantLoginDialogueComponent } from '../merchant-login-dialogue/merchant-login-dialogue.component';
import { ModalAlertComponent } from '../../modal-alert/modal-alert.component';
import { catchError } from 'rxjs/operators';
import { MerchantAgreementComponent } from '../../merchant-agreement/merchant-agreement.component';
import * as mapboxgl from 'mapbox-gl';
import * as MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import { MatChipInputEvent } from '@angular/material/chips';
import { SearchCountryField, CountryISO, PhoneNumberFormat } from 'ngx-intl-tel-input';

declare var $: any;


@Component({
  selector: 'app-merchant-signup',
  templateUrl: './merchant-signup.component.html',
  styleUrls: ['./merchant-signup.component.css']
})
export class MerchantSignupComponent {
  public passwordVisible: boolean = false;
  isSubmitting: boolean = false;
  merchantSignUpForm!: FormGroup;
  locations: any = [];
  states: any = [];
  districts: any = [];
  countryid: any = "";
  locationStateid: any = "";
  districtid: any = "";
  selectedLocation: any;
  map!: mapboxgl.Map;
  style = 'mapbox://styles/mapbox/streets-v12';
  marker: any;
  hostName: any = "";
  userAgent: string = "";
  defaultMerchantTags: any[] = ["adventurists", "top adventurist", "best adventurist", "top rated adventurists", "adventure tourism", "adventure travel", "adventure seekers", "best adventurists", "adventure sports", "adventure tourism", "outdoor adventures", "thrill-seekers", "extreme adventurers"];
  customSearchTags: any[] = [];
  operatorProfile: string = "";
  businessProfile: string = "";
  businessProfileId: any;
  operatorProfileId: any;

  @ViewChild('operatorProfileInput') operatorProfileInput: ElementRef<any> | undefined;
  @ViewChild('businessProfileInput') businessProfileInput: ElementRef<any> | undefined;
  constructor(public dialog: MatDialog, private el: ElementRef, private formBuilder: FormBuilder, private renderer: Renderer2, private authService: AuthService, private merchantSignupDialogRef: MatDialogRef<MerchantSignupComponent>, private snackBar: MatSnackBar, private http: HttpClient) {
    this.hostName = window.location.host;
    this.userAgent = window.navigator.userAgent;
  }

  individualGuideSelected = true;
  companySelected = false;
  contactDetails = false;
  latitude: any = 22.1991660760527;
  longitude: any = 78.476681027237;

  separateDialCode = false;
	SearchCountryField = SearchCountryField;
	CountryISO = CountryISO;
  PhoneNumberFormat = PhoneNumberFormat;
	preferredCountries: CountryISO[] = [CountryISO.UnitedStates, CountryISO.UnitedKingdom];

  ngOnInit(): void {
    this.merchantSignUpForm = this.formBuilder.group(
      {
        name: new FormControl('', Validators.required),
        shopName: new FormControl('', Validators.required),
        email: new FormControl('', [Validators.required, Validators.email]),
        phone: new FormControl('', Validators.required),
        alternatephoneno: new FormControl('', Validators.required),
        description: new FormControl('', Validators.required),
        location: new FormControl('', Validators.required),
        address: new FormControl('', Validators.required),
        city: new FormControl(''),
        district: new FormControl('', Validators.required),
        state: new FormControl('', Validators.required),
        pincode: new FormControl('', [Validators.required, Validators.pattern(/^[0-9]+$/)]),
        webiste: new FormControl(''),
        agree: new FormControl('', [Validators.required, this.validateAgreement]),
        tags: new FormControl([])
      }
    );

    this.http.post(`${environment.apiUrl}/locations/list`, { count: 500, sortColumn: 'locationname', sort: 'ASC' }).subscribe((data: any) => {
      if (data.status === "SUCCESS") {
        this.locations = data.locations
      }
    })
    this.http.post(`${environment.apiUrl}/countries/list`, { status: 1, count: 100, sortColumn: 'name', sort: 'ASC' }).subscribe((data: any) => {
      if (data.status === "SUCCESS") {
        data.countries.forEach((county: any) => {
          if (county.name.toLowerCase() === 'india') {
            this.countryid = county.id;
          }
        });
        this.http.post(`${environment.apiUrl}/states/list`, { status: 1, countryId: this.countryid, count: 100, sortColumn: 'name', sort: 'ASC' }).subscribe((data: any) => {
          if (data.status === "SUCCESS") {
            this.states = data.states;
          }
        })
      }
    })

  }

  
  ngAfterViewInit(){
    if (typeof $ !== 'undefined') {
      $(".bussinessphone input").attr('placeholder', "Business Number");
      $(".phone input").attr('placeholder', "Personal Number");
    }
    const divElement = this.el.nativeElement.querySelector('.bussinessphone');
    if (divElement) {
      this.renderer.listen(divElement, 'click', this.handleClick.bind(this));
    }
    const phoneElement = this.el.nativeElement.querySelector('.phone');
    if (phoneElement) {
      this.renderer.listen(phoneElement, 'click', this.handleClick.bind(this));
    }
  }

  handleClick(event: Event) {
    $(".bussinessphone input").attr('placeholder', "Business Number");
    $(".phone input").attr('placeholder', "Personal Number");
  }

  triggerUpload(dataFrom: string) {
    if (dataFrom == "operatorProfileInput") {
      this.renderer.selectRootElement(this.operatorProfileInput?.nativeElement).click();
    } else {
      this.renderer.selectRootElement(this.businessProfileInput?.nativeElement).click();
    }
  }

  handleEvent(event: any) {
    // console.log(event);
    // console.log(event.type, "type")
  }

  addListeners(reader: any) {
    // console.log(reader)
    reader.onloadstart =this.handleEvent;
    reader.onload =this.handleEvent;
    reader.onloadend =this.handleEvent;
    reader.onerror =this.handleEvent;
    reader.onabort =this.handleEvent;
  }
  
  handlePicChange = (event: any, dataFrom: string) => {
    const file = event.target.files[0];
    let reader = new FileReader();
    
    if(reader["__zone_symbol__originalInstance"]) {
      reader = reader["__zone_symbol__originalInstance"];
    }
    this.addListeners(reader);

    reader.onload = () => {
      const base64String = reader.result as string;

      const img = new Image();
      img.onload = () => {
        const canvas = document.createElement('canvas');
        const ctx: any = canvas.getContext('2d');
        const maxWidth = 2400;
        const maxHeight = 1800;

        let width = img.width;
        let height = img.height;

        // Calculate aspect ratio
        var aspectRatio = width / height;
        var targetRatio = 4 / 3;
        var tolerance = 0.01; // Allow some tolerance for ratio differences

        // if (Math.abs(aspectRatio - targetRatio) > tolerance) {
        //   // Aspect ratio does not match 4:3
        //   this.snackBar.open('Image must have a 4:3 aspect ratio.', 'close', { duration: 3000, verticalPosition: 'top' },);
        //   return; // Skip processing this image
        // }

        if (width > maxWidth) {
          height *= maxWidth / width;
          width = maxWidth;
        }

        if (height > maxHeight) {
          width *= maxHeight / height;
          height = maxHeight;
        }

        canvas.width = width;
        canvas.height = height;

        ctx.drawImage(img, 0, 0, width, height);

        const compressedBase64String = canvas.toDataURL('image/jpeg', 1); // Adjust quality as needed

        // Create thumbnail
        const thumbnailCanvas = document.createElement('canvas');
        const thumbnailCtx: any = thumbnailCanvas.getContext('2d');

        thumbnailCanvas.width = 400;
        thumbnailCanvas.height = 300;

        thumbnailCtx.drawImage(img, 0, 0, 400, 300);

        const thumbnailBase64String = thumbnailCanvas.toDataURL('image/jpeg', 1);
        const inputData: any = {
          createdBy: '0',
          dataType: 'image',
          dataForId: '0',
          data: compressedBase64String,
          thumbnail: thumbnailBase64String
        };
        if (dataFrom == 'operatorProfileInput') {
          inputData.dataFor = 'merchantprofile';
        } else {
          inputData.dataFor = 'merchantshopprofile';
        }
        this.http.post(environment.apiUrl + `/uploads/upload`, inputData, { responseType: 'json' }).subscribe(async (data: any) => {
          if (data.status === "SUCCESS") {
            if (dataFrom == 'operatorProfileInput') {
              this.operatorProfile = base64String;
              this.operatorProfileId = data.data.id;
              this.snackBar.open('Operator Profile Image added successfully.', 'close', { duration: 3000, verticalPosition: 'top' },);
            } else {
              this.businessProfile = base64String;
              this.businessProfileId = data.data.id;
              this.snackBar.open('Business Profile Image added successfully.', 'close', { duration: 3000, verticalPosition: 'top' },);
            }
          } else {
            this.snackBar.open('Some error occured while profile image.', 'close', { duration: 3000, verticalPosition: 'top' },);
          }
        });
      };

      img.src = reader.result as string;
    }

    reader.readAsDataURL(file);
  }

  closeMerchantSignUpDialog() {
    this.merchantSignupDialogRef.close();
  }

  togglePasswordVisibility() {
    this.passwordVisible = !this.passwordVisible;
  }

  get f(): { [key: string]: AbstractControl } {
    return this.merchantSignUpForm.controls;
  }

  openMerchantLoginDialogue() {
    this.merchantSignupDialogRef.close();
    const isMobile = window.innerWidth < 768;
    let sizes = {
      height: '90vh',
      width: '40%',
      maxWidth: 'unset',
      panelClass: 'bg-merchant-login-class'
    };
    if (isMobile) {
      sizes = {
        width: '100%',
        maxWidth: 'unset',
        height: '100%',
        panelClass: 'bg-merchant-login-class'
      }
    }
    this.dialog.open(MerchantLoginDialogueComponent, {
      ...sizes,
      disableClose: true,
      data: {

      },
    });
  }

  addSearchTag(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();
    if (value) {
      this.customSearchTags.push(value);
    }
    event.chipInput!.clear();
  }

  removeSearchTag(tag: any): void {
    const index = this.customSearchTags.indexOf(tag);
    if (index >= 0) {
      this.customSearchTags.splice(index, 1);
    }
  }

  onMerchantSignUp() {
    this.isSubmitting = true;
    setTimeout(()=>{
      $(".bussinessphone input").attr('placeholder', "Business Number");
      $(".phone input").attr('placeholder', "Personal Number");
    },2000)
    if (this.merchantSignUpForm.invalid) {
      return;
    }

    this.merchantSignupDialogRef.close();
    const isMobile = window.innerWidth < 768;
    let sizes = {
      height: 'fit-content',
      width: 'fit-content',
      maxWidth: '50vw',
    };
    if (isMobile) {
      sizes = {
        height: 'fit-content',
        width: 'fit-content',
        maxWidth: 'unset',
      }
    }
    let input: any = {};
    input.name = this.merchantSignUpForm.value.name;
    input.shopName = this.merchantSignUpForm.value.shopName;
    input.locationId = this.merchantSignUpForm.value.location;
    input.addressLine1 = this.merchantSignUpForm.value.address;
    input.city = this.merchantSignUpForm.value.city;
    input.districtId = this.merchantSignUpForm.value.district;
    input.stateId = this.merchantSignUpForm.value.state;
    input.country = this.countryid;
    input.pinCode = this.merchantSignUpForm.value.pincode;
    input.description = this.merchantSignUpForm.value.description;
    input.webiste = this.merchantSignUpForm.value.webiste;
    input.email = this.merchantSignUpForm.value.email;
    input.phone = this.merchantSignUpForm.value.phone?.e164Number.replace('+','');
    input.alternatephoneno = this.merchantSignUpForm.value.alternatephoneno?.e164Number.replace('+','');
    input.type = this.individualGuideSelected ? 'individual' : 'company';
    input.status = 2;
    input.changePassword = true;
    input.agree = this.merchantSignUpForm.value.agree;
    input.latitude = this.latitude;
    input.longitude = this.longitude;
    let images = [];
    if (this.businessProfileId) images.push(this.businessProfileId);
    if (this.operatorProfileId) images.push(this.operatorProfileId);
    input.images = images;
    input.tags = [...this.defaultMerchantTags, ...this.merchantSignUpForm.value.tags]

    if (!this.ifMobileApp()) {
      input.clienthost = 'https://' + this.hostName + '/'
    }
    this.http.post(`${environment.apiUrl}/merchants/create`, input).pipe(
      catchError((_err: HttpErrorResponse) => {
        let errors = _err.error.errors;
        const errorMessagesArray = [];
        for (const key in errors) {
          if (Object.hasOwnProperty.call(errors, key)) {
            const messages = errors[key];
            for (const message of messages) {
              errorMessagesArray.push(`${key}: ${message}`);
            }
          }
        }
        this.dialog.open(ModalAlertComponent, {
          ...sizes,
          disableClose: true,
          data: {
            title: 'Some Error occurs',
            message: errorMessagesArray.join('\n'),
            status: 'danger'
          },
        })
        throw new Error("Error");
      })
    ).subscribe((data: any) => {
      if (data.status === "SUCCESS") {
        this.dialog.open(ModalAlertComponent, {
          ...sizes,
          disableClose: true,
          data: {
            title: 'Thank you for registering!',
            message: 'Your account is being reviewed by an administrator. You will receive your login credentials via email once your account has been approved.',
            status: 'success'
          },
        })
      }
    });
  }

  onChangeLocation() {
    let selectedLocationId = this.merchantSignUpForm.value.location;
    if (selectedLocationId !== "") {
      this.http.get(`${environment.apiUrl}/locations/get?id=${selectedLocationId}`).subscribe((data: any) => {
        if (data.status === "SUCCESS") {
          this.countryid = data.location.countryid;
          this.locationStateid = data.location.stateid;
          this.districtid = data.location.districtid;
          this.latitude = data.location.latitude;
          this.longitude = data.location.longitude;
          this.marker.setLngLat({ lng: this.longitude, lat: this.latitude });
          this.map.setCenter({ lng: this.longitude, lat: this.latitude });
          this.map.setZoom(9);
          this.states = [];
          this.districts = [];
          this.http.post(`${environment.apiUrl}/states/list`, { status: 1, countryId: this.countryid, count: 100, sortColumn: 'name', sort: 'ASC' }).subscribe((stateData: any) => {
            if (stateData.status === "SUCCESS") {
              this.states = stateData.states;
            }
          })
          this.http.post(`${environment.apiUrl}/districts/list`, { status: 1, stateId: data.location.stateid, count: 100, sortColumn: 'name', sort: 'ASC' }).subscribe((districtData: any) => {
            if (districtData.status === "SUCCESS") {
              this.districts = districtData.districts;
            }
          })
        }
      })
    } else {
      this.locationStateid = '';
      this.districtid = '';
    }
  }

  onChangeContactPage() {
    this.contactDetails = true;
    setTimeout(() => {
      this.map = new mapboxgl.Map({
        container: 'map',
        style: this.style,
        center: [this.longitude, this.latitude],
        zoom: 2,
        accessToken: environment.mapbox.accessToken,
      });

      this.marker = new mapboxgl.Marker({
        draggable: true
      });

      const geocoder = new MapboxGeocoder({
        accessToken: environment.mapbox.accessToken,
        mapboxgl: mapboxgl,
        marker: false
      });

      geocoder.on('result', e => {
        this.latitude = e.result.center[1];
        this.longitude = e.result.center[0];
        this.marker.setLngLat({ lng: e.result.center[0], lat: e.result.center[1] });
      });

      this.marker.setLngLat({ lng: this.longitude, lat: this.latitude });
      this.marker.addTo(this.map);
      this.marker.on('dragend', () => {
        const lngLat: any = this.marker.getLngLat();
        this.latitude = lngLat.lat;
        this.longitude = lngLat.lng;
      });

      this.map.addControl(geocoder);
    }, 500)
  }

  onChangeState() {
    let selectedStateId = this.merchantSignUpForm.value.state;
    this.districts = [];
    if (selectedStateId !== "") {
      this.http.post(`${environment.apiUrl}/districts/list`, { status: 1, stateId: selectedStateId, count: 100, sortColumn: 'name', sort: 'ASC' }).subscribe((districtData: any) => {
        if (districtData.status === "SUCCESS") {
          this.districts = districtData.districts;
        }
      })
    }
  }


  validatePhoneNumber(control: any) {
    console.log(control)
    // const phoneNumber = control.value;
    // const phoneNumberPattern = /^([6-9]\d{9})$/; // Regular expression for 10-digit number

    // if (phoneNumber && !phoneNumber.match(phoneNumberPattern)) {
    //   return { invalidPhoneNumber: true };
    // }

    return null;
  }

  validateAgreement(control: any) {
    const value = control.value;
    if (!value) {
      return { invalid: true };
    }

    return null;
  }

  openMerchantAgreement() {
    const isMobile = window.innerWidth < 768;
    let sizes = {
      height: '500px',
      width: '50%',
      maxWidth: 'unset',
    };
    if (isMobile) {
      sizes = {
        width: '100%',
        maxWidth: 'unset',
        height: '100%',
      }
    }
    this.dialog.open(MerchantAgreementComponent, {
      ...sizes,
      disableClose: true,
      data: {
        vendorName: this.merchantSignUpForm.value.name
      },
    });
  }

  ifMobileApp() {
    if (this.userAgent.includes('AdvenzoneMobileApp')) {
      return true;
    } else {
      return false;
    }
  }
}

