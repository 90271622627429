import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';


interface details {
  id: any;
  name: any;
  src: any;
  hasCoupon: any;
  min_price:any;
  locationid:any;
  availability:any;
  locationname:any;
  parentvariationtypeid: any;
  parentvariationvalue: any;
  parentvariationvalueid: any;
  variationtypeid: any;
  variationtype: any;
  value: any;
}

@Component({
  selector: 'app-bike-rental-card',
  templateUrl: './bike-rental-card.component.html',
  styleUrls: ['./bike-rental-card.component.css']
})
export class BikeRentalCardComponent {
  @Input() data: details;
  @Output() dataLocation = new EventEmitter<any>();

  constructor(private router: Router) { }

  openBikeRental(){
    // sessionStorage.setItem('userData', JSON.stringify({category:"bike-rental"}));
    // let name = this.data.locationname.trimEnd().toLowerCase().replaceAll(" ", "-");
    // this.router.navigate(['/home', 'location', this.data.locationid, name]);
    this.router.navigate(['/rental-provided-by'], {
      state: { dataToPass: this.data}
    });
    this.dataLocation.emit();
  }

}
