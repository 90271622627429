import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { DataService } from 'src/app/data.service';
import { EnquiryFormComponent } from 'src/app/enquiry-form/enquiry-form.component';


interface details {
  id: any;
  name: any;
  shopName: any;
  src: any;
  cover_src: any;
  distanceAway: any;
  rating: any;
  locationName:any;
  locationid:any;
  latitude:any;
  longitude:any;
  category:any;
  time_from:any;
  time_to:any;
  campname:any;
  price:any;
  difficulty:any;
  duration:any;
  altitude:any;
}

@Component({
  selector: 'app-mindesc-provided-by-card',
  templateUrl: './mindesc-provided-by-card.component.html',
  styleUrls: ['./mindesc-provided-by-card.component.css']
})
export class MindescProvidedByCardComponent {
  @Input() data: details;

  constructor(public dataService: DataService, private router: Router,public dialog: MatDialog) {}

  openMerchantProfile(){
    let locationname = this.data.locationName.trim().replace(/[^a-zA-Z0-9\s]/g, '-').replace(/\s+/g, '-').toLowerCase();
    let name = this.data.name.trim().replace(/[^a-zA-Z0-9\s]/g, '-').replace(/\s+/g, '-').toLowerCase();
    this.router.navigate(['/merchant-profile','location',this.data.locationid,'merchantid',this.data.id,locationname,name])
  }

  openMap(event: any) {
    event.stopPropagation()
    this.dataService.openMap(this.data.latitude, this.data.longitude);
  }

  
  openEnquiryDialog(){
    const isMobile = window.innerWidth < 768;
    let sizes = {
      height: '87%',
      width: '33%',
      maxWidth: 'unset',
    };
    if (isMobile) {
      sizes = {
        width: '100%',
        maxWidth: 'unset',
        height: '100%',
      };
    }

    this.dialog.open(EnquiryFormComponent, {
      ...sizes,
      disableClose: true,
      data: {
        datafrom: this.data.campname,
        activityType: "0",
      },
    });
}
}
