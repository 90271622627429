<div class="pb-2 trekking-card shadow bg-white">
    <div class="position-relative">
        <ngb-carousel class="trekking-img-carousel-height" *ngIf="data.src && data.src.length > 0">
            <ng-template *ngFor="let data of data.src" ngbSlide (click)="$event.stopPropagation()">
                <div class="trekking-wrapper">
                    <img [src]="data" alt="image" class="image-data-src">
                </div>
            </ng-template>
        </ngb-carousel>
        <img class="position-absolute info pointer" src="/assets/cards/info.svg" alt="info"  (click)="openInfoDialog($event)" />
        <div class="trekking-has-coupon pe-5" *ngIf="data.hasCoupon">
            <h6 class="mb-0 ps-3">Offer Available</h6>
        </div>
        <div class="trekking-card-rating rounded-pill position-absolute px-2">
            <ng-container *ngFor="let star of [1, 2, 3, 4, 5]">
                <span [ngClass]="{'filled': star <= data.rating, 'empty': star > data.rating}" class="star">&#9733;</span>
            </ng-container>
        </div>
    </div>
    <div class="px-2">
        <h3 class="trekking-title mt-2 text-truncate">{{data.name}}</h3>
        <div class="d-flex trekking-content">
            <p class="me-2 trekking-card-activityname"><img src="/assets/cards/hardness.svg" alt="hardness icon"> {{data.difficulty}}</p>
            <p class="me-2 trekking-card-activityname"><img src="/assets/cards/mountain.svg" alt="mountain icon"> {{data.totalaltitude}}</p>
            <p class="me-2 trekking-card-activityname"><img src="/assets/cards/duration.svg" alt="duration icon"> {{data.numberofdays}} days</p>
        </div>
        <hr>
        <div class="d-flex justify-content-between align-items-end mb-2" style="min-height: 57px;">
            <div *ngIf=" data.min_price != '0' &&  data.min_price != null && data.min_price != undefined && data.slot_available">
                <h6 class="trekking-starts-from mb-1">Starting from</h6>
                <h4 class="trekking-price mb-1">₹ {{data.min_price}} <span class="trekking-per-person">/person</span></h4>
            </div>
            <div *ngIf=" data.min_price == '0' || data.min_price == null || !data.slot_available">
                <button type="button" class="btn rounded-pill px-4 trekking-enquiry" (click)="openEnquiryDialog()">Enquiry</button>
            </div>
            <div>
                <button type="button" class="btn trekking-view-details rounded-pill px-3" (click)="openActivityPage()">View details</button>
            </div>
        </div>
    </div>
</div>