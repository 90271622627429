<div class="m-3 p-3 h-100" >
    <div class="d-flex w-100 h-100">
        <div class="w-25" style="border-right: 1px solid #CCCCCC">
            <h2 class="web-reviews-show-more-title">{{activityName}}</h2>
            <h4 class="web-reviews-show-more-review mb-0">Total Review</h4>
            <h2 class="web-reviews-show-more-value">{{totalReview}}</h2>
            <h4 class="web-reviews-show-more-review mb-0">Average Rating</h4>
            <div class="d-flex align-items-center">
                <h2 class="mb-0 web-reviews-show-more-value">{{avgReview}}</h2>
                <ng-container *ngFor="let star of [1, 2, 3, 4, 5]">
                    <span [ngClass]="{'filled': star <= avgReview, 'empty': star > avgReview}"
                        class="web-reviews-details-star">&#9733;</span>
                </ng-container>
            </div>
        </div>
        <div class="w-75 h-100 ps-4">
            <div class="d-flex justify-content-between align-items-center">
                <h2 class="mb-0 web-reviews-show-more-title">Reviews & Ratings</h2>
                <button class="btn" (click)="PopupProvidedByDialogRef.close()"><img src="/assets/icons/close.svg"
                        alt="close icon"></button>
            </div>
            <hr>
            <div class="web-reviews-show-more" #dialogContent>
                <ng-containter *ngFor="let data of  reviews">
                    <div class="web-show-more-reviews-details-review-content mb-5">
                        <div class="d-flex align-items-center">
                            <img [src]="data.src" alt="fire icon" *ngIf="data.src && data.src != ''"
                                class="web-show-more-reviews-details-review-content-user-img">
                            <img src="assets/default-profile-pic.svg" alt="fire icon" *ngIf="!data.src || data.src == ''"
                                class="web-show-more-reviews-details-review-content-user-img rounded-circle">
                            <h3 class="web-show-more-reviews-details-review-content-user-name mb-0 ms-2">{{ data.firstname +
                                (data.lastname != null ? ' ' + data.lastname : '') }}</h3>
                        </div>
                        <div class="d-flex mt-2">
                            <ng-container *ngFor="let star of [1, 2, 3, 4, 5]; let j= index">
                                <span [ngClass]="{'filled': star <= data.star, 'empty': star > data.star}"
                                    [class.ms-0]="j == 0" class="web-reviews-details-star">&#9733;</span>
                            </ng-container>
                            <p class="web-show-more-reviews-details-review-date ms-2">
                                {{convertTimestampToDateFormat(data.createdon)}}</p>
                        </div>
                        <p class="web-show-more-reviews-details-review-content-text">{{data.review}}</p>
                    </div>
                </ng-containter>
            </div>
        </div>
    </div>
</div>