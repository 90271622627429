import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FormatDateRangeService {

  constructor() { }

  // getNextSixMonths(): string[] {
  //   const today = new Date();
  //   const nextSixMonths: string[] = [];

  //   for (let i = 0; i < 6; i++) {
  //     const nextMonth = new Date(today.getFullYear(), today.getMonth() + i, 1);
  //     const monthAbbreviation = nextMonth.toLocaleString('default', { month: 'short' });
  //     nextSixMonths.push(monthAbbreviation);
  //   }

  //   return nextSixMonths;
  // }
  
  isPastDate(dateString: string): boolean {
    const date = new Date(dateString);
    const currentDate = new Date();
    return date < currentDate;
  }

  formatDateRange(dateRangeStr: string): string {
    const [startDateStr, endDateStr] = dateRangeStr.split(' - ');
  
    const startDate = new Date(startDateStr);
    const endDate = new Date(endDateStr);
  
    const startDay = this.formatDateWithOrdinalSuffix(startDate.getDate());
    const startMonth = this.formatDateToMonthName(startDate.getMonth());
    const startYear = startDate.getFullYear();
    const endDay = this.formatDateWithOrdinalSuffix(endDate.getDate());
    const endMonth = this.formatDateToMonthName(endDate.getMonth());
    const endYear = endDate.getFullYear();
  
    return `${startDay} ${startMonth} ${startYear} to ${endDay} ${endMonth} ${endYear}`;
  }

  getMonthFromText(dateRangeText:string) {
    // Regular expression to match the month name
    let regex = /[A-Za-z]{3}/; // Matches three consecutive letters (assuming month names are always three letters)
    let matches = dateRangeText.match(regex);
    if (matches && matches.length > 0) {
        return matches[0]; // The first match should be the month
    } else {
        return null; // If no match found, return null
    }
  }
  
  formatDateWithOrdinalSuffix(day: number): string {
    const suffixes = ['th', 'st', 'nd', 'rd'];
    const suffix = day % 10 < 4 && (day < 11 || day > 13) ? suffixes[day % 10] : suffixes[0];
    return `${day}${suffix}`;
  }
  
  formatDateToMonthName(month: number): string {
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    return months[month];
  }
}
