<div class="h-100 overflow-hidden">
    <div class="d-flex justify-content-between align-items-center pt-2">
        <h2 class="mb-0 mobile-reviews-show-more-title">Reviews & Ratings</h2>
        <button class="btn" (click)="PopupReviewsDialogue.dismiss()"><img src="/assets/icons/close.svg" alt="close icon"></button>
    </div>
    <hr class="mobile-review-show-more-hr">
    <div class="mobile-reviews-show-more-content" #dialogContent>
        <h2 class="mobile-reviews-show-more-activity-title">{{activityName}}</h2>
        <div class="d-flex mb-2">
            <div class="me-4">
              <h4 class="mobile-review mb-1">Total Review</h4>
              <h2 class="mb-0 mobile-review-value">{{totalReview}}</h2>
            </div>
            <div class="ps-4" style="border-left: 1px solid #CCCCCC">
              <h4 class="mobile-review mb-1">Average Rating</h4>
              <div class="d-flex align-items-center">
                <h2 class="mb-0 mobile-review-value">{{avgReview}}</h2>
                <ng-container *ngFor="let star of [1, 2, 3, 4, 5]">
                  <span [ngClass]="{'filled': star <= avgReview, 'empty': star > avgReview}" class="mobile-reviews-details-star">&#9733;</span>
              </ng-container>
              </div>
            </div>
          </div>
          <hr  class="mobile-review-show-more-hr">
          <ng-containter *ngFor="let data of  reviews">
            <div class="mobile-show-more-reviews-details-review-content">
                <div class="d-flex align-items-center">
                    <img [src]="data.src" alt="fire icon" *ngIf="data.src && data.src != ''"
                        class="mobile-show-more-reviews-details-review-content-user-img">
                    <img src="assets/default-profile-pic.svg" alt="fire icon" *ngIf="!data.src || data.src == ''"
                        class="mobile-show-more-reviews-details-review-content-user-img rounded-circle">
                    <h3 class="mobile-show-more-reviews-details-review-content-user-name mb-0 ms-2">{{ data.firstname +
                        (data.lastname != null ? ' ' + data.lastname : '') }}</h3>
                </div>
                <div class="d-flex mt-2">
                    <ng-container *ngFor="let star of [1, 2, 3, 4, 5]; let j= index">
                        <span [ngClass]="{'filled': star <= data.star, 'empty': star > data.star}"
                            [class.ms-0]="j == 0" class="mobile-reviews-details-star">&#9733;</span>
                    </ng-container>
                    <p class="mobile-show-more-reviews-details-review-date ms-2">
                        {{convertTimestampToDateFormat(data.createdon)}}</p>
                </div>
                <p class="mobile-show-more-reviews-details-review-content-text">{{data.review}}</p>
            </div>
        </ng-containter>
    </div>
</div>