<div class="web-activities-show-more m-3 p-3" #dialogContent>
    <div class="d-flex justify-content-between align-items-center">
        <h2 class="mb-0 web-activities-show-more-title" *ngIf="category === 'topadventurists'">Top Adventurists <span *ngIf="locationName !== '' && locationName !== null"> in {{locationName}}</span></h2>
        <h2 class="mb-0 web-activities-show-more-title" *ngIf="category === 'rafting' || category === 'activity'">Other  {{ (activityCategory == 'rafting') ? 'Rafting' : 'Quick Activities' }}  <span *ngIf="locationName !== '' && locationName !== null"> in {{locationName}}</span></h2>
        <h2 class="mb-0 web-activities-show-more-title" *ngIf="category === 'camping'">Other Camping <span *ngIf="locationName !== '' && locationName !== null"> in {{locationName}}</span></h2>
        <h2 class="mb-0 web-activities-show-more-title" *ngIf="category === 'trekking'">Other Trekking <span *ngIf="locationName !== '' && locationName !== null"> in {{locationName}}</span></h2>
        <h2 class="mb-0 web-activities-show-more-title" *ngIf="category === 'tour'">Other Tours <span *ngIf="locationName !== '' && locationName !== null"> in {{locationName}}</span></h2>
        <h2 class="mb-0 mobile-activities-show-more-title" *ngIf="category === 'bikerental'">Other Vehicles provided by <span *ngIf="locationName !== '' && locationName !== null"> by {{locationName}}</span></h2>
        <button class="btn" (click)="PopupProvidedByDialogRef.close()"><img src="/assets/icons/close.svg" alt="close icon"></button>
    </div>
    <hr>
    <div class="row mb-3" *ngIf="category === 'topadventurists'">
        <div class="col-4 mt-3" *ngFor="let data of  topAdventuristsData; let j= index">
            <app-new-merchant-profile-card [data]="data" (dataLocation)="PopupProvidedByDialogRef.close()"></app-new-merchant-profile-card> 
        </div>
    </div>
    <div class="row mb-3" *ngIf="category === 'rafting' || category === 'activity'">
        <div class="col-4 mt-3" *ngFor="let data of  activityData; let j= index">
            <app-rafting-card [data]="data" dataFrom="popup" (dataLocation)="PopupProvidedByDialogRef.close()" *ngIf="category == 'rafting'"></app-rafting-card>
            <app-quick-activities-card [data]="data" dataFrom="popup" (dataLocation)="PopupProvidedByDialogRef.close()" *ngIf="category == 'activity'"></app-quick-activities-card>
        </div>
    </div>
    <div class="row mb-3" *ngIf="category === 'camping'">
        <div class="col-4 mt-3" *ngFor="let data of  activityData; let j= index">
            <app-camping-card [data]="data" dataFrom="popup" (dataLocation)="PopupProvidedByDialogRef.close()"></app-camping-card>
        </div>
    </div>
    <div class="row mb-3" *ngIf="category === 'trekking'">
        <div class="col-4 mt-3" *ngFor="let data of  activityData; let j= index">
            <app-trekking-card [data]="data" dataFrom="popup" (dataLocation)="PopupProvidedByDialogRef.close()"></app-trekking-card>
        </div>
    </div>
    <div class="row mb-3" *ngIf="category === 'tour'">
        <div class="col-4 mt-3" *ngFor="let data of  activityData; let j= index">
            <app-tour-card [data]="data" dataFrom="popup"  (dataLocation)="PopupProvidedByDialogRef.close()"></app-tour-card>
        </div>
    </div>
    <div class="row mb-3" *ngIf="category === 'bikerental'">
        <div class="col-4 mt-3" *ngFor="let data of  activityData; let j= index">
            <app-bike-rental-card [data]="data"  dataFrom="popup" (dataLocation)="PopupProvidedByDialogRef.close()"></app-bike-rental-card>
        </div>
    </div>
</div>