<div class="pb-2 quick-activity-card shadow bg-white">
    <div class="position-relative">
        <ngb-carousel class="quick-activity-img-carousel-height" *ngIf="data.src && data.src.length > 0">
            <ng-template *ngFor="let data of data.src" ngbSlide (click)="$event.stopPropagation()">
                <div class="quick-activity-wrapper">
                    <img [src]="data" alt="image" class="image-data-src">
                </div>
            </ng-template>
        </ngb-carousel>
        <img class="position-absolute info pointer"  *ngIf="dataFrom != 'popup'" src="/assets/cards/info.svg" alt="info"  (click)="openInfoDialog($event)" />
        <div class="activity-has-coupon pe-3" *ngIf="data.hasCoupon">
            <h6 class="mb-0 ps-3">Offer Available</h6>
        </div>
        <div class="quick-activity-card-rating rounded-pill position-absolute px-2">
            <ng-container *ngFor="let star of [1, 2, 3, 4, 5]">
                <span [ngClass]="{'filled': star <= data.rating, 'empty': star > data.rating}" class="star">&#9733;</span>
            </ng-container>
        </div>
    </div>
    <div class="px-2">
        <h3 class="quick-activity-title mt-2 text-truncate">{{data.name}}</h3>
        <div class="d-flex quick-activity-content">
            <p class="ms-2"><img src="/assets/cards/hardness.svg" alt="hardness icon">{{data.difficulty}}</p>
        </div>
        <hr>
        <div class="d-flex justify-content-between align-items-end mb-2" style="min-height: 57px;">
            <div *ngIf="data.min_price != 0">
                <h6 class="quick-activity-starts-from mb-1">Starting from</h6>
                <h4 class="quick-activity-price mb-1">₹ {{data.min_price}} <span class="quick-activity-per-person">/person</span></h4>
            </div>
            <div *ngIf="data.min_price == 0">
                <button type="button" class="btn rounded-pill px-4 quick-activity-enquiry" (click)="openEnquiryDialog()">Enquiry</button>
            </div>
            <div>
                <button type="button" class="btn quick-activity-view-details rounded-pill px-4" (click)="openActivityPage()">View details</button>
            </div>
        </div>
    </div>
</div>