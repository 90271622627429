<div>
    <div class="d-flex justify-content-between">
        <h4 class="search-popup-title mb-0">Search</h4>
        <img src="/assets/icons/cancel.svg" alt="cancel" (click)="close()" class="pointer">
    </div>
    <hr style="opacity: 0.1;">
    <div>
        <div class="form-group  w-100 px-md-0 ps-3 form-banner mx-auto">
            <div class="input-group mt-2 mobile" >
              <input type="text" class="form-control input-left-group  opacity no-focus-outline"
                placeholder="search" name="location" [(ngModel)]="searchData"
                (keyup.enter)="onLocationChange('')">
              <div class="input-group-append pointer" (click)="onLocationChange('')">
                <span class="input-group-text p-1 input-right-group opacity">
                  <img src="/assets/icons/footer-search-icon.svg" width="30px" alt="search">
                </span>
              </div>
            </div>
          </div>

          <div>
            <h4 class="mt-2">Most Popular Place</h4>
            <div class="search-location-header">
                <h6 class="mx-2 search-location-title px-2 rounded pointer" (click)="onLocationChange(data.locationname)" *ngFor="let data of popularLocations">{{data.locationname}}</h6>
            </div>
          </div>
    </div>
</div>