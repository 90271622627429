import { AfterViewInit, Component, ElementRef, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { CouponsPopupComponent } from 'src/app/coupons-popup/coupons-popup.component';

declare var Cropper: any;

@Component({
  selector: 'app-cropper',
  templateUrl: './cropper.component.html',
  styleUrls: ['./cropper.component.css']
})
export class CropperComponent implements AfterViewInit {
  @ViewChild('cropperContainer') cropperContainer!: ElementRef;
  base64Data: any;
  cropper: any;
  aspectRatio: any = 1;
  width: any = 500;
  height:any = 500;
  thumbnailWidth: any = 200;
  thumbnailHeight: any = 200;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public bookingDetailsDialogRef: MatDialogRef<CouponsPopupComponent>, public dialog: MatDialog) {
    this.base64Data = data.base64Data;
    this.aspectRatio = data.aspectRatio;
    this.width = data.width;
    this.height = data.height;
    this.thumbnailHeight = data.thumbnailHeight;
    this.thumbnailWidth = data.thumbnailWidth;
   }

   ngAfterViewInit(): void {
    this.cropper = new Cropper(this.cropperContainer.nativeElement, {
      aspectRatio: this.aspectRatio,
      minContainerWidth: 300,
      minContainerHeight: 300,
      minCropBoxWidth: 200,
      minCropBoxHeight: 200,
      guides: false,
      autoCropArea: 1,
      background: true,
      viewMode: 1,
    });
   }

   crop(){
    const croppedImageBase64 = this.cropper.getCroppedCanvas({width: this.width, height: this.height}).toDataURL();
    const thumbCanvas = this.cropper.getCroppedCanvas({width: this.thumbnailWidth, height: this.thumbnailHeight}).toDataURL();
    this.bookingDetailsDialogRef.close({croppedImageBase64, thumbCanvas});
   }

}
