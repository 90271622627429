import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RedirectionGuard implements CanActivate {
  
  constructor(private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      const queryParams = this.router.parseUrl(state.url).queryParams;
      let id = queryParams['id'];
      if (!id) {
        return true;
      }
      if (queryParams['type'] === 'booking') {
        this.router.navigate(['bookings', id]);
        return false; // Prevent the current route from loading
      }
      if (queryParams['type'] === 'bookings-merchant') {
        this.router.navigate(['bookings-merchant', id]);
        return false; // Prevent the current route from loading
      }
      
      this.router.navigate(['404-not-found']);
      return false; // Prevent the current route from loading
    return true;
  }
  
}
