<div mat-dialog-content class="overflow-auto">
<div>
    <button type="button" class="btn-close mt-3 ms-3"  (click)="bookingDetailsDialogRef.close()" aria-label="Close"></button>
    <h1 mat-dialog-title class="text-center" style="text-align: center;" class="full-screen-dialog">Crop
    </h1>
</div>
<div>
    <div class="mx-auto" style="width: 300px; height: 300px;">
        <img #cropperContainer [src]="base64Data" style="display: block; max-width: 100%;" alt="">
    </div>
    <div class="text-center mt-5">
        <button type="button" (click)="crop()" class="btn btn-primary">Crop</button>
    </div>
</div>

</div>