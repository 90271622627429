import { HttpClient } from '@angular/common/http';
import { Component, Inject } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-categories-popup',
  templateUrl: './categories-popup.component.html',
  styleUrls: ['./categories-popup.component.css']
})
export class CategoriesPopupComponent {

  locationId: any;
  indexData: any = {};
  locationName: any = "";

  constructor(private bottomSheetRef: MatBottomSheetRef<CategoriesPopupComponent>, @Inject(MAT_BOTTOM_SHEET_DATA) public data: any, private http: HttpClient, private router: Router) {

  }

  async ngOnInit() {
    let location = JSON.parse(localStorage.getItem('location'));
    if (location != null && location != "") {
      this.locationId = location.id;
      this.locationName = location.locationname;
    }
    let filter: any = { status: 1 };
    if (this.locationId != null) {
      filter.locationId = this.locationId;
    }
    try {
      let indexCount: any = await this.http.post(environment.apiUrl + `/activities/indexcount`, filter, { responseType: 'json' }).toPromise();
      if (indexCount.status === "SUCCESS") {
        this.indexData.rafting = indexCount.rafting;
        this.indexData.camping = indexCount.camping;
        this.indexData.trekking = indexCount.trekking;
        this.indexData.topadventurists = indexCount.topAdventurists;
        this.indexData.bikerental = indexCount.bikeRentals;
        this.indexData.tour = indexCount.tour;
        this.indexData.quickactivities = indexCount.quickactivities;
      }
    } catch (err) {
      console.log(err)
    }
  }

  navigateTo(category: any) {
    switch (category) {
      case 'rafting':
        sessionStorage.setItem('userData', JSON.stringify({ category: 'rafting' }))
        break;
      case 'trekking':
        sessionStorage.setItem('userData', JSON.stringify({ category: 'trekking' }))
        break;
      case 'camping':
        sessionStorage.setItem('userData', JSON.stringify({ category: 'camping' }))
        break;
      case 'bike-rental':
        sessionStorage.setItem('userData', JSON.stringify({ category: 'bike-rental' }))
        break;
      case 'tour':
        sessionStorage.setItem('userData', JSON.stringify({ category: 'tour' }))
        break;
      case 'activity':
        sessionStorage.setItem('userData', JSON.stringify({ category: 'activity' }))
        break;
      case 'topAdventurists':
        sessionStorage.setItem('userData', JSON.stringify({ category: 'topAdventurists' }))
        break;
    }
    this.bottomSheetRef.dismiss(); // This closes the bottom sheet
    let name = this.locationName.trimEnd().toLowerCase().replaceAll(" ", "-");
    this.router.navigate(['/home', 'location', this.locationId, name]);
  }

  close(): void {
    this.bottomSheetRef.dismiss(); // This closes the bottom sheet
  }
}
