import { Component, ElementRef, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { PopupProvidedbyComponent } from '../popup-providedby/popup-providedby.component';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { DomSanitizer } from '@angular/platform-browser';
import { DataService } from 'src/app/data.service';
import { AppService } from 'src/app/app.service';

@Component({
  selector: 'app-web-activities-show-more',
  templateUrl: './web-activities-show-more.component.html',
  styleUrls: ['./web-activities-show-more.component.css']
})
export class WebActivitiesShowMoreComponent {

  
  userAgent: string = "";
  topAdventuristsData: any =[];
  activityData:any =[];
  category: string = "";
  viewCount: number = 4;
  locationId: any;
  locationName: any;
  page: any = 2;
  topAdventuristsLoadMore: boolean = true;
  isTopAdventuristsLoading: boolean = false;
  loadMoreActivities:boolean = true;
  nonFiteredCampingData:any =[];
  userLocation = { latitude: 0, longitude: 0 };
  appserviceSubscribe:any;

  @ViewChild('dialogContent', { static: false }) dialogContent: ElementRef;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,public dialog: MatDialog, public PopupProvidedByDialogRef: MatDialogRef<PopupProvidedbyComponent>, private http: HttpClient, private sanitizer:DomSanitizer, private dataService: DataService, private appService: AppService) {
    this.userAgent = window.navigator.userAgent;
    this.category = data.category;
    this.locationName = data.locationName;
    this.locationId = data.locationId;
    this.appserviceSubscribe = this.appService.userLocation.subscribe(data => {
      this.userLocation = data;
    })
    if(data.category === "topadventurists"){
       this.topAdventuristsData = data.topAdventuristsData;
       this.loadMoreTopAdventurists();
    }else if(data.category === "activity"){
      this.activityData = data.activityData;
      this.showMoreActivities();
    }else if(data.category == "rafting"){
      this.activityData = data.activityData;
      this.showMoreActivities();
    }else if(data.category == "camping"){
      this.activityData = data.activityData;
      this.showMoreCampingActivities();
    }else if(data.category == "trekking"){
      this.activityData = data.activityData;
      this.showMoreTrekkingActivities();
    }else if(data.category == "tour"){
      this.activityData = data.activityData;
      this.showMoreTourActivities();
    }else if(data.category == "bikerental"){
      this.activityData = data.activityData;
    }

  }

  ngAfterViewInit() {
    if (this.dialogContent) {
      this.dialogContent.nativeElement.addEventListener('scroll', this.onScroll.bind(this));
    }
  }

  ngOnDestroy() {
    if (this.appserviceSubscribe) {
      this.appserviceSubscribe.unsubscribe();
    }
  }

  onScroll(event: any) {
    const scrollTop = event.target.scrollTop;
    const scrollHeight = event.target.scrollHeight;
    const clientHeight = event.target.clientHeight;

    // If the user has scrolled to the bottom
    if (scrollTop + clientHeight >= scrollHeight) {
      this.loadMoreData(); 
    }
  }

  // Example function to load more data
  loadMoreData() {
    if(this.category === "topadventurists" && this.topAdventuristsLoadMore && !this.isTopAdventuristsLoading){
      this.loadMoreTopAdventurists();
    }
    if((this.category == "activity" || this.category == "rafting") && this.loadMoreActivities){
      this.showMoreActivities();
    }
  }

  loadMoreTopAdventurists(){
    this.isTopAdventuristsLoading = true;
    let filter = { count: this.viewCount, locationId: this.locationId, status: 1, page: this.page };
    this.topAdventuristsLoadMore = false;
    this.http.post(environment.apiUrl + `/merchants/topmerchants-card`, { ...filter, sortColumn: "average_star" }, { responseType: 'json' }).subscribe(async (data: any) => {
      {
        if (data.status === "SUCCESS") {
          // const merchantIdsFromFirstArray = this.merchantData.map((item: any) => item.id);
          // data.merchants = data.merchants.filter((item: any) => !merchantIdsFromFirstArray.includes(item.id));
          data.merchants.forEach((merchant: any) => {
            let values: any = {};
            values["id"] = merchant.id;
            values["name"] = merchant.name;
            values["description"] = merchant.description;
            values["locationId"] = merchant.locationid;
            values["locationName"] = merchant.locationname;
            values["address"] = merchant.addressline1;
            values["activityCount"] = merchant.activitycount;
            values["src"] = [];
            values["reviewed_user_pic"] = [];
            try{
              this.http.post(environment.apiUrl + '/coupons/is-coupon-available',{status:1,locationId:this.locationId, merchantId:merchant.id,activityId:0 ,currentTimeStamp: new Date().getTime(), isMobileApp: this.ifMobileApp()}, { responseType: 'json' }).subscribe((data: any) => {
                if (data.status === "SUCCESS" ) {
                  merchant.hasCoupon = data.coupons;
                  values["hasCoupon"] = data.coupons;
                  }else{
                    merchant.hasCoupon = false;
                    values["hasCoupon"] = false;
                 }
                  })
            }catch(error){
              console.log(error);
              merchant.hasCoupon = false;
              values["hasCoupon"] = false;
            }
            merchant.src = [];
            merchant.shopsrc = [];
            try {
              this.http.get(environment.apiUrl + `/uploads/listbydatafor?datafor=merchantprofile&dataforid=${merchant.id}`).subscribe((data: any) => {
                if (data.status === "SUCCESS") {
                  for (const upload of data.uploads) {
                    this.http.get(environment.apiUrl + `/uploads/getThumbnail?id=${upload.id}`).subscribe((res: any) => {
                      if (res.status === "SUCCESS") {
                        merchant.src.push(res.upload.thumbnail);
                        values["src"].push(res.upload.thumbnail);
                      }
                    });
                  }
                }
              })
            } catch (error) {
              console.log(error)
            }
            let data = { dataFor: 'merchant', merchantId: merchant.id, count: 4, page: 1, sortColumn: 'id' };
            this.http.post(environment.apiUrl + `/reviews/merchantlist`, data, { responseType: 'json' }).subscribe(async (data: any) => {
              if (data.status === "SUCCESS") {
                data.reviews.forEach(async (review: any) => {
                  try {
                    this.http.get(environment.apiUrl + `/uploads/listbydatafor?datafor=userprofile&dataforid=${review.createdby}`).subscribe((data: any) => {
                      if (data.status === "SUCCESS") {
                        if (data.uploads.length > 0) {
                          for (const upload of data.uploads) {
                            this.http.get(environment.apiUrl + `/uploads/getThumbnail?id=${upload.id}`).subscribe((res: any) => {
                              if (res.status === "SUCCESS") {
                                values["reviewed_user_pic"].push(res.upload.thumbnail);
                              }
                            });
                          }
                        } else {
                          values["reviewed_user_pic"].push(null);
                        }
                      }
                    })
                  } catch (error) {
                    console.log(error)
                  }
                })
              }
            });
            merchant.averagestar = parseInt(merchant.average_star);
            values["averagestar"] = parseInt(merchant.average_star);
            this.topAdventuristsData.push(values);
          });
          this.isTopAdventuristsLoading = false;
          if (data.merchants && data.merchants.length > 0) {
            // this.topAdventuristsData.push(...data.merchants);
            if (Number(data.count) >  this.viewCount) {
              this.topAdventuristsLoadMore = true;
              this.page += 1;
            } else {
              this.topAdventuristsLoadMore = false;
            }
          } else {
            this.topAdventuristsLoadMore = false;
          }
        }
      }
    })

  }

  showMoreActivities() {
    let filter = { count: this.viewCount, locationId: this.locationId, status: 1, page: this.page };
    this.loadMoreActivities = false;
    this.http.post(environment.apiUrl + `/activities/card-list`, { ...filter, category: this.category }, { responseType: 'json' }).subscribe(async (data: any) => {
      if (data.status === "SUCCESS") {
        data.activities.forEach((activity: any) => {
          let values: any = {};
          values['id'] = activity.id;
          values['name'] = activity.addressline1;
          values['category'] = activity.category;
          values['src'] = [];
          values['description'] = '';
          values['locationid'] = activity.locationid;
          values['locationname'] = activity.locationname;
          values['raftingkm'] = '';
          values['difficulty'] = '';
          values['min_price'] = activity.min_price || 0;
          values['rating'] = activity.avg_rating || 0;
          activity.src = [];
          const description = typeof activity.description === 'string' ? activity.description.trim() : '';
          const strippedDescription = description.replace(new RegExp('(<p><br></p>|<br>)$'), '').trim();
          if (strippedDescription) {
            values['description'] = this.sanitizer.bypassSecurityTrustHtml(activity.description);
          } else {
            values['description'] = '';
          }
          try{
            this.http.post(environment.apiUrl + '/coupons/is-coupon-available',{status:1,locationId:this.locationId, merchantId:0,activityId:activity.id ,currentTimeStamp: new Date().getTime(), isMobileApp: this.ifMobileApp()}, { responseType: 'json' }).subscribe((data: any) => {
              if (data.status === "SUCCESS" ) {
                values["hasCoupon"] = data.coupons;
                activity.hasCoupon = data.coupons;
                }else{
                  values["hasCoupon"] = false;
                  activity.hasCoupon = false;
                }
                })
          }catch(error){
            console.log(error);
            values["hasCoupon"] = false;
            activity.hasCoupon = false;
          }
          if (activity.additionalinfo != null && activity.additionalinfo != "") {
            let parsedfrom = JSON.parse(activity.additionalinfo);
            activity.raftingkm = parsedfrom.raftingkm;
            activity.difficulty = parsedfrom.difficulty;
            values['raftingkm'] = parsedfrom.raftingkm;
            values['difficulty'] = parsedfrom.difficulty;
          }
          
          try {
            this.http.get(environment.apiUrl + `/uploads/listbydatafor?datafor=activity&dataforid=${activity.id}`).subscribe((data: any) => {
              if (data.status === "SUCCESS") {
                for (const upload of data.uploads) {
                  this.http.get(environment.apiUrl + `/uploads/getThumbnail?id=${upload.id}`).subscribe((res: any) => {
                    if (res.status === "SUCCESS") {
                      activity.src.push(res.upload.thumbnail)
                      values.src.push(res.upload.thumbnail);
                    }
                  });
                }
              }
            })
          } catch (error) {
            console.log(error)
          }
          this.activityData.push(values);
        });
        // const filteredActivities = data.activities.filter((activity:any)=>{
        //   return this.activityId != activity.id;  
        // })
        if (data.activities && data.activities.length > 0) {
          if (Number(data.count) > this.activityData.length +1) {
            this.loadMoreActivities = true;
            this.page += 1;
          } else {
            this.loadMoreActivities = false;
          }
        } else {
          this.loadMoreActivities = false;
        }
      }
    });
  }

  showMoreCampingActivities() {
    let filter: any = { count: this.viewCount, locationId: this.locationId, page: this.page, status: 1 };
    this.http.post(environment.apiUrl + `/merchantactivities/list`, { ...filter, category: 'camping' }, { responseType: 'json' }).subscribe(async (data: any) => {
      if (data.status === "SUCCESS") {
        this.nonFiteredCampingData.push(...data.merchantactivities);
        data.merchantactivities = this.removeDuplicates(data.merchantactivities, "activityid", "merchantid", this.activityData);
        data.merchantactivities.forEach((activity: any) => {
          let value: any = {};
          value["id"] = activity.id;
          value["campingName"] = "";
          value["description"] = activity.description;
          value["activityname"] = activity.activitiesaddressline1;
          value["merchantname"] = activity.merchantname;
          value["merchantid"] = activity.merchantid;
          value["locationid"] = activity.activitieslocationid;
          value["locationname"] = activity.activitieslocationname;
          activity.campingName = "";
          try {
            this.http.post(environment.apiUrl + '/coupons/is-coupon-available', { status: 1, locationId: this.locationId, merchantId: activity.merchantid, activityId: activity.activityid, currentTimeStamp: new Date().getTime(), isMobileApp: this.ifMobileApp() }, { responseType: 'json' }).subscribe((data: any) => {
              if (data.status === "SUCCESS") {
                activity.hasCoupon = data.coupons;
                value["hasCoupon"] = data.coupons;
              } else {
                activity.hasCoupon = false;
                value["hasCoupon"] = false;
              }
            })
          } catch (error) {
            console.log(error);
            activity.hasCoupon = false;
            value["hasCoupon"] = false;
          }
          if (activity.additionalinfo && activity.additionalinfo != null && activity.additionalinfo != "") {
            if (JSON.parse(activity.additionalinfo).campingname) {
              activity.campingName = JSON.parse(activity.additionalinfo).campingname;
              value["campingName"] = JSON.parse(activity.additionalinfo).campingname;
            }
            if (JSON.parse(activity.additionalinfo).latitude != undefined && JSON.parse(activity.additionalinfo).longitude != undefined && JSON.parse(activity.additionalinfo).latitude != null && JSON.parse(activity.additionalinfo).longitude != null && JSON.parse(activity.additionalinfo).latitude != 0 && JSON.parse(activity.additionalinfo).longitude) {
              activity.campingLatitude = JSON.parse(activity.additionalinfo).latitude;
              activity.campingLongitude = JSON.parse(activity.additionalinfo).longitude;
              value["campingLatitude"] = JSON.parse(activity.additionalinfo).latitude;
              value["campingLongitude"] = JSON.parse(activity.additionalinfo).longitude;
              this.dataService.getActivitiesDistance(this.userLocation.latitude, this.userLocation.longitude, JSON.parse(activity.additionalinfo).latitude, JSON.parse(activity.additionalinfo).longitude).subscribe({
                next: (res: any) => {
                  let km = Math.round(res.distance_in_km)
                  activity.distanceAway = `${km}${(Number(km) > 1) ? " Kms away" : "Km away"}`;
                  value["distanceAway"] = `${km}${(Number(km) > 1) ? " Kms away" : "Km away"}`;
                }, error: (error) => {
                  console.log(error)
                }
              }
              );
            } else {
              activity.campingLatitude = 0;
              activity.campingLongitude = 0;
              activity.distanceAway = "0Km away";
              value["distanceAway"] = "0Km away";
              value["campingLatitude"] = 0;
              value["campingLongitude"] = 0;
            }
          }

          activity.src = [];
          value["src"] = [];
          if (activity.mapimages && activity.mapimages != null && activity.mapimages != "") {
            let Images = JSON.parse(activity.mapimages)
            Images.forEach((x: any) => {
              this.http.get(environment.apiUrl + `/uploads/getThumbnail?id=${x}`).subscribe((resValue: any) => {
                if (resValue.status === "SUCCESS") {
                  activity.src.push({ id: resValue.upload.id, img: resValue.upload.thumbnail });
                  value.src.push({ id: resValue.upload.id, img: resValue.upload.thumbnail });
                }
              })
            })
          }
          try {
            this.http.post(environment.apiUrl + `/reviews/reviewstar`, { dataFor: 'activity', dataForId: activity.activityid }, { responseType: 'json' }).subscribe((data: any) => {
              if (data.status === "SUCCESS") {
                activity.avgReview = Math.round(data.reviews.average);
                value["rating"] = Math.round(data.reviews.average);
                activity.total = data.reviews.total;
              }
            });
          } catch (error) {
            console.log(error)
          }
          try {
            this.http.get(environment.apiUrl + `/range/minpricebymerchantactivityid?merchantActivityId=${activity.id}`).subscribe((data: any) => {
              if (data.status === "SUCCESS") {
                activity.min_price = data.min_price;
                value["min_price"] = data.min_price;
              }
            });
          } catch (error) {
            console.log(error)
          }
          this.activityData.push(value);
        });
        if (this.activityData && (this.activityData.length < this.viewCount) && (Number(data.count) > this.nonFiteredCampingData.length)) {
          this.showMoreCampingActivities();
        }
      }
    });
  }

  showMoreTrekkingActivities(){
    let filter = { count: this.viewCount, locationId: this.locationId, status: 1, page: this.page };
    this.loadMoreActivities = false;
    this.http.post(environment.apiUrl + `/activities/card-list`, { ...filter, category: 'trekking' }, { responseType: 'json' }).subscribe(async (data: any) => {
      if (data.status === "SUCCESS") {
        data.activities.forEach((activity: any, index: number) => {
          activity.src = [];
          let values: any = {};
          values['id'] = activity.id;
          values['name'] = activity.addressline1;
          values['category'] = activity.category;
          values['locationid'] = activity.locationid;
          values['src'] = [];
          values['description'] = '';
          values['locationname'] = activity.locationname;
          values['rating'] = activity.avg_rating;
          values['min_price'] = activity.min_price;
          const description = typeof activity.description === 'string' ? activity.description.trim() : '';
          const strippedDescription = description.replace(new RegExp('(<p><br></p>|<br>)$'), '').trim();
          if (strippedDescription) {
            values['description'] = this.sanitizer.bypassSecurityTrustHtml(activity.description);
          } else {
            values['description'] = '';
          }
          if (activity.additionalinfo && activity.additionalinfo != null && activity.additionalinfo != "") {
            let parsedfrom = JSON.parse(activity.additionalinfo);
            activity.difficulty = parsedfrom.difficulty;
            activity.totalaltitude = parsedfrom.totalaltitude;
            activity.numberofdays = parsedfrom.numberofdays;
            values['difficulty'] = parsedfrom.difficulty;
            values['totalaltitude'] = parsedfrom.totalaltitude;
            values['numberofdays'] = parsedfrom.numberofdays;
          }
          activity.slot_available = "";
          values["slot_available"] = "";
          try{
            this.http.post(environment.apiUrl + '/coupons/is-coupon-available',{status:1,locationId:this.locationId, merchantId:0,activityId:activity.id ,currentTimeStamp: new Date().getTime(), isMobileApp: this.ifMobileApp()}, { responseType: 'json' }).subscribe((data: any) => {
              if (data.status === "SUCCESS" ) {
                activity.hasCoupon = data.coupons;
                  values["hasCoupon"] = data.coupons;
                }else{
                  activity.hasCoupon = false;
                  values["hasCoupon"] = data.coupons;
                }
                })
          }catch(error){
            console.log(error);
            activity.hasCoupon = false;
            values["hasCoupon"] = data.coupons;
          }
          
          try {
            this.http.get(environment.apiUrl + `/merchantactivities/packagetourslots?category=trekking&activityId=${activity.id}`).subscribe((data: any) => {
              if (data.status === "SUCCESS") {
                if(data.slots.length > 0){
                  activity.slot_available = true;
                    values["slot_available"] = true;
                  }else{
                  activity.slot_available = false;
                  values["slot_available"] = false;
                }
              }
            });
          } catch (error) {
            console.log(error)
          }
         
          try {
            this.http.get(environment.apiUrl + `/uploads/listbydatafor?datafor=activity&dataforid=${activity.id}`).subscribe((data: any) => {
              if (data.status === "SUCCESS") {
                for (const upload of data.uploads) {
                  this.http.get(environment.apiUrl + `/uploads/getThumbnail?id=${upload.id}`).subscribe((res: any) => {
                    if (res.status === "SUCCESS") {
                      activity.src.push(res.upload.thumbnail);
                      values['src'].push(res.upload.thumbnail);
                    }
                  });
                }
              }
            })
          } catch (error) {
            console.log(error)
          }
          this.activityData.push(values);
        });
       
        if (data.activities && data.activities.length > 0) {
          if (Number(data.count) > this.activityData.length +1) {
            this.loadMoreActivities = true;
            this.page += 1;
          } else {
            this.loadMoreActivities = false;
          }
        } else {
          this.loadMoreActivities = false;
        }
      }
    });
  }

  showMoreTourActivities(){
    let filter = { count: this.viewCount, locationId: this.locationId, status: 1, page: this.page };
    this.loadMoreActivities = false;
    this.http.post(environment.apiUrl + `/activities/card-list`, { ...filter, category: 'tour' }, { responseType: 'json' }).subscribe(async (data: any) => {
      if (data.status === "SUCCESS") {
        data.activities.forEach((activity: any, index: number) => {
          activity.src = [];
          let values: any = {};
          values['id'] = activity.id;
          values['name'] = activity.addressline1;
          values['category'] = activity.category;
          values['locationid'] = activity.locationid;
          values['src'] = [];
          values['description'] = '';
          values['locationname'] = activity.locationname;
          values['rating'] = activity.avg_rating;
          values['min_price'] = activity.min_price;
          const description = typeof activity.description === 'string' ? activity.description.trim() : '';
          const strippedDescription = description.replace(new RegExp('(<p><br></p>|<br>)$'), '').trim();
          if (strippedDescription) {
            values['description'] = this.sanitizer.bypassSecurityTrustHtml(activity.description);
          } else {
            values['description'] = '';
          }
          if (activity.additionalinfo && activity.additionalinfo != null && activity.additionalinfo != "") {
            let parsedfrom = JSON.parse(activity.additionalinfo);
            activity.difficulty = parsedfrom.difficulty;
            activity.totaldistance = parsedfrom.totaldistance;
            activity.numberofdays = parsedfrom.numberofdays;
            values['difficulty'] = parsedfrom.difficulty;
            values['totaldistance'] = parsedfrom.totaldistance;
            values['numberofdays'] = parsedfrom.numberofdays;
          }
          activity.slot_available = "";
          values["slot_available"] = "";
          try{
            this.http.post(environment.apiUrl + '/coupons/is-coupon-available',{status:1,locationId:this.locationId, merchantId:0,activityId:activity.id ,currentTimeStamp: new Date().getTime(), isMobileApp: this.ifMobileApp()}, { responseType: 'json' }).subscribe((data: any) => {
              if (data.status === "SUCCESS" ) {
                activity.hasCoupon = data.coupons;
                  values["hasCoupon"] = data.coupons;
                }else{
                  activity.hasCoupon = false;
                  values["hasCoupon"] = data.coupons;
                }
                })
          }catch(error){
            console.log(error);
            activity.hasCoupon = false;
            values["hasCoupon"] = data.coupons;
          }
          
          try {
            this.http.get(environment.apiUrl + `/merchantactivities/packagetourslots?category=trekking&activityId=${activity.id}`).subscribe((data: any) => {
              if (data.status === "SUCCESS") {
                if(data.slots.length > 0){
                  activity.slot_available = true;
                    values["slot_available"] = true;
                  }else{
                  activity.slot_available = false;
                  values["slot_available"] = false;
                }
              }
            });
          } catch (error) {
            console.log(error)
          }
         
          try {
            this.http.get(environment.apiUrl + `/uploads/listbydatafor?datafor=activity&dataforid=${activity.id}`).subscribe((data: any) => {
              if (data.status === "SUCCESS") {
                for (const upload of data.uploads) {
                  this.http.get(environment.apiUrl + `/uploads/getThumbnail?id=${upload.id}`).subscribe((res: any) => {
                    if (res.status === "SUCCESS") {
                      activity.src.push(res.upload.thumbnail);
                      values['src'].push(res.upload.thumbnail);
                    }
                  });
                }
              }
            })
          } catch (error) {
            console.log(error)
          }
          this.activityData.push(values);
        });
       
        if (data.activities && data.activities.length > 0) {
          if (Number(data.count) > this.activityData.length +1) {
            this.loadMoreActivities = true;
            this.page += 1;
          } else {
            this.loadMoreActivities = false;
          }
        } else {
          this.loadMoreActivities = false;
        }
      }
    });
  }
  


  ifMobileApp() {
    if (this.userAgent.includes('AdvenzoneMobileApp')) {
      return true;
    } else {
      return false;
    }
  }

  removeDuplicates(arr: any[], prop1: string, prop2: string, prevArr: any[]) {
    arr = arr.filter((obj, index, self) =>
      index === self.findIndex((o) =>
        o[prop1] === obj[prop1] && o[prop2] === obj[prop2]
      )
    );

    arr = arr.filter((obj, index, self) =>
      prevArr.findIndex((o) =>
        o[prop1] === obj[prop1] && o[prop2] === obj[prop2]
      ) == -1
    );
    return arr;
  }

}
