<div class="px-2" *ngIf="(indexData.rafting && indexData.rafting > 0) || (indexData.trekking && indexData.trekking > 0) || (indexData.camping && indexData.camping > 0) || (indexData.bikerental && indexData.bikerental > 0) || (indexData.tour && indexData.tour > 0) || (indexData.quickactivities && indexData.quickactivities > 0) || (indexData.topadventurists && indexData.topadventurists > 0)">
    <div class="d-flex justify-content-between">
        <h4 class="categories-popup-title mb-0">Categories</h4>
        <img src="/assets/icons/cancel.svg" alt="cancel" (click)="close()" class="pointer">
    </div>
    <hr style="opacity: 0.1;">
    <div>
        <div class="d-flex justify-content-between pointer category-popup-sub-title" *ngIf="indexData.rafting && indexData.rafting > 0"
            (click)="navigateTo('rafting')">
            <h6 class="category-index-category mb-0"><img src="/assets/icons/rafting.svg" class="me-2" alt="rafting icon">
                Rafting</h6>
            <h6 class="category-index-category-count mb-0">{{indexData.rafting ? indexData.rafting : 0}}</h6>
        </div>
        <hr style="opacity: 0.1;" *ngIf="indexData.rafting && indexData.rafting > 0">
        <div class="d-flex justify-content-between pointer category-popup-sub-title" *ngIf="indexData.bikerental && indexData.bikerental > 0"
            (click)="navigateTo('bike-rental')">
            <h6 class="category-index-category mb-0"><img src="/assets/icons/bike-rental.svg" class="me-2"
                    alt="rental icon"> Bike Rentals</h6>
            <h6 class="category-index-category-count mb-0">{{indexData.bikerental ? indexData.bikerental : 0}}</h6>
        </div>
        <hr style="opacity: 0.1;" *ngIf="indexData.bikerental && indexData.bikerental > 0">
        <div class="d-flex justify-content-between pointer category-popup-sub-title"  *ngIf="indexData.camping && indexData.camping > 0"
            (click)="navigateTo('camping')">
            <h6 class="category-index-category mb-0"><img src="/assets/icons/camping.svg" class="me-2" alt="camping icon">
                Camping</h6>
            <h6 class="category-index-category-count mb-0">{{indexData.camping ? indexData.camping : 0}}</h6>
        </div>
        <hr style="opacity: 0.1;"  *ngIf="indexData.camping && indexData.camping > 0">
        <div class="d-flex justify-content-between  pointer category-popup-sub-title" *ngIf="indexData.trekking && indexData.trekking > 0"
            (click)="navigateTo('trekking')">
            <h6 class="category-index-category mb-0"><img src="/assets/icons/trekking.svg" class="me-2"
                    alt="trekking icon"> Trekking</h6>
            <h6 class="category-index-category-count mb-0">{{indexData.trekking ? indexData.trekking : 0}}</h6>
        </div>
        <hr style="opacity: 0.1;" *ngIf="indexData.trekking && indexData.trekking > 0">
        <div class="d-flex justify-content-between pointer category-popup-sub-title"  *ngIf="indexData.tour && indexData.tour > 0"
            (click)="navigateTo('tour')">
            <h6 class="category-index-category mb-0"><img src="/assets/icons/bike-tour.svg" class="me-2"
                    alt="bike tour icon"> Tours</h6>
            <h6 class="category-index-category-count mb-0">{{indexData.tour ? indexData.tour : 0}}</h6>
        </div>
        <hr style="opacity: 0.1;"  *ngIf="indexData.tour && indexData.tour > 0">
        <div class="d-flex justify-content-between pointer category-popup-sub-title" *ngIf="indexData.quickactivities && indexData.quickactivities > 0"
            (click)="navigateTo('activity')">
            <h6 class="category-index-category mb-0"><img src="/assets/icons/quick-activities.svg" class="me-2"
                    alt="quick activities icon"> Quick Activities</h6>
            <h6 class="category-index-category-count mb-0">{{indexData.quickactivities ? indexData.quickactivities : 0 }}</h6>
        </div>
        <hr style="opacity: 0.1;" *ngIf="indexData.quickactivities && indexData.quickactivities > 0">
        <div class="d-flex justify-content-between pointer category-popup-sub-title" *ngIf="indexData.topadventurists && indexData.topadventurists > 0"
            (click)="navigateTo('topAdventurists')">
            <h6 class="category-index-category mb-0"><img src="/assets/icons/top-adventurists.svg" class="me-2"
                    alt="adventurists icon"> Top Adventurists</h6>
            <h6 class="category-index-category-count mb-0">{{indexData.topadventurists ? indexData.topadventurists : 0 }}</h6>
        </div>
    </div>
</div>