import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DataService } from 'src/app/data.service';
import { Router } from '@angular/router';

interface details {
  id: any;
  name: any;
  shopName: any;
  src: any;
  cover_src: any;
  distanceAway: any;
  rating: any;
  min_price:any;
  time_from:any;
  time_to:any;
  latitude:any;
  longitude:any;
  locationName:any;
  locationid:any;
}

@Component({
  selector: 'app-des-provided-by-card',
  templateUrl: './des-provided-by-card.component.html',
  styleUrls: ['./des-provided-by-card.component.css']
})
export class DesProvidedByCardComponent {
  @Input() data: details;
  @Output() book_merchant = new EventEmitter<string>();

  constructor(public dataService: DataService, private router: Router) {}

  
  openMap(event: any) {
    event.stopPropagation()
    this.dataService.openMap(this.data.latitude, this.data.longitude);
  }

  openMerchantProfile(){
    let locationname = this.data.locationName.trim().replace(/[^a-zA-Z0-9\s]/g, '-').replace(/\s+/g, '-').toLowerCase();
    let name = this.data.name.trim().replace(/[^a-zA-Z0-9\s]/g, '-').replace(/\s+/g, '-').toLowerCase();
    this.router.navigate(['/merchant-profile','location',this.data.locationid,'merchantid',this.data.id,locationname,name])
  }


}
