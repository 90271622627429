<div class="desktop-header"
  *ngIf="!router.url.includes('generate-qr-code') && !router.url.includes('agentbooking') && !router.url.includes('partneractivities')  && !router.url.includes('partnerbooking') && (!bookingSource || UserType == 'partner') &&   auth.viewHeader">
  <nav class="navbar desktop-navbar navbar-expand-md navbar-light bg-header top-0 py-1 pt-3 pb-0"
    >
    <div class="container-fluid" style="background-color: #3EBDC6 !important; border-radius: 10px;">
      <div class="d-flex align-items-center pointer ms-5" (click)="navigateToHome()"
        [routerLink]="UserType === 'merchant' ? '/full-merchant-profile' : (UserType === 'partner' ? '/partnerdashboard' : '/')">
        <img src="/assets/new-logo.svg" width="40px" alt="logo" />
        <div class="ms-1">
          <a class="navbar-brand py-0" style="font-size: 20px; font-weight: 700; font-family: Istok Web;">Advenzone</a>
          <!-- <div class="d-flex align-items-center"
            *ngIf="(UserType != 'merchant' && UserType != 'partner' && !router.url.includes('bookings') && router.url != '/' && !router.url.includes('blogs'))"
            id="div">
            <select class="mb-0 search-location no-focus-outline form-select" [(ngModel)]="selectedLocation"
              (click)="stopClickPropagation($event)" (change)="changeSearchLocation()">
              <option [value]="location.id" [selected]="location.id == selectedLocation"
                *ngFor="let location of availableLocations">{{location.locationname}}</option>
            </select>
          </div> -->
        </div>
      </div>
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon">
        </span>
      </button>
      <div class="collapse navbar-collapse web" id="navbarSupportedContent">
        <div class="form-group  w-25 px-md-0 ps-3 form-banner ms-auto" *ngIf="UserType == null || UserType === 'user'">
          <div class="input-group head" (focusin)="highlightInputGroup('head')"
            (focusout)="removeHighlightInputGroup('head')">
            <input type="text" class="form-control input-left-group w-50 opacity no-focus-outline"
              [placeholder]="currentPlaceholder" [(ngModel)]="location" name="location"
              (keyup.enter)="onLocationChange()">
            <div class="input-group-append pointer" (click)="onLocationChange()">
              <span class="input-group-text p-1 input-right-group opacity">
                <img src="../../assets/search.svg" width="30px" alt="search icon">
              </span>
            </div>
          </div>
        </div>
        <!-- <div class="form-group  w-50 px-md-0 ps-3 form-banner mx-auto" *ngIf="UserType == null || UserType === 'user'">
          <div class="input-group head" (focusin)="highlightInputGroup('head')"
            (focusout)="removeHighlightInputGroup('head')">
   
              <input type="text" class="form-control input-left-group opacity no-focus-outline"
              [placeholder]="currentPlaceholder" [(ngModel)]="location" name="location"
              (keyup)="filterLocations(location)" >

       
              <div class="input-group-append">
                <span class="separator content">&#124;</span>
            </div>
            <div class=" pointer" (click)="onLocationChange()">

                <input type="text" class=" form-control input-right-group  opacity no-focus-outline position-relative" placeholder="Search Activities">
                <img src="../../assets/search.svg" width="30px" alt="Image" class="pointer search">
            </div>
          </div>
        </div> -->
        <ul class="navbar-nav ms-auto mb-lg-0  mt-2 mt-sm-0">
          <!-- <li class="nav-item ms-3" *ngIf="UserType == null || UserType === 'user'">
          <a class="nav-link link px-3"  data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation" aria-current="page" routerLink="/home" routerLinkActive="active-list-item"><img src="../assets/home.svg"  routerLinkActive="white-img" width="15" height="15"> Home</a>
        </li> -->
          <li class="nav-item custom-border-left" *ngIf="(UserType != 'merchant' && UserType != 'partner' && (!router.url.includes('bookings') || router.includes('profile-management/bookings')) && !router.url.includes('affiliate')  && !router.url.includes('blogs'))">
            <div class="nav-link px-3">
              <div class="d-flex align-items-center dropdown" id="location-list-dropdown">
                <img class="pointer" src="/assets/header/selected-location-icon.svg" width="15" height="15"
                  alt="icon for selected location" style="cursor: pointer;" (click)="navigateToHome()" [routerLink]="homeNavigation"  />
                <a data-bs-toggle="dropdown" class="link dropdown-toggle selected-location dropdown-toggle-no-caret mx-2" href="javascript:void(0)"
                  id="locationMenuDropdown" role="button" aria-expanded="false">{{getLocationName()}}</a>
                  <img data-bs-toggle="dropdown" class="pointer dropdown-toggle" src="/assets/header/down-arrow.svg" width="15" height="15" role="button" aria-expanded="false"
                  alt="down arrow" style="cursor: pointer;" />
                <div class="dropdown-menu location-list-dropdown p-4 shadow" aria-labelledby="locationMenuDropdown" style="max-width: 50vw; min-width: max-content; position: fixed !important; top: 72px; left: 25vw;">
                  <div style=" display: flex; flex-flow: column wrap; height: 75vh;">
                    <div style="width: calc(100% / var(--gallery-items-per-row)); min-width: 200px; display: block;" *ngFor="let group of groupLocationsKeys">
                      <h4 class="location-list-state mb-2 text-uppercase">{{group}}</h4>
                      <ul class="ps-0 ">
                        <li *ngFor="let location of groupLocations[group]" class="dropdown-item location-list-content pointer"
                          (click)="changeSearchLocation(location.id)" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent">
                          <img alt="icon for selected location" src="/assets/header/list-icon.svg" width="15" height="15"
                            class="me-2">{{location.locationname}}
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </li>
          <li class="nav-item ms-3 custom-border-left">
            <a class="nav-link link px-3" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation"
              aria-current="page" routerLink="/blogs" routerLinkActive="active-list-item"><img alt="icon for blogs"
                src="/assets/header/blog-icon.svg" routerLinkActive="white-img" width="15" height="15"> Blogs</a>
          </li>
          <li class="nav-item ms-3 custom-border-left" *ngIf="UserType === 'user'">
            <a class="nav-link link px-3" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation"
              aria-current="page" routerLink="/profile-management/bookings" routerLinkActive="active-list-item"><img
                alt="notification" src="../assets/notification.svg" routerLinkActive="white-img" width="15" height="15">
              Bookings</a>
          </li>
          <!-- <li class="nav-item ms-3" *ngIf="UserType == null || UserType === 'user'">
          <a class="nav-link link px-3" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation" aria-current="page" routerLink="/explore" routerLinkActive="active-list-item"><img src="../assets/explore.svg" routerLinkActive="white-img" width="15" height="15"> Explore</a>
        </li>
        <li class="nav-item ms-3" *ngIf="UserType == null || UserType === 'user'">
          <a class="nav-link link px-3" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation" aria-current="page" routerLink="/activity" routerLinkActive="active-list-item" ><img src="../assets/activity.svg" routerLinkActive="white-img" width="15" height="15"> Activities</a>
         </li> -->
          <li class="nav-item ms-3 custom-border-left" *ngIf="!name">
            <a class="nav-link link px-3" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation"
              aria-current="page" href="javascript:void(0)" routerLinkActive="active-list-item"
              (click)="openProfile()"><img src="../assets/profile.svg" class="mb-1" width="15" height="15"
                alt="user profile pic"> Profile</a>
          </li>
          <li class="nav-item ms-3 custom-border-left" *ngIf="UserType == 'partner'">
            <a class="nav-link link px-3" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation"
              aria-current="page" routerLink="/partnerdashboard" routerLinkActive="active-list-item"><img
                src="../assets/home.svg" routerLinkActive="white-img" class="mb-1" width="15" height="15" alt="home">
              Dashboard</a>
          </li>
          <li class="nav-item ms-3 custom-border-left" *ngIf="UserType == 'partner'">
            <a class="nav-link link px-3 pb-0" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation"
              aria-current="page" style="color: red; cursor: pointer;" (click)="signout()"><img class="me-2 img-logo"
                alt="logout" src="../../assets/logout.svg"> Sign out</a>
          </li>
          <li class="nav-item ms-3 mt-2 mt-sm-0 custom-border-left" *ngIf="UserType == 'merchant'">
            <a class="nav-link link px-3" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation"
              aria-current="page" routerLink="/merchant-bookings" routerLinkActive="active-list-item"><img alt="image"
                src="../assets/home.svg" routerLinkActive="white-img" class="mb-1" width="15" height="15"> Dashboard</a>
          </li>
          <li class="nav-item ms-3 custom-border-left" *ngIf="UserType == 'merchant'">
            <a class="nav-link link px-3" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation"
              aria-current="page" routerLink="/merchant-reviews" routerLinkActive="active-list-item"><img alt="star"
                src="../assets/header-star.svg" class="mb-1" routerLinkActive="white-img" width="15" height="15">
              Reviews</a>
          </li>
          <li class="nav-item ms-3 custom-border-left" *ngIf="UserType == 'merchant'">
            <a class="nav-link link px-3" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation"
              aria-current="page" routerLink="/merchantnotifications" routerLinkActive="active-list-item"><img
                alt="bellNotification" src="../assets/bell-notification.svg" *ngIf="dataservice.notification.length > 0"
                class="mb-1" routerLinkActive="white-img" width="15" height="15"><img
                *ngIf="dataservice.notification.length == 0" src="../assets/no-bell-notification.svg" class="mb-1"
                routerLinkActive="white-img" width="15" height="15" alt="noBellNotification"> Notifications</a>
          </li>
          <li class="nav-item ms-3 custom-border-left" *ngIf="UserType == 'merchant'">
            <a class="nav-link link px-3" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation"
              aria-current="page" routerLink="/full-merchant-profile" routerLinkActive="active-list-item"><img
                alt="user profile pic" src="../assets/profile.svg" class="mb-1" routerLinkActive="white-img" width="15"
                height="15"> Profile</a>
          </li>
          <li class="nav-item ms-3 custom-border-left" *ngIf="UserType == 'merchant'">
            <a class="nav-link link px-3 pb-0" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation"
              aria-current="page" style="color: red; cursor: pointer;" (click)="signout()"><img class="me-2 img-logo"
                alt="logout" src="../../assets/logout.svg"> Sign out</a>
          </li>

          <li class="nav-item dropdown ms-sm-3 ms-4 d-flex align-items-center  custom-border-left" *ngIf="name && UserType == 'user'">
            <a class="nav-link dropdown-toggle dropdown-toggle-no-caret py-0" href="#" id="navbarDropdown" role="button"
              data-bs-toggle="dropdown" aria-expanded="false">
              <img src="/assets/default-profile-pic.svg" class="header-profile-pic" *ngIf="!profileSrc"
                alt="{{name}}" />
              <img [src]="profileSrc" *ngIf="profileSrc" class="header-profile-pic" alt="{{name}}" />
            </a>
            <ul class="dropdown-menu  dropdown-menu-end">
              <li><a class="dropdown-item link" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
                  aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation"
                  aria-current="page" routerLink="/profile-management/profile-edit"><img class="me-2 img-logo"
                    alt="user profile pic" src="../../assets/profile.svg"> Account</a></li>
              <!-- <li><a class="dropdown-item link" routerLink="/ProfileManagement/UserProfileSaved"><img class="me-2 img-logo" src="../../assets/saved.svg">    Saved</a></li>
            <li><a class="dropdown-item link"  routerLink="/ProfileManagement/UserAccountNotification"><img class="me-2 img-logo" src="../../assets/notification.svg">  Notifications</a></li> -->
              <li><a class="dropdown-item link" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
                  aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation"
                  aria-current="page" style="color: red; cursor: pointer;" (click)="signout()"><img alt="logout"
                    class="me-2 img-logo" src="../../assets/logout.svg"> Sign out</a></li>
            </ul>
          </li>
          <li class="nav-item ms-3" *ngIf="ifMobileApp()">
            <a class="nav-link link px-3" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation"
              aria-current="page" routerLink="/terms-condition" routerLinkActive="active-list-item">Terms &
              Conditions</a>
          </li>
          <li class="nav-item ms-3" *ngIf="ifMobileApp()">
            <a class="nav-link link px-3" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation"
              aria-current="page" routerLink="/about-us" routerLinkActive="active-list-item">About Us</a>
          </li>
          <li class="nav-item ms-3" *ngIf="ifMobileApp()">
            <a class="nav-link link px-3" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation"
              aria-current="page" routerLink="/faq" routerLinkActive="active-list-item">FAQ</a>
          </li>
          <li class="nav-item ms-3" *ngIf="ifMobileApp()">
            <a class="nav-link link px-3" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation"
              aria-current="page" routerLink="/cancellation-policy" routerLinkActive="active-list-item">Cancellation
              Policy</a>
          </li>
          <li class="nav-item ms-3" *ngIf="ifMobileApp()">
            <a class="nav-link link px-3" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation"
              aria-current="page" routerLink="/privacy-policy" routerLinkActive="active-list-item">Privacy Policy</a>
          </li>
          <li class="nav-item ms-3" *ngIf="ifMobileApp()">
            <a class="nav-link link px-3" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation"
              aria-current="page" routerLink="/contact-us" routerLinkActive="active-list-item">Contact Us</a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</div>
<div class="mobile-header"
  *ngIf="!router.url.includes('generate-qr-code') && !router.url.includes('agentbooking') && !router.url.includes('partneractivities')  && !router.url.includes('partnerbooking') && (!bookingSource || UserType == 'partner') &&   auth.viewHeader">
  <nav class="navbar navbar-expand-lg navbar-light " style="background-color: #FFFF !important;">
    <div class="d-flex justify-content-between align-items-center px-2 w-100">
      <div class="d-flex align-items-center pointer" (click)="navigateToHome()"
        [routerLink]="UserType === 'merchant' ? '/full-merchant-profile' : (UserType === 'partner' ? '/partnerdashboard' : '/')">
        <img src="/assets/new-logo.svg" width="40px" alt="logo" />
        <div class="ms-1">
          <a class="navbar-brand py-0" style="font-size: 20px; font-weight: 700; font-family: Istok Web;">Advenzone</a>
          <!-- <div class="d-flex align-items-center"
            *ngIf="(UserType != 'merchant' && UserType != 'partner'  && !router.url.includes('bookings') && router.url != '/' && !router.url.includes('blogs'))"
            id="div">
            <select class="mb-0 search-location form-select no-focus-outline ps-0" [(ngModel)]="selectedLocation"
              (click)="stopClickPropagation($event)" (change)="changeSearchLocation()">
              <option [value]="location.id" [selected]="location.id == selectedLocation"
                *ngFor="let location of availableLocations">{{location.locationname}}</option>
            </select>
          </div> -->
        </div>
      </div>
      <div class="d-flex">
        <div class="d-flex align-items-center"  *ngIf="(UserType != 'merchant' && UserType != 'partner' && !router.url.includes('bookings')  && !router.url.includes('affiliate') && !router.url.includes('blogs'))">
          <img class="pointer" (click)="navigateToHome()" alt="icon for selected location" style="cursor: pointer;"
          [routerLink]="homeNavigation" src="/assets/header/selected-location-icon.svg" width="15" height="15" alt="down arrow" />
          <a class="link dropdown-toggle selected-location-mobile dropdown-toggle-no-caret mx-2" href="javascript:void(0)"
            id="locationMenuDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">{{getLocationName()}}</a>
          <div class="dropdown-menu location-list-dropdown-mobile p-4 shadow dropdown-menu-end" aria-labelledby="locationMenuDropdown">
            <div class="row">
              <div class="col-md-12" *ngFor="let group of groupLocationsKeys">
                <h4 class="location-list-state mb-2  text-uppercase">{{group}}</h4>
                <ul class="ps-0 ">
                  <li *ngFor="let location of groupLocations[group]" class="dropdown-item location-list-content pointer"
                    (click)="changeSearchLocation(location.id)" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent">
                    <img alt="icon for selected location" src="/assets/header/list-icon.svg" width="15" height="15"
                      class="me-2">{{location.locationname}}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="name && UserType == 'user'" class="position-relative">
          <!-- <img src="/assets/default-profile-pic.svg" class="header-profile-pic" *ngIf="!profileSrc" alt="{{name}}" />
          <img [src]="profileSrc" *ngIf="profileSrc" class="header-profile-pic" alt="{{name}}" /> -->
          <a class="nav-link dropdown-toggle dropdown-toggle-no-caret py-0" href="#" id="navbarDropdown" role="button"
            data-bs-toggle="dropdown" aria-expanded="false">
            <img src="/assets/default-profile-pic.svg" class="header-profile-pic" *ngIf="!profileSrc" alt="{{name}}" />
            <img [src]="profileSrc" *ngIf="profileSrc" class="header-profile-pic" alt="{{name}}" />
          </a>
          <ul class="dropdown-menu  dropdown-menu-end mt-2">
            <li><a class="dropdown-item link" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation"
                aria-current="page" routerLink="/profile-management/profile-edit"><img class="me-2 img-logo"
                  alt="user profile pic" src="../../assets/profile.svg"> Account</a></li>
            <li><a class="dropdown-item link" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation"
                aria-current="page" routerLink="/profile-management/bookings"><img class="me-2 img-logo"
                  alt="notification" src="../assets/notification.svg"> Bookings</a></li>
            <li><a class="dropdown-item link" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation"
                aria-current="page" style="color: red; cursor: pointer;" (click)="signout()"><img class="me-2 img-logo"
                  alt="logout" src="../../assets/logout.svg"> Sign out</a></li>
          </ul>
        </div>
    </div>
      <div *ngIf="UserType == 'merchant' || UserType == 'partner'" class="position-relative">
        <button class="navbar-toggler" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight"
          aria-controls="offcanvasRight" style="border:none; box-shadow:none;">
          <span class="navbar-toggler-icon"></span>
        </button>
      </div>
    </div>
    <div class="container-fluid">
      <div style="width: calc(100% - 57px);">
        <div class="form-group  w-100 px-md-0 ps-3 form-banner mx-auto" *ngIf="UserType == null || UserType === 'user'">
          <div class="input-group mt-2 mobile" (focusin)="highlightInputGroup('mobile')"
            (focusout)="removeHighlightInputGroup('mobile')">
            <input type="text" class="form-control input-left-group  opacity no-focus-outline"
              [placeholder]="currentPlaceholder" [(ngModel)]="location" name="location"
              (keyup.enter)="onLocationChange()">
            <div class="input-group-append pointer" (click)="onLocationChange()">
              <span class="input-group-text p-1 input-right-group opacity">
                <img src="../../assets/search.svg" width="30px" alt="search">
              </span>
            </div>
          </div>
        </div>

      </div>
      <div class="d-flex mt-2">
        <!-- <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation"
          style="border:none; box-shadow:none;">
          <span class="navbar-toggler-icon"></span>
        </button> -->
        <button class="navbar-toggler" *ngIf="UserType !== 'merchant' && UserType != 'partner'" type="button"
          data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight"
          style="border:none; box-shadow:none;">
          <span class="navbar-toggler-icon"></span>
        </button>
      </div>

      <div class="offcanvas offcanvas-end" tabindex="-1" id="offcanvasRight" aria-labelledby="offcanvasRightLabel">
        <div class="offcanvas-header">
          <!-- <h5 id="offcanvasRightLabel">Offcanvas right</h5> -->
          <button type="button" class="btn-close text-reset ms-auto d-block" data-bs-dismiss="offcanvas"
            aria-label="Close"></button>
        </div>
        <div class="offcanvas-body">
          <h5 class="heading mb-0">Quick Links</h5>
          <ul class="navbar-nav me-auto mb-2 mb-lg-0">
            <li class="nav-item " *ngIf="UserType == null || UserType === 'user'">
              <a class="nav-link mobile-link px-3" data-bs-dismiss="offcanvas" aria-label="Close"
                aria-controls="navbarSupportedContent" aria-expanded="false" aria-current="page"
                (click)="navigateToLocation()" routerLink="/home">Home</a>
            </li>
            <li class="nav-item mt-sm-0">
              <a class="nav-link mobile-link px-3" data-bs-dismiss="offcanvas" aria-label="Close"
                aria-controls="navbarSupportedContent" aria-expanded="false" aria-current="page"
                routerLink="/blogs">Blogs</a>
            </li>
            <li class="nav-item mt-sm-0" *ngIf="UserType == 'user'">
              <a class="nav-link mobile-link px-3" data-bs-dismiss="offcanvas" aria-label="Close"
                aria-controls="navbarSupportedContent" aria-expanded="false" aria-current="page"
                routerLink="/profile-management/bookings">Bookings</a>
            </li>
            <li class="nav-item mt-sm-0" *ngIf="UserType == 'merchant'">
              <a class="nav-link mobile-link px-3" data-bs-dismiss="offcanvas" aria-label="Close"
                aria-controls="navbarSupportedContent" aria-expanded="false" aria-current="page"
                routerLink="/merchant-bookings">Dashboard</a>
            </li>
            <li class="nav-item " *ngIf="UserType == 'merchant'">
              <a class="nav-link mobile-link px-3" data-bs-dismiss="offcanvas" aria-label="Close"
                aria-controls="navbarSupportedContent" aria-expanded="false" aria-current="page"
                routerLink="/merchant-reviews">Reviews</a>
            </li>
            <li class="nav-item " *ngIf="UserType == 'merchant'">
              <a class="nav-link mobile-link px-3" data-bs-dismiss="offcanvas" aria-label="Close"
                aria-controls="navbarSupportedContent" aria-expanded="false" aria-current="page"
                routerLink="/merchantnotifications">Notifications</a>
            </li>
            <li class="nav-item " *ngIf="UserType == 'merchant'">
              <a class="nav-link mobile-link px-3" data-bs-dismiss="offcanvas" aria-label="Close"
                aria-controls="navbarSupportedContent" aria-expanded="false" aria-current="page"
                routerLink="/full-merchant-profile">Profile</a>
            </li>
            <li class="nav-item " *ngIf="UserType == 'partner'">
              <a class="nav-link mobile-link px-3" data-bs-dismiss="offcanvas" aria-label="Close"
                aria-controls="navbarSupportedContent" aria-expanded="false" aria-current="page"
                routerLink="/partnerdashboard">Dashboard</a>
            </li>
            <li class="nav-item " *ngIf="UserType == 'partner'">
              <a class="nav-link mobile-link px-3 pb-0 pointer" data-bs-dismiss="offcanvas" aria-label="Close"
                aria-controls="navbarSupportedContent" aria-expanded="false" aria-current="page"
                (click)="signout()">Sign out</a>
            </li>
            <li class="nav-item " *ngIf="UserType == 'merchant'">
              <a class="nav-link mobile-link px-3 pb-0 pointer" data-bs-dismiss="offcanvas" aria-label="Close"
                aria-controls="navbarSupportedContent" aria-expanded="false" aria-current="page"
                (click)="signout()">Sign out</a>
            </li>
            <li class="nav-item " *ngIf="!name">
              <a class="nav-link mobile-link px-3" data-bs-dismiss="offcanvas" aria-label="Close"
                aria-controls="navbarSupportedContent" aria-expanded="false" aria-current="page"
                href="javascript:void(0)" (click)="openProfile()">Register/Sign in</a>
            </li>
            <li class="nav-item " *ngIf="!name && UserType == null">
              <a class="nav-link mobile-link px-3" data-bs-dismiss="offcanvas" aria-label="Close"
                aria-controls="navbarSupportedContent" aria-expanded="false" aria-current="page"
                href="javascript:void(0)" (click)="guestLogin()">Guest login</a>
            </li>
            <li class="nav-item " *ngIf="!name && UserType == null">
              <a class="nav-link mobile-link px-3" data-bs-dismiss="offcanvas" aria-label="Close"
                aria-controls="navbarSupportedContent" aria-expanded="false" aria-current="page"
                href="javascript:void(0)" (click)="openMerchantLoginDialogue()">Merchant Login</a>
            </li>
            <li class="nav-item " *ngIf="!name && UserType == null">
              <a class="nav-link mobile-link px-3" data-bs-dismiss="offcanvas" aria-label="Close"
                aria-controls="navbarSupportedContent" aria-expanded="false" aria-current="page"
                href="javascript:void(0)" (click)="loginPartner()">Partner Login</a>
            </li>
          </ul>
          <h5 class="heading mb-0"> Support</h5>
          <ul class="navbar-nav me-auto mb-2 mb-lg-0">
            <li class="nav-item ">
              <a class="nav-link mobile-link px-3" data-bs-dismiss="offcanvas" aria-label="Close"
                aria-controls="navbarSupportedContent" aria-expanded="false" aria-current="page"
                routerLink="/terms-condition">Terms &
                Conditions</a>
            </li>
            <li class="nav-item ">
              <a class="nav-link mobile-link px-3" data-bs-dismiss="offcanvas" aria-label="Close"
                aria-controls="navbarSupportedContent" aria-expanded="false" aria-current="page"
                routerLink="/about-us">About Us</a>
            </li>
            <li class="nav-item ">
              <a class="nav-link mobile-link px-3" data-bs-dismiss="offcanvas" aria-label="Close"
                aria-controls="navbarSupportedContent" aria-expanded="false" aria-current="page"
                routerLink="/faq">FAQ</a>
            </li>
            <li class="nav-item ">
              <a class="nav-link mobile-link px-3" data-bs-dismiss="offcanvas" aria-label="Close"
                aria-controls="navbarSupportedContent" aria-expanded="false" aria-current="page"
                routerLink="/cancellation-policy">Cancellation
                Policy</a>
            </li>
            <li class="nav-item ">
              <a class="nav-link mobile-link px-3" data-bs-dismiss="offcanvas" aria-label="Close"
                aria-controls="navbarSupportedContent" aria-expanded="false" aria-current="page"
                routerLink="/privacy-policy">Privacy Policy</a>
            </li>
            <li class="nav-item ">
              <a class="nav-link mobile-link px-3" data-bs-dismiss="offcanvas" aria-label="Close"
                aria-controls="navbarSupportedContent" aria-expanded="false" aria-current="page"
                routerLink="/contact-us">Contact Us</a>
            </li>
          </ul>
          <div class="d-flex  justify-content-start mobile-footer ms-3" *ngIf="ifMobileApp()">
            <a href="javascript:void(0)" (click)="openSocialMedia('https://www.facebook.com/AdvenzoneApp')">
              <img src="/assets/fb.svg" width="38px" alt="fb" class="ms-0 mt-2"></a>
            <a href="javascript:void(0)" (click)="openSocialMedia('https://www.instagram.com/advenzone/')">
              <img src="/assets/instagram.svg" width="38px" alt="instagram" class="ms-2 mt-2"></a>
            <a href="javascript:void(0)" (click)="openSocialMedia('https://www.linkedin.com/company/advenzone/')">
              <img src="/assets/linkedIn.svg" width="38px" alt="linkedIn" class="ms-2 mt-2"></a>
          </div>
        </div>
      </div>

      <!-- <div class="collapse navbar-collapse mt-3 app" id="navbarSupportedContent">
        <ul class="navbar-nav me-auto mb-2 mb-lg-0">
          <li class="nav-item ms-3" *ngIf="UserType == null || UserType === 'user'">
            <a class="nav-link link px-3" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation"
              aria-current="page" routerLink="/home" routerLinkActive="active-list-item"><img src="../assets/home.svg"
                routerLinkActive="white-img" class="mb-1" width="15" height="15"> Home</a>
          </li>
          <li class="nav-item ms-3 mt-2 mt-sm-0" *ngIf="UserType == 'merchant'">
            <a class="nav-link link px-3" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation"
              aria-current="page" routerLink="/merchant-bookings" routerLinkActive="active-list-item"><img
                src="../assets/home.svg" class="mb-1" routerLinkActive="white-img" width="15" height="15"> Dashboard</a>
          </li>
          <li class="nav-item ms-3" *ngIf="UserType == 'merchant'">
            <a class="nav-link link px-3" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation"
              aria-current="page" routerLink="/merchant-reviews" routerLinkActive="active-list-item"><img
                src="../assets/header-star.svg" class="mb-1" routerLinkActive="white-img" width="15" height="15"> Reviews</a>
          </li>
          <li class="nav-item ms-3" *ngIf="UserType == 'merchant'">
            <a class="nav-link link px-3" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation"
              aria-current="page" routerLink="/full-merchant-profile" routerLinkActive="active-list-item"><img
                src="../assets/profile.svg" class="mb-1" routerLinkActive="white-img" width="15" height="15"> Profile</a>
          </li>
          <li class="nav-item ms-3" *ngIf="UserType == 'partner'">
            <a class="nav-link link px-3" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation"
            aria-current="page" routerLink="/partnerdashboard" routerLinkActive="active-list-item"><img
              src="../assets/home.svg" routerLinkActive="white-img" class="mb-1" width="15" height="15"> Dashboard</a>
          </li>
          <li class="nav-item ms-3" *ngIf="UserType == 'partner'">
            <a class="nav-link link px-3 pb-0" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation"
              aria-current="page" style="color: red; cursor: pointer;" (click)="signout()"><img class="me-2 img-logo"
                src="../../assets/logout.svg"> Sign out</a>
          </li>
          <li class="nav-item ms-3" *ngIf="UserType == 'merchant'">
            <a class="nav-link link px-3 pb-0" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation"
              aria-current="page" style="color: red; cursor: pointer;" (click)="signout()"><img
                src="../../assets/logout.svg" width="15" height="15">Sign out</a>
          </li>
          <li class="nav-item ms-3" *ngIf="!name">
            <a class="nav-link link px-3" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation"
              aria-current="page" href="javascript:void(0)" routerLinkActive="active-list-item"
              (click)="openProfile()"><img src="../assets/profile.svg" class="mb-1" width="15" height="15"> Profile</a>
          </li>
          <li class="nav-item ms-3" *ngIf="!name && UserType == null &&  ifMobileApp()">
            <a class="nav-link link px-3" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation"
              aria-current="page" href="javascript:void(0)" routerLinkActive="active-list-item"
              (click)="loginPartner()">Partner Login</a>
          </li>
          <li class="nav-item ms-3" *ngIf="ifMobileApp()">
            <a class="nav-link link px-3" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation"
              aria-current="page" routerLink="/terms-condition" routerLinkActive="active-list-item">Terms &
              Conditions</a>
          </li>
          <li class="nav-item ms-3" *ngIf="ifMobileApp()">
            <a class="nav-link link px-3" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation"
              aria-current="page" routerLink="/about-us" routerLinkActive="active-list-item">About Us</a>
          </li>
          <li class="nav-item ms-3" *ngIf="ifMobileApp()">
            <a class="nav-link link px-3" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation"
              aria-current="page" routerLink="/faq" routerLinkActive="active-list-item">FAQ</a>
          </li>
          <li class="nav-item ms-3" *ngIf="ifMobileApp()">
            <a class="nav-link link px-3" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation"
              aria-current="page" routerLink="/cancellation-policy" routerLinkActive="active-list-item">Cancellation
              Policy</a>
          </li>
          <li class="nav-item ms-3" *ngIf="ifMobileApp()">
            <a class="nav-link link px-3" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation"
              aria-current="page" routerLink="/privacy-policy" routerLinkActive="active-list-item">Privacy Policy</a>
          </li>
          <li class="nav-item ms-3" *ngIf="ifMobileApp()">
            <a class="nav-link link px-3" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation"
              aria-current="page" href="javascript:void(0)" (click)="openContactUsForm()"
              routerLinkActive="active-list-item">Contact Us</a>
          </li>
        </ul>
      </div> -->

    </div>
  </nav>
</div>