<section class="rental-description-page-fixed-margin-top">
    <div class="rental-description-page-content mb-3"  [class.pb-3]="ifMobileApp()">
        <h1 class="rental-description-page-title mb-md-4 mb-2">Affordable Bike Rental in {{locationName}}</h1>
        <img [src]="homeData.src[0]" *ngIf="homeData && homeData.src[0]" class="rental-description-page-carousel-height w-100" />
        <div class="row mt-2">
            <div class="col-md-8 col-12 pe-md-3">
              <div #activitycontentDiv>
                <h2 class="rental-description-page-sub-title my-2">{{homeData.value}}</h2>
                <div [innerHTML]="merchantData?.description"></div>
                <div id="map" class="map pointer mt-3" (click)="dataService.openMap(merchantData?.latitude, merchantData?.longitude)"></div>
                <div class="rental-description-ratings-div my-3" *ngIf="reviews && reviews.length > 0">
                    <h2 class="rental-description-sub-title mb-4">Reviews & Ratings</h2>
                    <div class="d-flex">
                      <div class="me-5">
                        <h4 class="rental-description-review mb-1">Total Review</h4>
                        <h2 class="mb-0 rental-description-review-value">{{merchantData?.total_persons_rated}}</h2>
                      </div>
                      <div class="ps-5" style="border-left: 1px solid #CCCCCC">
                        <h4 class="rental-description-review mb-1">Average Rating</h4>
                        <div class="d-flex align-items-center">
                          <h2 class="mb-0 rental-description-review-value">{{merchantData?.avg_rating}}</h2>
                          <ng-container *ngFor="let star of [1, 2, 3, 4, 5]">
                            <span [ngClass]="{'filled': star <= merchantData?.avg_rating, 'empty': star > merchantData?.avg_rating}" class="rental-description-star">&#9733;</span>
                        </ng-container>
                        </div>
                      </div>
                    </div>
                    <hr class="rental-description-hr">
                    <ng-containter *ngFor="let data of  reviews | slice:0:3; let j= index">
                      <div class="rental-description-review-content mb-4">
                        <div class="d-flex align-items-center">
                          <img [src]="data.src" alt="fire icon" *ngIf="data.src && data.src != ''" class="rental-description-review-content-user-img">
                          <img src="assets/default-profile-pic.svg" alt="fire icon" *ngIf="!data.src || data.src == ''" class="rental-description-review-content-user-img rounded-circle">
                          <h3 class="rental-description-review-content-user-name mb-0 ms-2">{{ data.firstname + (data.lastname != null ? ' ' + data.lastname : '') }}</h3>
                        </div>
                        <div class="d-flex mt-2">
                          <ng-container *ngFor="let star of [1, 2, 3, 4, 5]; let j= index">
                            <span [ngClass]="{'filled': star <= data.star, 'empty': star > data.star}"  [class.ms-0]="j == 0"  class="review-details-star">&#9733;</span>
                        </ng-container>
                        <p class="rental-description-review-date ms-2">{{convertTimestampToDateFormat(data.createdon)}}</p>
                        </div>
                        <p class="rental-description-review-content-text">{{data.review}}</p>
                      </div>
                    </ng-containter>
                    <div class="mt-4 text-center" *ngIf="loadMoreReviews">
                      <button class="btn btn-show-more" (click)="showMoreReviews()">Show more →</button>
                    </div>
                    <hr>
                  </div>
                </div>
            </div>
            <div class="col-md-4 col-12">
                <div #providedbyDiv class="rental-description-provided-by-div">
                    <h4 class="rental-description-provided-by-title pt-3 mb-md-3 mb-2">Provided By</h4>
                      <div class="mt-2" *ngFor="let data of  providedByMerchant; let j= index">
                        <app-des-provided-by-card [data]="data" (enquire_merchant)="enquire_merchant($event)"  (book_merchant)="book_merchant($event)" ></app-des-provided-by-card>
                      </div>
                    <h4 class="rental-description-provided-by-title pt-3 mb-md-3 mb-2" *ngIf="rentalMerchant.length > 0">Other Providers</h4>
                      <div class="mt-2" *ngFor="let data of  rentalMerchant; let j= index">
                        <app-des-provided-by-card [data]="data" (enquire_merchant)="enquire_merchant($event)"  (book_merchant)="book_merchant($event, 'otherproviders')" ></app-des-provided-by-card>
                      </div>
                </div>
            </div>
        </div>
        <div class="mb-3 mt-md-0 mt-3" *ngIf="allBikeCard && allBikeCard.length > 0">
            <h2 class="rental-description-sub-title pt-sm-3 mb-2">Other Vehicles provided by {{merchantData?.name}}</h2>
            <div class="row">
              <div class="mt-3 col-12 col-md-4" *ngFor="let data of  allBikeCard | slice:0:3; let j= index">
                <app-bike-rental-card [data]="data" ></app-bike-rental-card>
              </div>
            </div>
          </div>
          <button class="btn btn-show-more mx-auto d-block mt-4" *ngIf="allBikeCard.length > 3"  (click)="ShowMoreTopAdventurists('activity')" >Show more →</button>
          <hr *ngIf="allBikeCard.length > 0">

            <div class=" mb-3" *ngIf="topAdventuristsData && topAdventuristsData.length > 0">
                <h2 class="rental-description-sub-title pt-sm-3 mb-2">Top Adventurists <span *ngIf="locationName !== '' && locationName !== null"> in {{locationName }}</span></h2>
                <div class="row">
                  <div class="mt-3 col-12 col-md-4" *ngFor="let data of  topAdventuristsData | slice:0:3; let j= index">
                    <app-new-merchant-profile-card [data]="data"></app-new-merchant-profile-card>
                  </div>
                </div>
              </div>
              <div class="my-5 text-center"  *ngIf="topAdventuristsLoadMore" >
                <button class="btn btn-show-more" (click)="ShowMoreTopAdventurists('merchant')">Show more →</button>
              </div>
        </div>
        <app-footer></app-footer>
</section>