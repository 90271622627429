import { HttpClient } from '@angular/common/http';
import { Component, Inject } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { Router } from '@angular/router';
import { ActivityFilterService } from 'src/app/services/activity-filter.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-categories-popup',
  templateUrl: './categories-popup.component.html',
  styleUrls: ['./categories-popup.component.css']
})
export class CategoriesPopupComponent {

  locationId: any;
  indexData: any = {};
  locationName: any = "";
  activityFilterSubscription:any;

  constructor(private bottomSheetRef: MatBottomSheetRef<CategoriesPopupComponent>, @Inject(MAT_BOTTOM_SHEET_DATA) public data: any, private http: HttpClient, private router: Router, private activityFilterService: ActivityFilterService) {
    this.activityFilterSubscription = this.activityFilterService.category_index_count_data$.subscribe((data:any) => {
      this.indexData = data;
    })
  }

  ngOnDestroy() {
    if (this.activityFilterSubscription) {
      this.activityFilterSubscription.unsubscribe();
    }
  }


  navigateTo(category: any) {
    switch (category) {
      case 'rafting':
        sessionStorage.setItem('userData', JSON.stringify({ category: 'rafting' }))
        break;
      case 'trekking':
        sessionStorage.setItem('userData', JSON.stringify({ category: 'trekking' }))
        break;
      case 'camping':
        sessionStorage.setItem('userData', JSON.stringify({ category: 'camping' }))
        break;
      case 'bike-rental':
        sessionStorage.setItem('userData', JSON.stringify({ category: 'bike-rental' }))
        break;
      case 'tour':
        sessionStorage.setItem('userData', JSON.stringify({ category: 'tour' }))
        break;
      case 'activity':
        sessionStorage.setItem('userData', JSON.stringify({ category: 'activity' }))
        break;
      case 'topAdventurists':
        sessionStorage.setItem('userData', JSON.stringify({ category: 'topAdventurists' }))
        break;
    }
    let location = localStorage.getItem('location');
    this.locationName = JSON.parse(location).locationname;
    this.locationId = JSON.parse(location).id;
    let name = this.locationName.trimEnd().toLowerCase().replaceAll(" ", "-");
    const currentUrl = this.router.url;
    if (currentUrl.includes('home/location')) {
      if(category === 'activity'){
        this.activityFilterService.setActiveCategoryData({ active_category: 'quickactivities' });
      }else{
        this.activityFilterService.setActiveCategoryData({ active_category: category });
      }
    }else{
       this.router.navigate(['/home', 'location', this.locationId, name]);
    }
    this.bottomSheetRef.dismiss(); // This closes the bottom sheet
  }

  close(): void {
    this.bottomSheetRef.dismiss(); // This closes the bottom sheet
  }
}
