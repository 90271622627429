import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthAffiliateGuard implements CanActivate {

  constructor(private http: HttpClient, private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    
    if (route.params['id']) {
      const uniqueId = route.params['id'];
      
      return this.http.get(`${environment.apiUrl}/affiliate/get-unique-id?uniqueId=${uniqueId}`).pipe(
        map((data: any) => {
          if (data.status === "SUCCESS") {
            localStorage.setItem('linkFrom', 'affiliatelink');
            localStorage.setItem('affiliateId', data.affiliate.id);
            return true; // Allow access
          } else {
            this.router.navigate(['/404-not-found']); // Navigate to not found page
            return false; // Deny access
          }
        }),
        catchError((error: HttpErrorResponse) => {
          console.error('Error fetching unique ID:', error);
          this.router.navigate(['/404-not-found']); // Navigate to not found page on error
          return of(false); // Return false in case of error
        })
      );
    } else {
      return of(false); // If no ID is present, deny access
    }
  }
}
