import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ProfileManagementComponent } from './profile-management/profile-management.component';
import { ProfileEditComponent } from './profile-edit/profile-edit.component';
import { ProfileBookingsComponent } from './profile-bookings/profile-bookings.component';
import { LandingPageComponent } from './landing-page/landing-page.component';
import { AuthAffiliateGuard } from './guard/auth-affiliate.guard';

const routes: Routes = [
  // {
  //   path: '', redirectTo: '/', pathMatch: 'full'
  // },
  {
    path: '', component: LandingPageComponent,
  },
  { path: 'affiliate/:id', component: LandingPageComponent, canActivate: [AuthAffiliateGuard] },
  { path: 'location/:id/:locationname', loadChildren: () => import('./location/location-details/location-details.module').then(m => m.LocationDetailsModule) },
  {
    path: 'home', loadChildren: () => import('./home/home.module').then(m => m.HomeModule),
  },
  {
    path: 'home/location/:locationid/:locationname',  loadChildren: () => import('./home/home.module').then(m => m.HomeModule)
  },
  {
    path: 'search',  loadChildren: () => import('./search/search.module').then(m => m.SearchModule)
  },
  {
    path: 'profile-management',
    redirectTo: 'profile-management/profile-edit'
  },
  {
    path: 'ProfileManagement/bookings',
    redirectTo: 'profile-management/bookings'
  },
  {
    path: 'profile-management',
    component: ProfileManagementComponent,
    children: [
      { path: 'profile-edit', component: ProfileEditComponent, data: { animation: 'profile-edit' } },
      { path: 'bookings', component: ProfileBookingsComponent, data: { animation: 'bookings' } }
    ]
  },
  {
    path: 'location/:locationid/activity-details/:id/:locationname/:addressline1', loadChildren: () => import('./activitie-details/activitie-details.module').then(m => m.ActivitieDetailsModule)
  },
  {
    path: 'merchant-profile/location/:locationid/merchantid/:id/:city/:name', loadChildren: () => import('./merchant-profile/merchant-profile.module').then(m => m.MerchantProfileModule)
  },
  {
    path: 'agentbooking/:id', loadChildren: () => import('./book-by-agent/book-by-agent.module').then(m => m.BookByAgentModule)
  },
  {
    path: 'book-merchant',  loadChildren: () => import('./booking-merchant/book-merchant/book-merchant.module').then(m => m.BookMerchantModule)
  },
  {
    path: 'selectbike', loadChildren: () => import('./select-bike/select-bike.module').then(m => m.SelectBikeModule)
  },
  {
    path: 'book-merchant/rafting', loadChildren: () => import('./booking-merchant/book-merchant-rafting/book-merchant-rafting.module').then(m => m.BookMerchantRaftingModule)
  },
  {
    path: 'book-merchant/camping',  loadChildren: () => import('./booking-merchant/book-merchant-camping/book-merchant-camping.module').then(m => m.BookMerchantCampingModule)
  },
  {
    path: '',
    loadChildren: () => import('./partner/partner.module').then(m => m.PartnerModule)
  },
  {
    path: '',
    loadChildren: () => import('./merchant/merchant.module').then(m => m.MerchantModule)
  },
  {
    path: 'book-merchant/scooty-rental', loadChildren: () => import('./booking-merchant/book-merchant-scooty-rentals/book-merchant-scooty-rentals.module').then(m => m.BookMerchantScootyRentalsModule)
  },
  {
    path: 'book-merchant/package-tour', loadChildren: () => import('./booking-merchant/book-merchant-package-tour/book-merchant-package-tour.module').then(m => m.BookMerchantPackageTourModule)
  },
  {
    path: 'book-merchant/activity/:id/merchant/:merchantId', loadChildren: () => import('./booking-merchant/book-merchant-activity/book-merchant-activity.module').then(m => m.BookMerchantActivityModule)
  },
  {
    path:'location/:locationid/camping-details/:id/:activitieslocationname/:activitiesaddressline1', loadChildren: () => import('./camping-full/camping-full.module').then(m => m.CampingFullModule)
  },
  {
    path: 'bookings/:referralBookingId', loadChildren: () => import('./bookings/bookings.module').then(m => m.BookingsModule)
  },
  {
    path: 'bookings-merchant/:referralBookingId', loadChildren: () => import('./msg-bookings-merchant/msg-bookings-merchant.module').then(m => m.MsgBookingsMerchantModule)
  },
  {
    path: '',  loadChildren: () => import('./package-tour-section/trekking-details/trekking-details.module').then(m => m.TrekkingDetailsModule)
  },
  {
    path: '',  loadChildren: () => import('./package-tour-section/package-tour-details/package-tour-details.module').then(m => m.PackageTourDetailsModule)
  },
  {
    path: '', loadChildren: () => import('./blogs/blogs.module').then(m => m.BlogsModule)
  },
  {
     path: 'contact-us',  loadChildren: () => import('./contact-us/contact-us.module').then(m => m.ContactUsModule) 
    },
  { 
    path: 'enquiry-merchant-details/:id',  loadChildren: () => import('./enquiry-merchant-details/enquiry-merchant-details.module').then(m => m.EnquiryMerchantDetailsModule)
   },
  {
    path: 'terms-condition', loadChildren: () => import('./termcondition/termcondition.module').then(m => m.TermconditionModule)
  },
  {
    path: 'faq', loadChildren: () => import('./faq/faq.module').then(m => m.FaqModule)
  },
  { path: 'cancellation-policy',  loadChildren: () => import('./cancellation-policy/cancellation-policy.module').then(m => m.CancellationPolicyModule)  },
  { path: 'generate-qr-code', loadChildren: () => import('./generate-qr-code/generate-qr-code.module').then(m => m.GenerateQrCodeModule) },
  { path: 'applecallback', loadChildren: () => import('./applecallback/applecallback.module').then(m => m.ApplecallbackModule)  },
  { path: 'about-us',  loadChildren: () => import('./aboutus/aboutus.module').then(m => m.AboutusModule)  },
  { path: 'paynow', loadChildren: () => import('./payment/payamount/payamount.module').then(m => m.PayamountModule)  },
  { path: 'redirecturl', loadChildren: () => import('./redirecturl/redirecturl.module').then(m => m.RedirecturlModule) },
  { path: 'privacy-policy', loadChildren: () => import('./privacy-policy/privacy-policy.module').then(m => m.PrivacyPolicyModule) },
  {
    path: '404-not-found', loadChildren: () => import('./error-page/invalidurl/invalidurl.module').then(m => m.InvalidurlModule)
  },
  {
    path: 'temporarily-unavailable', loadChildren: () => import('./error-page/invalid-path/invalid-path.module').then(m => m.InvalidPathModule)
  },
  {
    path: 'service-unavailable', loadChildren: () => import('./error-page/service-unavailable/service-unavailable.module').then(m => m.ServiceUnavailableModule)
  },
  { path: '**', redirectTo: '404-not-found' },
 
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
