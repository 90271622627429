import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { DataService } from 'src/app/data.service';
import { EnquiryFormComponent } from 'src/app/enquiry-form/enquiry-form.component';
import { InfoComponent } from 'src/app/info/info.component';



interface details {
  id: any;
  campingName: any;
  campingLatitude: any;
  campingLongitude: any;
  src: any;
  distanceAway: any;
  rating: any;
  hasCoupon: any;
  description:any;
  min_price:any;
  locationid:any;
  locationname:any;
  activityname:any;
  merchantname:any;
  merchantid:any;
}

@Component({
  selector: 'app-camping-card',
  templateUrl: './camping-card.component.html',
  styleUrls: ['./camping-card.component.css']
})
export class CampingCardComponent {
  @Input() data: details;
  
  constructor(public dialog: MatDialog, private router: Router, public dataService: DataService) {}

  openInfoDialog(event:any) {
    event.stopPropagation();
    const isMobile = window.innerWidth < 768;
    let sizes = {
      height: '500px',
      width: '50%',
      maxWidth: 'unset',
    };
    if (isMobile) {
      sizes = {
        width: '100%',
        maxWidth: 'unset',
        height: '100%',
      }
    }
    let input : any ={};
        input.id = this.data.id;
        input.description = this.data.description;
        input.datafrom = 'camping',
        input.locationid = this.data.locationid;
    
    this.dialog.open(InfoComponent, {
      ...sizes,
      disableClose: true,
      data: input,
    });
  }

  openMap(event: any, latitude: any, longitude: any) {
    event.stopPropagation()
    this.dataService.openMap(latitude, longitude);
  }

  openMerchantPage() {
    let locationname = this.data.locationname.trim().replace(/[^a-zA-Z0-9\s]/g, '-').replace(/\s+/g, '-').toLowerCase();
    let name = this.data.merchantname.trim().replace(/[^a-zA-Z0-9\s]/g, '-').replace(/\s+/g, '-').toLowerCase();
    this.router.navigate(['/merchant-profile','location',this.data.locationid,'merchantid',this.data.merchantid,locationname,name])
  }

  openCampingPage(){
    let location = this.data.locationname.trimEnd().toLowerCase().replaceAll(" ", "-");
    let name = this.data.campingName.trimEnd().toLowerCase().replaceAll(" ", "-");
    this.router.navigate(['/location',this.data.locationid,'camping-details',this.data.id,location,name])
  }
  
  openEnquiryDialog(){
    const isMobile = window.innerWidth < 768;
    let sizes = {
      height: '87%',
      width: '33%',
      maxWidth: 'unset',
    };
    if (isMobile) {
      sizes = {
        width: '100%',
        maxWidth: 'unset',
        height: '100%',
      };
    }

    this.dialog.open(EnquiryFormComponent, {
      ...sizes,
      disableClose: true,
      data: {
        datafrom: this.data.campingName,
        activityType: "0",
      },
    });
}
}
