<app-header></app-header>

<div class="menu-item-callback pointer" *ngIf="showCallback" [ngClass]="{ 'show-callback': showMenu }" (click)="openDialog()">
  <img src="/assets/support/request-call-back.svg" alt="request" />
</div>
<div class="menu-item-contactus pointer" [ngClass]="{ 'show-contactus': showMenu }" (click)="callSupport()">
  <img src="/assets/contact.svg" alt="contact us" />
</div>
<div class="menu-item-whatsapp pointer" [ngClass]="{ 'show-whatsapp': showMenu }" (click)="openWhatsapp()">
  <img src="/assets/whatsapp.svg" alt="whatsapp" />
</div>

<div class="menu-img">
  <img src="/assets/support/menu.svg" class="pointer" *ngIf="!showMenu" (click)="toggleMenuVisibility()" alt="menu" />
  <img src="/assets/support/close-button.svg" class="pointer" *ngIf="showMenu" (click)="toggleMenuVisibility()"
    alt="close menu" />
</div>

<img src="/assets/location/back-to-top.svg" alt="go to top" [ngClass]="{'back-to-top-visible': isScrolled}" class="back-to-top" (click)="scrollToTop()">


<!-- <div *ngIf="showCallback" class="callback">
  <label class="pointer  px-3 "  (click)="openDialog()">Request a Call Back</label>
</div> -->

<!-- <div class="whatsapp-img animated tada">
    <div class="whatsappDisplay" (mouseenter)="hovering = true" (mouseleave)="hovering = false">
      <img *ngIf="showCallback" src="../assets/whatsapp.svg" class="pointer" alt="" (click)="toggleLabelVisibility()">
      <div class="whatsappDisplay-content " [style.display]="(showLabel || hovering) ? 'block' : 'none'" >
        <label class="pointer px-3 fw-bold shadow" (click)="openWhatsapp()">Click to chat</label> 
      </div>
    </div>
  </div> -->

<!-- <div class="whatsapp-img" (mouseenter)="animateTada = true" (mouseleave)="animateTada = false">
  <div class="whatsappDisplay"  (mouseenter)="hovering = true" (mouseleave)="hovering = false" >
      <img *ngIf="showCallback" src="../assets/whatsapp.svg" [ngClass]="{'animated tada': animateImgTada}"
        class="pointer " alt="whatsapp" (click)="toggleLabelVisibility()">
    <div [style.display]="(showLabel || hovering) ? 'block' : 'none'" >
      <label class="pointer px-3 fw-bold shadow" (click)="openWhatsapp()"
        [ngClass]="{'animated tada': animateLabelTada}"[style.top.%]="animateLabelTada ? 15 : 50">Click to chat</label>
    </div>
  </div>
</div> -->
<!-- 
<div class="phone-img"  (mouseenter)="animateCallTada = true" (mouseleave)="animateCallTada = false">
  <div  class="phoneDisplay"(mouseenter)="hoveringCall = true" (mouseleave)="hoveringCall = false">
    <img *ngIf="showCallback" src="../assets/contact.svg" class="pointer" alt="contact" [ngClass]="{'animated tada': animateCallImgTada}"(click)="toggleCallLabelVisibility()">
    <div  [style.display]="(showCallLabel || hoveringCall) ? 'block' : 'none'">
      <label class="pointer px-3 fw-bold shadow" (click)="callSupport()" [ngClass]="{'animated tada': animateCallLabelTada}"[style.top.%]="animateCallLabelTada ? 18 : 53">Call us</label>
    </div>
  </div>
</div> -->


<router-outlet></router-outlet>