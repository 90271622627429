<div class="desc-provided-by-card bg-white">
    <div class="position-relative">
        <img src="assets/cards/default-provided-by-img.jpg" *ngIf="!data.cover_src || !data.cover_src[0]" class="desc-provided-by-cover-img" alt="provided by cover pic" />
        <img *ngIf="data.cover_src && data.cover_src[0]" [src]="data.cover_src[0]" class="desc-provided-by-cover-img" alt="provided by cover pic" />
        <div class="desc-provided-by-card-rating rounded-pill position-absolute px-2">
            <ng-container *ngFor="let star of [1, 2, 3, 4, 5]">
                <span [ngClass]="{'filled': star <= data.rating, 'empty': star > data.rating}" class="star">&#9733;</span>
            </ng-container>
        </div>
        <img [src]="data.src[0]" *ngIf="data.src && data.src[0]" class="desc-provided-by-profile-img position-absolute" alt="provided by merchant profile pic" />
        <img src="assets/icons/default-profile-pic.png" *ngIf="!data.src || !data.src[0]" class="desc-provided-by-profile-img position-absolute" alt="provided by merchant profile pic" />
    </div>
    <div class="mx-2">
        <h5 class="desc-provided-by-merchant-name text-truncate">{{data.name}}</h5>
        <div class="d-flex align-items-center justify-content-between">
            <h3 class="desc-provided-by-merchant-shop-name mb-1 text-truncate">{{data.shopName}}</h3>
            <a href="javascript:void(0)" (click)="openMerchantProfile()" class="desc-provided-by-view-profile">View profile</a>
        </div>
        <hr class="des-provided-by-hr">
        <h4 class="desc-provided-by-price mb-1" *ngIf="data.min_price && data.min_price != 0">₹ {{data.min_price}} <span class="desc-provided-by-per-person" *ngIf="data.category != 'tour' && data.category != 'trekking'">{{(data.category == 'camping' || data.category == 'bikerental') ? '/day' : '/person'}}</span></h4>
        <hr class="des-provided-by-hr" *ngIf="data.min_price && data.min_price != 0">
        <div *ngIf="data.category == 'bikerental'">
            <p><img src="/assets/cards/tick-icon.svg" class="me-2" alt="hardness icon">{{(data.availability == true)? 'Available' : 'Not Available'}}</p>
        </div>
        <div *ngIf="data.category != 'trekking' && data.category != 'tour' && data.category != 'bikerental'">
            <a class="me-2 des-provided-by-card-navigation text-truncate w-50" href="javascript:void(0)" (click)="openMap($event)"><img src="/assets/cards/km.svg"
                alt="Km icon"> {{data.distanceAway}} <img src="/assets/cards/link-icon.svg" alt="link icon"></a>
            <p class="desc-provided-by-card-duration mt-3" ><img src="assets/cards/duration.svg" alt="duration icon" style="margin-right: 4px;"> {{data.time_from}} - {{data.time_to}}</p>
        </div>
        <div *ngIf="data.category == 'trekking' || data.category == 'tour'">
            <div class="d-flex">
                <a class="me-2 des-provided-by-card-navigation text-truncate w-50" href="javascript:void(0)" (click)="openMap($event)"><img src="/assets/cards/km.svg"
                    alt="Km icon"> {{data.distanceAway}} <img src="/assets/cards/link-icon.svg" alt="link icon"></a>
                <p class="me-2 trekking-card-activityname " *ngIf="data.category == 'trekking'"><img src="/assets/cards/mountain.svg" alt="mountain icon"> {{data.totalaltitude}}</p>
                <p class="me-2 trekking-card-activityname " *ngIf="data.category == 'tour'"><img src="/assets/cards/km.svg" alt="km icon"> {{data.totaldistance}}</p>
            </div>
            <div class="d-flex">
                <p class="me-2 trekking-card-activityname"><img src="/assets/cards/hardness.svg" alt="hardness icon"> {{data.difficulty}}</p>
                <p class="me-2 trekking-card-activityname"><img src="/assets/cards/duration.svg" alt="duration icon"> {{data.numberofdays}} days</p>
            </div>
            <hr class="des-provided-by-hr">
            <h4 class="des-provided-by-availability">Availability</h4>
            <div *ngFor="let month of data.months; let i = index">
                <div class="d-flex mb-3 justify-content-between align-items-center px-3 des-provided-by-available-month-container pointer" (click)="openSlot(i)">
                    <h6 class="py-2 mb-0 des-provided-by-available-month">{{month}}</h6>
                    <img src="/assets/filter-icons/down-arrow.svg" alt="down icon" />
                </div>
                <div class="row mb-2" *ngIf="selectedSlot == i">
                    <div class="col-6 mt-2" *ngFor="let slot of data.groupedSlots[month]">
                        <div class="dec-provided-by-card-available-slots-div px-2"  (click)="slot.available !== 0 && !slot.isPastDate && (data.min_price && data.min_price != 0) ? book_slot_merchant(slot.available, slot) : null"  [class.active]="slot.available !== 0 && !slot.isPastDate"  [ngClass]="{ 'pointer': slot.available !== 0 && !slot.isPastDate && (data.min_price && data.min_price != 0)}" >
                            <h4 class="dec-provided-by-card-available-slots-contnent mb-0 text-center" [class.active]="slot.available !== 0 && !slot.isPastDate">{{formatDate.formatDateRange(slot.slot)}}</h4>
                            <h4  class="dec-provided-by-card-available-slots-contnent mb-0 text-center" [class.active]="slot.available !== 0 && !slot.isPastDate" *ngIf="slot.available !== 0 && !slot.isPastDate"  > AVBL - {{ slot.available }} </h4>
                            <h4  class="dec-provided-by-card-available-slots-contnent mb-0 text-center"  *ngIf="slot.available == 0 || slot.isPastDate">Closed</h4>
                        </div>
                    </div>
                </div>
            </div>

        </div>
       
    </div>
    <button type="button" class="btn des-provided-by-book-btn px-3" *ngIf="data.category != 'trekking' && data.category != 'tour' && data.min_price && data.min_price != 0" (click)="book_merchant.emit(data.id)">{{(data.category != 'bikerental' || data.dataFor == 'booking') ?  'Book Now' : 'View Details'}}</button>
    <button type="button" class="btn des-provided-by-book-btn px-3" *ngIf="(data.category == 'trekking' || data.category == 'tour') && !selectedSlot && selectedSlot != 0 && data.min_price && data.min_price != 0" (click)="openSlot(data.months.length -1)">Book Now</button>
    <button type="button" class="btn des-provided-by-enquire-btn px-3" *ngIf="!data.min_price || data.min_price == 0" (click)="enquire_merchant.emit(data.id)">Enquire</button>
</div>