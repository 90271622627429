<div class="merchant-profile-card shadow bg-white position-relative">
    <div class="new-merchant-has-coupon pe-3" *ngIf="data.hasCoupon">
        <h6 class="mb-0 ps-3">Offer Available</h6>
    </div>
    <div class="p-3">
        <div class="new-merchant-profile-card-title-content d-flex align-items-center">
            <img src="assets/icons/default-profile-pic.png" *ngIf="!data.src || data.src.length == 0" width="75px"
                height="75px" class="merchant-profile-card-profile-pic rounded-circle" alt="profile" />
            <img [src]="data.src[0]" *ngIf="data.src && data.src.length > 0" width="75px" height="75px"
                class="merchant-profile-card-profile-pic rounded-circle" alt="profile" />
            <div class="ms-4" style="width: calc(100% - 100px);">
                <h6 class="merchant-profile-card-title mb-0">Name</h6>
                <h4 class="merchant-profile-card-name mb-0 text-truncate">{{data.name}}</h4>
                <h6 class="merchant-profile-card-title mb-0 mt-2">Location</h6>
                <h4 class="merchant-profile-card-name mb-0 text-truncate">{{data.locationName}}</h4>
            </div>
        </div>
        <hr>
        <div class="merchant-profile-card-content d-flex">
            <div class="text-center w-50">
                <h3 class="merchant-profile-card-activity-count mb-0">{{(data.activityCount == 0 || data.activityCount
                    == 1)
                    ? data.activityCount : (data.activityCount - 1) + '+'}}</h3>
                <h6 class="merchant-profile-card-title mb-0">Adventure Activities</h6>
            </div>
            <div class="text-center w-50">
                <div class="d-flex align-items-center justify-content-center">
                    <h3 class="merchant-profile-card-activity-count mb-0">{{data.averagestar}} </h3>
                    <img src="assets/cards/star-icon.svg" class="ms-1" alt="star icon" />
                </div>
                <h6 class="merchant-profile-card-title mb-0">Rating</h6>
            </div>
        </div>
        <div class="merchant-profile-card-content d-flex mt-2 align-items-end ">
            <div class="reviewed-profile-images w-50">
                <img [src]="(img == null) ? 'assets/default-profile-pic.svg' : img"
                    *ngFor="let img of data.reviewed_user_pic" alt="Image 1" class="rounded-circle">
            </div>
            <div class="w-50 text-end">
                <button type="button" class="btn merchant-view-details rounded-pill px-4"
                    (click)="openMerchant()">View details</button>
            </div>
        </div>

    </div>
</div>