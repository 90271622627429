<div class="footer-icon-div d-md-none d-block">
    <div class="d-flex justify-content-around mt-3">
        <div class="text-center pointer" (click)="openCategory()">
            <img src="/assets/icons/footer-category-icon.svg" alt="category icon" class="pointer">
            <h6 class="footer-icon-component-menu">category</h6>
        </div>
        <div class="text-center pointer" (click)="openSearchLocation()">
            <img src="/assets/icons/footer-search-icon.svg" alt="category icon" class="pointer">
            <h6 class="footer-icon-component-menu">Search</h6>
        </div>
        <!-- <div class="text-center pointer" (click)="openLocation()">
            <img src="/assets/icons/footer-location-icon.svg" alt="category icon" class="pointer">
            <h6 class="footer-icon-component-menu">Rishikesh</h6>
        </div> -->
        <div class="text-center pointer" *ngIf="router.url.includes('/home/location')" (click)="openFilter()">
            <img src="/assets/icons/filter.svg" alt="category icon" class="pointer">
            <h6 class="footer-icon-component-menu">Filter</h6>
        </div>
    </div>
</div>
