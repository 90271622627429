<div class="mindesc-provided-by-card">
    <div class="position-relative">
        <img src="assets/cards/default-provided-by-img.jpg" *ngIf="!data.cover_src || !data.cover_src[0]" class="mindesc-provided-by-cover-img" alt="provided by cover pic" />
        <img *ngIf="data.cover_src && data.cover_src[0]" [src]="data.cover_src[0]" class="mindesc-provided-by-cover-img" alt="provided by cover pic" />
        <div class="mindesc-provided-by-card-rating rounded-pill position-absolute px-2">
            <ng-container *ngFor="let star of [1, 2, 3, 4, 5]">
                <span [ngClass]="{'filled': star <= data.rating, 'empty': star > data.rating}" class="star">&#9733;</span>
            </ng-container>
        </div>
        <img [src]="data.src[0]" *ngIf="data.src && data.src[0]" class="mindesc-provided-by-profile-img position-absolute" alt="provided by merchant profile pic" />
        <img src="assets/icons/default-profile-pic.png" *ngIf="!data.src || !data.src[0]" class="mindesc-provided-by-profile-img position-absolute" alt="provided by merchant profile pic" />
    </div>
    <div class="mb-3 min-desc-privided-by-content">
        <h5 class="mindesc-provided-by-merchant-name text-truncate">{{data.name}}</h5>
        <h3 class="mindesc-provided-by-merchant-shop-name mb-1 text-truncate">{{data.shopName}}</h3>
        <div class="d-flex align-items-center justify-content-between">
            <a class="min-desc-provided-by-card-navigation text-truncate w-50" href="javascript:void(0)"  (click)="openMap($event)"><img src="/assets/cards/km.svg"
                alt="Km icon" class="me-2"> {{data.distanceAway}} <img src="/assets/cards/link-icon.svg" alt="link icon"></a>
            <button type="button" class="btn mindesc-provided-by-view-profile rounded-pill" (click)="openMerchantProfile()">View profile</button>
        </div>
        
    </div>
</div>