<!-- from home && activitie-details -->
<div class="card border-0 shadow h-100" *ngIf="merchantData !== 'book-merchant'">
  <div class="card-body p-2">
    <div class="row h-100">
      <div class="col-6">
        <div (click)="openMerchant(data.locationid,data.id,data.city,data.name)" class="w-100 h-100 position-relative">
          <img class="card-img-divs" src="assets/default-profile-pic.svg" *ngIf="!data.shopsrc[0] " alt="profile" />
          <img class="card-img-divs" [src]=" data.shopsrc[0]" *ngIf="data.shopsrc[0]" alt="profile" />
          <div *ngIf="data.hasCoupon">
            <div class="position-absolute offer ">
              <label class="pointer px-2 offer-text  offer-textlable">OFFER AVAILABLE</label>
            </div>
          </div>
        </div>
      </div>
      <div class="col-6 ps-1 ps-md-3">
        <div class="ms-0">
          <div class="d-flex justify-content-between align-items-center mt-2">
            <h6 class="my-1 shopname default-font-family text-truncate pointer" *ngIf="data.datafrom && data.datafrom === 'rafting'"
              (click)="openMerchant(data.locationid, data.merchantid,data.city,data.name)">{{data.shopname ||
              data.merchantshopname}}<img src="assets/gg_link.svg" class="location-icon" alt="location"></h6>
               <h6 class="my-1 shopname default-font-family text-truncate pointer" *ngIf="!data.datafrom || data.datafrom != 'rafting'"
               (click)="openMerchant(data.locationid, data.id,data.city,data.name)">{{data.shopname ||
               data.merchantshopname}}<img src="assets/gg_link.svg" class="location-icon" alt="location"></h6>
          </div>
          <div class="activity-title text-truncate my-2 pointer"
            (click)="dataService.openMap(data.latitude, data.longitude)"><img src="assets/location-b.svg"
              class="location-icon me-2" alt="location"> <span
              class="text-decoration "><u>{{data.distanceAway}}</u></span>
            <img src="assets/gg_link.svg" class="location-icon" alt="gglink">
          </div>
          <div class="d-flex align-items-center" *ngIf="data.price != 0"><img src="assets/rupee-symbol.svg" alt="rupee"><span
              class="default-font-family price">{{data.price}}</span></div>
          <div class="d-flex align-items-center mt-3 me-1">
            <div *ngIf="exploreData === 'homePage' && data.price != 0">
              <button class="btn book-btn text-white px-4"
                (click)="bookRaftingMerchant(data.id, data.activityid, data.merchantid)">Book
                Now</button>
            </div>
            <div *ngIf="exploreData !== 'homePage' && data.price != 0">
              <button class="btn book-btn text-white px-4" (click)="bookMerchant(data.id, activityId)">Book Now</button>
            </div>
            <div *ngIf="(data.price == '0' || data.price == null || data.price == undefined)" class="callback"
            (click)="$event.stopPropagation()">
            <label class="pointer px-sm-3" (click)="openEnquireDialog(data.id)"> Enquire</label>
          </div>
            <div class="d-inline-flex align-items-baseline ms-3">
              <!-- <ngb-rating [max]="1" [rate]="1"></ngb-rating> -->
              <img src="/assets/home/like_icon.svg" alt="like icon" class="like-icon">
              <h3 class="mb-0 avg-review ms-2 fw-bold">{{data.rating }}</h3>
            </div>
          </div>


        </div>
      </div>
    </div>
    <div class="">
      <hr class=" px-5">
      <table class="w-100 ">
        <tr>
          <td class="activity-title w-50"><img class="me-2 activity-font" src="/assets/time.svg" alt="time">Activity
            Time</td>
          <td class="me-5 p-md-3 p-2 rafting-details default-font-family">{{data.timefrom}} - {{ data.timeto}}</td>
        </tr>
        <tr>
          <td class="activity-title w-50"><img class="me-2  activity-font" src="/assets/managed-by.svg"
              alt="managed">Managed by </td>
          <td class="p-md-3 d-flex align-items-center gap-2 me-2 ">
            <img class="rounded-circle profile-pic pointer"
              (click)="openMerchant(data.locationid,data.id,data.city,data.name)" src="assets/default-profile-pic.svg"
              *ngIf="!data.src[0] " alt="profile" />
            <img class="rounded-circle profile-pic pointer"
              (click)="openMerchant(data.locationid,data.id,data.city,data.name)" [src]="data.src[0]"
              *ngIf="data.src[0]" alt="profile" />
            <h3 class="mb-0  rafting-details default-font-family pointer"
              (click)="openMerchant(data.locationid,data.id,data.city,data.name)"> {{data.name || data.merchantname}}
              <img src="assets/gg_link.svg" class="location-icon" alt="gglink"></h3>
          </td>
        </tr>
      </table>
    </div>
  </div>
</div>



<!-- book-merchant && book-merchant-rafting-->

<div class="card border-0 shadow h-100" *ngIf="merchantData === 'book-merchant'">
  <div class="card-body p-2">
    <div class="row h-100">
      <div class="col-6">
        <div class="w-100 h-100 position-relative">
          <img class="card-img" src="assets/default-profile-pic.svg" *ngIf="!merchant.shopsrc[0]" alt="profile" />
          <img class="card-img" [src]=" merchant.shopsrc[0]" *ngIf="merchant.shopsrc[0]" alt="profile" />
        </div>
      </div>
      <div class="col-6 ps-1 ps-md-3">
        <div class="ms-0">
          <div class="d-flex justify-content-between align-items-center mt-2">
            <h6 class="my-1 shopname default-font-family text-truncate pointer">{{merchant.shopname }}</h6>
          </div>
          <div class="activity-title text-truncate my-2 pointer"
            (click)="dataService.openMap(merchant.latitude, merchant.longitude)"><img src="assets/location-b.svg"
              class="location-icon me-2" alt="location"> <span
              class="text-decoration "><u>{{merchant.distanceAway}}</u></span>
            <img src="assets/gg_link.svg" class="location-icon" alt="gglink">
          </div>
          <div class="d-inline-flex align-items-baseline">
            <!-- <ngb-rating [max]="1" [rate]="1"></ngb-rating> -->
            <img src="/assets/home/like_icon.svg" alt="like icon" class="like-icon">
            <h3 class="mb-0 avg-review ms-2 fw-bold">{{merchant.avgRating}}</h3>
          </div>

        </div>
      </div>
    </div>
    <div class="">
      <hr class=" px-5">
      <table class="w-100 ">
        <tr>
          <td class="activity-title w-50"><img class="me-2 activity-font" src="/assets/time.svg" alt="time">Activity
            Time</td>
          <td class="me-5 p-md-3 p-2 activity-details default-font-family">{{ activityDetails.timefrom}} - {{
            activityDetails.timeto}}</td>
        </tr>
        <tr>
          <td class="activity-title w-50"><img class="me-2  activity-font" src="/assets/managed-by.svg"
              alt="managed">Managed by </td>
          <td class="p-md-3 d-flex align-items-center gap-2 me-2 ">
            <img class="rounded-circle profile-pic pointer" src="assets/default-profile-pic.svg" *ngIf="!merchant.src"
              alt="profile" />
            <img class="rounded-circle profile-pic pointer" [src]="merchant.src" *ngIf="merchant.src" alt="profile" />
            <h3 class="mb-0  rafting-details default-font-family pointer"> {{merchant.name}}</h3>
          </td>
        </tr>
      </table>
    </div>
  </div>
</div>