<div class="mindesc-provided-by-card bg-white">
    <div class="position-relative">
        <img src="assets/cards/default-provided-by-img.jpg" *ngIf="!data.cover_src || !data.cover_src[0]" class="mindesc-provided-by-cover-img" alt="provided by cover pic" />
        <img *ngIf="data.cover_src && data.cover_src[0]" [src]="data.cover_src[0]" class="mindesc-provided-by-cover-img" alt="provided by cover pic" />
        <div class="mindesc-provided-by-card-rating rounded-pill position-absolute px-2">
            <ng-container *ngFor="let star of [1, 2, 3, 4, 5]">
                <span [ngClass]="{'filled': star <= data.rating, 'empty': star > data.rating}" class="star">&#9733;</span>
            </ng-container>
        </div>
        <img [src]="data.src[0]" *ngIf="data.src && data.src[0]" class="mindesc-provided-by-profile-img position-absolute" alt="provided by merchant profile pic" />
        <img src="assets/icons/default-profile-pic.png" *ngIf="!data.src || !data.src[0]" class="mindesc-provided-by-profile-img position-absolute" alt="provided by merchant profile pic" />
    </div>
    <div class="mb-3 min-desc-privided-by-content">
        <h5 class="mindesc-provided-by-merchant-name text-truncate">{{data.name}}</h5>
        <h3 class="mindesc-provided-by-merchant-shop-name mb-1 text-truncate" *ngIf="data.category != 'trekking' && data.category != 'tour'">{{data.shopName}}</h3>
        <ng-container *ngIf="data.category == 'trekking' || data.category == 'tour'">
            <div class="d-flex align-items-center justify-content-between">
                <h3 class="mindesc-provided-by-merchant-shop-name mb-1 text-truncate">{{data.shopName}}</h3>
                <a href="javascript:void(0)" (click)="openMerchantProfile()" class="btn mindesc-provided-by-view-profile">View profile</a>
            </div>
            <hr>
          <h3 class="mindesc-provided-by-merchant-shop-name mb-1 text-truncate" *ngIf="data.price !=0">₹ {{data.price}}</h3>
          <hr>
        </ng-container>
        <hr  *ngIf="data.category == 'camping'">
        <h3 class="mindesc-provided-by-merchant-shop-name mb-1 text-truncate" *ngIf="data.category == 'camping' && data.price !=0">₹ {{data.price}}<span> /person</span></h3>
        <button type="button" class="btn mindesc-provided-by-enquire" *ngIf="data.category == 'camping'  && data.price == 0" (click)="openEnquiryDialog()">Enquire</button>
        <hr  *ngIf="data.category == 'camping'">
        <div class="d-flex align-items-center justify-content-between" *ngIf="data.category != 'trekking' && data.category != 'tour'">
            <a class="min-desc-provided-by-card-navigation text-truncate w-50" href="javascript:void(0)"  (click)="openMap($event)"><img src="/assets/cards/km.svg"
                alt="Km icon" class="me-2"> {{data.distanceAway}} <img src="/assets/cards/link-icon.svg" alt="link icon"></a>
            <button type="button" class="btn mindesc-provided-by-view-profile rounded-pill" (click)="openMerchantProfile()">View profile</button>
        </div>
        <h5 class="mindesc-provided-by-merchant-name text-truncate mt-2" *ngIf="data.category == 'camping'"><img src="/assets/cards/camping-icon.svg" alt="camping icon"> {{data.campname}} - <b>Camping Name</b></h5>
        <h5 class="mindesc-provided-by-merchant-name text-truncate mt-2" *ngIf="data.category == 'camping'"><img src="/assets/cards/duration.svg" alt="duration icon"> {{data.time_from}} to {{data.time_to}} - <b>Check in/out time</b></h5>
        <ng-container *ngIf="data.category == 'trekking' || data.category == 'tour'">
            <div class="d-flex align-items-center justify-content-between">
                <p class="me-2 trekking-card-activityname"><img src="/assets/cards/mountain.svg" alt="mountain icon"> {{data.altitude}}</p>
                <p class="me-2 trekking-card-activityname"><img src="/assets/cards/duration.svg" alt="duration icon"> {{data.duration}} days</p>
            </div>
                <p class="me-2 trekking-card-activityname"><img src="/assets/cards/hardness.svg" alt="hardness icon"> {{data.difficulty}}</p>
        </ng-container>
    </div>
</div>