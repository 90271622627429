import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';


interface MerchantProfileCard {
  id:any;
  name: string;
  src: any;
  description: any;
  locationId: any;
  locationName:any;
  address: any;
  activityCount:any;
  reviewed_user_pic:any;
  hasCoupon:any;
  averagestar:any;
}

@Component({
  selector: 'app-top-vendor-card',
  templateUrl: './top-vendor-card.component.html',
  styleUrls: ['./top-vendor-card.component.css']
})
export class TopVendorCardComponent {
  @Input() data: MerchantProfileCard;

  constructor( private router: Router) {}

  openMerchant(){
    let locationname = this.data.locationName.trim().replace(/[^a-zA-Z0-9\s]/g, '-').replace(/\s+/g, '-').toLowerCase();
    let name = this.data.name.trim().replace(/[^a-zA-Z0-9\s]/g, '-').replace(/\s+/g, '-').toLowerCase();
    this.router.navigate(['/merchant-profile','location',this.data.locationId,'merchantid',this.data.id,locationname,name])
  }
}
