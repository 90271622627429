import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';


interface MerchantProfileCard {
  id:any;
  name: string;
  src: any;
  description: any;
  locationId: any;
  locationName:any;
  address: any;
  activityCount:any;
  reviewed_user_pic:any;
  hasCoupon:any;
  averagestar:any;
}


@Component({
  selector: 'app-new-merchant-profile-card',
  templateUrl: './new-merchant-profile-card.component.html',
  styleUrls: ['./new-merchant-profile-card.component.css']
})
export class NewMerchantProfileCardComponent {
  @Input() data: MerchantProfileCard;
  @Output() dataLocation = new EventEmitter<any>();

  constructor( private router: Router) {}

  openMerchant(){
    this.dataLocation.emit();
    let locationname = this.data.locationName.trim().replace(/[^a-zA-Z0-9\s]/g, '-').replace(/\s+/g, '-').toLowerCase();
    let name = this.data.name.trim().replace(/[^a-zA-Z0-9\s]/g, '-').replace(/\s+/g, '-').toLowerCase();
    this.router.navigate(['/merchant-profile','location',this.data.locationId,'merchantid',this.data.id,locationname,name])
  }
}
