import { Component } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AuthService } from '../auth.service';
import { FormControl, FormBuilder, FormGroup, AbstractControl, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LoginDialogComponent } from '../login-dialog/login-dialog.component';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { VerificationCodeComponent } from '../../verification-code/verification-code.component';
import { Router, ActivatedRoute } from '@angular/router';
import { GoogleLoginProvider, SocialAuthService } from '@abacritt/angularx-social-login';
// import { MerchantLoginDialogueComponent } from '../auth/merchant-login-dialogue/merchant-login-dialogue.component';
// import { MerchantSignupComponent } from '../merchant-signup/merchant-signup.component';
import Swal from 'sweetalert2';


declare var AppleID: {
  auth: {
    init: (arg0: {
      clientId: string; scope: string; redirectURI: string; state: string; nonce: string; usePopup: boolean; //or false defaults to false
    }) => void; signIn: () => any;
  };
};


@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.css']
})
export class SignUpComponent {
  public passwordVisible: boolean = false;
  isSubmitting: boolean = false;
  SignInForm!: FormGroup;
  userAgent:string ="";
  mobileDeviceReady:boolean = false;
  isMobile:boolean = false;
  hostName:any="";

  constructor(public dialog: MatDialog, private formBuilder: FormBuilder,private socialAuthService: SocialAuthService, private route: ActivatedRoute,private router: Router, private authService: AuthService, private signupDialogRef: MatDialogRef<SignUpComponent>, private snackBar: MatSnackBar, private http: HttpClient) {
    this.hostName = window.location.host;
    var raftingDetails = localStorage.getItem('raftingDetails');
    var campingDetails = localStorage.getItem('campingDetails');
    var locationDetails = localStorage.getItem('location');
    var linkFrom = localStorage.getItem('linkFrom');
    var appToken = localStorage.getItem('appToken');
    var affiliateId = localStorage.getItem('affiliateId');
    var trekkingDetails = localStorage.getItem('trekkingDetails');
    var tourDetails = localStorage.getItem('tourDetails');
    var rentalDescription = localStorage.getItem('rentalDescription');
    var bookBikeRentalProvidedBy = localStorage.getItem('bookBikeRentalProvidedBy');
    localStorage.clear();
    if(raftingDetails && raftingDetails != ""){
      localStorage.setItem('raftingDetails',raftingDetails);
      }
    if(campingDetails && campingDetails != ""){
    localStorage.setItem('campingDetails',campingDetails);
    }
    if(locationDetails && locationDetails != ""){
      localStorage.setItem('location',locationDetails);
    }
    if(affiliateId && affiliateId != ""){
      localStorage.setItem('affiliateId',affiliateId);
    }
    if(linkFrom && linkFrom != ""){
      localStorage.setItem('linkFrom',linkFrom);
    }
    if(trekkingDetails && trekkingDetails != ""){
      localStorage.setItem('trekkingDetails',trekkingDetails);
    }
    if(tourDetails && tourDetails != ""){
      localStorage.setItem('tourDetails',tourDetails);
    }
    if(rentalDescription && rentalDescription != ""){
      localStorage.setItem('rentalDescription',rentalDescription);
    }
    if(bookBikeRentalProvidedBy && bookBikeRentalProvidedBy != ""){
      localStorage.setItem('bookBikeRentalProvidedBy',bookBikeRentalProvidedBy);
    }
    localStorage.setItem('appToken', appToken);
   }

  ngOnInit(): void {
    document.addEventListener('deviceready', this.deviceReady, false);
    this.userAgent = window.navigator.userAgent;
    this.SignInForm = this.formBuilder.group({
      email: new FormControl('', [Validators.required, Validators.email]),
      phone: new FormControl('',[Validators.required,this.validatePhoneNumber]),
      firstName: new FormControl('', Validators.required), lastName: new FormControl('', Validators.required),
      password: new FormControl('', Validators.required), changePassword: new FormControl(false)
    });

    if(!this.ifMobileApp()){
    this.socialAuthService.authState.subscribe((user) =>{
      if(user.provider === 'GOOGLE'){
        this.authService.validateGoogleUserSignIn({idToken: user.idToken}).subscribe({
          next:(res)=>{
            this.signupDialogRef.close();
            const queryString = window.location.search;
            const searchParams = new URLSearchParams(queryString);
            const queryParams:any = Object.fromEntries(searchParams.entries());
            let currentUrl = this.router.url.split('?')[0];
            if (queryParams && queryParams.dialogue) {
              queryParams.dialogue = '0';
            }
            this.router.navigate([currentUrl],{ queryParams: queryParams });
            this.snackBar.open('Login Successfully', 'close', { duration: 3000, verticalPosition: 'top' },);
          },
          error:(err)=>{
              this.snackBar.open('Some error occurs.'+ JSON.stringify(err.error.errors) , 'close', { duration: 3000, verticalPosition: 'top' },);
          }
        })
      }
    });
  
    (window as any).handleCredentialResponse = (response:any) => {
      if (response && response.credential){
        this.authService.validateGoogleUserSignIn({idToken:response.credential}).subscribe({
          next:(res)=>{
            this.signupDialogRef.close();
            const queryString = window.location.search;
            const searchParams = new URLSearchParams(queryString);
            const queryParams:any = Object.fromEntries(searchParams.entries());
            let currentUrl = this.router.url.split('?')[0];
            if (queryParams && queryParams.dialogue) {
              queryParams.dialogue = '0';
            }
            this.router.navigate([currentUrl],{ queryParams: queryParams });
            this.snackBar.open('Login Successfully', 'close', { duration: 3000, verticalPosition: 'top' },);
          },
          error:(err)=>{
              this.snackBar.open('Some error occurs.'+ JSON.stringify(err.error.errors) , 'close', { duration: 3000, verticalPosition: 'top' },);
          }
        })
    }
    }
  } else {
    this.isMobile = true;
  }

  }

  loginGoogle() {
    if(this.isMobile) {
      (window as any).plugins.googleplus.login(
        {
          // 'scopes': '... ', // optional, space-separated list of scopes, If not included or empty, defaults to `profile` and `email`.
          'webClientId': '189004330207-8ubso558a7ns63388uhgnl9fjjq1621b.apps.googleusercontent.com', // optional clientId of your Web application from Credentials settings of your project - On Android, this MUST be included to get an idToken. On iOS, it is not required.
          // 'offline': true // optional, but requires the webClientId - if set to true the plugin will also return a serverAuthCode, which can be used to grant offline access to a non-Google server
        },
         (obj: any) => {
            this.authService.validateGoogleUserSignIn({ mobileidToken: obj.idToken }).subscribe({
              next: (res) => {
              this.signupDialogRef.close();
              const queryString = window.location.search;
              const searchParams = new URLSearchParams(queryString);
              const queryParams:any = Object.fromEntries(searchParams.entries());
                let currentUrl = this.router.url.split('?')[0];
                if (queryParams && queryParams.dialogue) {
                  queryParams.dialogue = '0';
                }
                this.router.navigate([currentUrl], { queryParams: queryParams });
                this.snackBar.open('Login Successfully', 'close', { duration: 3000, verticalPosition: 'top' },);
              },
              error: (err) => {
                this.snackBar.open('Some error occurs.' + JSON.stringify(err.error.errors), 'close', { duration: 3000, verticalPosition: 'top' },);
              }
            })
        },
        (msg: string) => {
          this.snackBar.open('Some error occurs.' + msg, 'close', { duration: 3000, verticalPosition: 'top' },);
        }
      );
    }
  }

  

  deviceReady() {
    this.mobileDeviceReady = true;
  }


  ifMobileApp(){
    if(this.userAgent.includes('AdvenzoneMobileApp')){
      return true;
    }else{
      return false;
    }
  }

  ifIosApp(){
    if((window as any).cordova.platformId === "ios"){
      return true;
    }
    return false;
  }

  clickWebLoginApple(){
    Swal.fire({
      title: '',
      text: 'To ensure smooth operation of the application, it is recommended not to obfuscate or hide the email during the login process',
      icon: 'info',
      showCancelButton: true,
      cancelButtonText: 'No',
      confirmButtonText: 'Yes',
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        this.apple()
      } 
      // else if (result.dismiss === Swal.DismissReason.cancel) {
      
      // }
    })
  }

  async apple() {
    try {
      AppleID.auth.init({
                clientId : 'com.advenzone.service',
                scope : 'name email',
                redirectURI : 'https://'+this.hostName+'/applecallback',
                state : 'init',
                nonce : 'test',
                usePopup : true //or false defaults to false
            });
      const data = await AppleID.auth.signIn();
      console.log(JSON.stringify(data))
      const decodedToken = this.parseJwt(data.authorization.id_token);
      if(decodedToken.is_private_email){
        this.snackBar.open("Your Email Id is private. Please don't hide the email.", 'close', { duration: 3000, verticalPosition: 'top' },);
      }else{
        let requestData={};
        if(data.user){
          const userName = data.user;
            requestData = {
              "email": decodedToken.email,
              "firstName": userName.name.firstName,
              "lastName": userName.name.lastName,
              "socialId": decodedToken.sub,
          };
         
        }else{
           requestData = {
            "email": decodedToken.email,
        };
        }
        this.authService.validateWebAppleLogin(requestData).subscribe({
          next: (res) => {
            this.signupDialogRef.close();
            const queryString = window.location.search;
            const searchParams = new URLSearchParams(queryString);
            const queryParams:any = Object.fromEntries(searchParams.entries());
            let currentUrl = this.router.url.split('?')[0];
            if (queryParams && queryParams.dialogue) {
              queryParams.dialogue = '0';
            }
            this.router.navigate([currentUrl], { queryParams: queryParams });
            this.snackBar.open('Login Successfully', 'close', { duration: 3000, verticalPosition: 'top' },);
          },
          error: (err) => {
            this.snackBar.open('Some error occurs.' + JSON.stringify(err.error.errors), 'close', { duration: 3000, verticalPosition: 'top' },);
          }
        })
      }

    } catch (error) {
      console.log("Couldn't find your account. There's no account for that email. Try logging in with a different email.")
    }
  }

  validatePhoneNumber(control: any) {
    const phoneNumber = control.value;
    const phoneNumberPattern = /^([6-9]\d{9})$/; // Regular expression for 10-digit number

    if (phoneNumber && !phoneNumber.match(phoneNumberPattern)) {
      return { invalidPhoneNumber: true };
    }

    return null;
  }

  openVerificationDialog() {
    this.signupDialogRef.close();
    this.dialog.open(VerificationCodeComponent, {
      height: 'fit-content',
      width: 'fit-content',
      disableClose: true,
      data: this.SignInForm.get('phone')?.value

    });
  }

  get f(): { [key: string]: AbstractControl } {
    return this.SignInForm.controls;
  }

  openLoginDialog() {
    if(!this.ifMobileApp()){
      const queryString = window.location.search;
      const searchParams = new URLSearchParams(queryString);
      const queryParams:any = Object.fromEntries(searchParams.entries());
      const currentUrl = this.router.url.split('?')[0];
      let q = Object.keys(queryParams).map(k => k !== 'dialogue' ? `${k}=${queryParams[k]}` : '').filter(k => k !== '').join('&');
      q = q !== '' ? q + '&dialogue=user' : q + `dialogue=user`
      window.location.href = `${currentUrl}?${q}`;
    }else{
      this.signupDialogRef.close();
      const isMobile = window.innerWidth < 768;
      let sizes = {
        height: '90vh',
        width: '50%',
        maxWidth: 'unset',
        panelClass: 'bg-login-class'
      };
      if (isMobile) {
        sizes = {
          width: '100%',
          maxWidth: 'unset',
          height: '100%',
          panelClass: 'bg-login-class'
        }
      }
      this.dialog.open(LoginDialogComponent, {
        ...sizes,
        disableClose: true,
        data: {
  
        },
      });
    }
   
  }
  closeLoginDialog() {
    this.signupDialogRef.close();
  }

  onSignIn() {
    this.isSubmitting = true;
    if (this.SignInForm.invalid) {
      return;
    }
    if (this.SignInForm.valid) {
      const phone = this.SignInForm.get('phone')?.value;
      const requestbody = {
        phone: phone
      }

      this.authService.SignUp(this.SignInForm.value).subscribe({
        next: (res: any) => {

          this.http.post(environment.apiUrl + `/users/phoneSendCode`, requestbody).subscribe((data: any) => {
            this.openVerificationDialog()

          });
        },
        error: (err: any) => {
          this.isSubmitting = false;
          let error=[];
          let keys =Object.keys(err.error.errors);
              keys.forEach(x=>{
                error.push(err.error.errors[x][0]);
              })
          this.snackBar.open('Some error occurs while creating user. ' +  error.join('\r\n'), 'Dismiss', { duration: 3000, verticalPosition: 'top' });
        }
      });
    } else {

    }
  }


  togglePasswordVisibility() {
    this.passwordVisible = !this.passwordVisible;
  }
  
  loginMobileApple(){
    if (this.isMobile) {
      (window as any).cordova.plugins.SignInWithApple.signin(
        { requestedScopes: [0, 1] },
        (data: any) => {
          const decodedToken = this.parseJwt(data.identityToken);
          if (decodedToken.is_private_email) {
            this.snackBar.open("Your Email Id is private. Please don't hide the email.", 'close', { duration: 3000, verticalPosition: 'top' },);
          } else {
            let requestData = {};
            if (data.fullName.givenName != "") {
              requestData = {
                "email": decodedToken.email,
                "firstName": data.fullName.givenName,
                "lastName": data.fullName.familyName
              };

            } else {
              requestData = {
                "email": decodedToken.email,
              };
            }
            this.authService.validateWebAppleLogin(requestData).subscribe({
              next: (res) => {
                this.signupDialogRef.close();
                const queryString = window.location.search;
                const searchParams = new URLSearchParams(queryString);
                const queryParams:any = Object.fromEntries(searchParams.entries());
                let currentUrl = this.router.url.split('?')[0];
                if (queryParams && queryParams.dialogue) {
                  queryParams.dialogue = '0';
                }
                this.router.navigate([currentUrl], { queryParams: queryParams });
                this.snackBar.open('Login Successfully', 'close', { duration: 3000, verticalPosition: 'top' },);
              },
              error: (err) => {
                this.snackBar.open('Some error occurs.' + JSON.stringify(err.error.errors), 'close', { duration: 3000, verticalPosition: 'top' },);
              }
            })
          }
        },
        (err: any) => {
          this.snackBar.open('Some error occurs.' + JSON.stringify(err), 'close', { duration: 3000, verticalPosition: 'top' },);
        }
      )

    }
  }

  parseJwt (token:any) {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
};

}
