import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, map } from 'rxjs';
import { environment } from 'src/environments/environment';
import jwtDecode from 'jwt-decode';


export interface loginResponse {
  locations: any;
  token: string,
 
}

@Injectable({
  providedIn: 'root'
})

export class AuthService {

  searchKey:string =''
  public accessToken: BehaviorSubject<any> = new BehaviorSubject(null);
  public userName: BehaviorSubject<any>= new BehaviorSubject(null);
  userInfo: BehaviorSubject<any> = new BehaviorSubject(null);
  UserType: BehaviorSubject<any> = new BehaviorSubject(null);
  viewHeader:boolean=true;

  isSubmitting = false;
  
  constructor(private http: HttpClient) {
    this.accessToken.next(localStorage.getItem('accessToken') || null);
    this.userName.next(localStorage.getItem('userName') || null)
    if (localStorage.getItem('accessToken')) {
       this.userInfo.next(this.decodeToken(this.accessToken.getValue()))
      }
    this.UserType.next(localStorage.getItem('UserType') || null)
  }


  decodeToken(token: string) { 
    if(token&&token!=='undefined')
    return jwtDecode(token);
 }

  login(credentials: { email: string, password: string }) {
    let data:any = credentials;
        if(localStorage.getItem('appToken') && localStorage.getItem('appToken') != null){
          data.appToken = localStorage.getItem('appToken');
        }
    return this.http.post<loginResponse>(environment.apiUrl + '/users/login', data, { responseType: 'json' }).pipe(map((data: any) => {
      localStorage.setItem('accessToken', data.token);
      localStorage.setItem('userName',data?.users?.firstname);
      localStorage.setItem('UserType',"user");
      this.UserType.next('user');
      this.userName.next(data?.users?.firstname)
      this.userInfo.next(this.decodeToken(data.token));
    }));
  }

  guestLogin(credentials: { phone: string, phoneCode: string }) {
    let data:any = credentials;
        if(localStorage.getItem('appToken') && localStorage.getItem('appToken') != null){
          data.appToken = localStorage.getItem('appToken');
        }
    return this.http.post<loginResponse>(environment.apiUrl + '/users/phoneValidateCodeForGuest', data, { responseType: 'json' }).pipe(map((data: any) => {
      let token:any= this.decodeToken(data.token)
      localStorage.setItem('accessToken', data.token);
      localStorage.setItem('userName',token.firstname);
      localStorage.setItem('UserType',"user");
      this.UserType.next('user');
      this.userName.next(token.firstname)
      this.userInfo.next(this.decodeToken(data.token));
    }));
  }

  merchantlogin(credentials: { email: string, password: string }) {
    let data:any = credentials;
        if(localStorage.getItem('appToken') && localStorage.getItem('appToken') != null){
          data.appToken = localStorage.getItem('appToken');
        }
    return this.http.post<loginResponse>(environment.apiUrl + '/merchants/login', data, { responseType: 'json' }).pipe(map((data: any) => {
      localStorage.setItem('accessToken', data.token);
      localStorage.setItem('userName',data?.merchants?.name);
      localStorage.setItem('UserType',"merchant");
      this.UserType.next('merchant');
      this.userName.next(data?.merchants?.name)
      this.userInfo.next(this.decodeToken(data.token));
    }));
  }

  partnerlogin(credentials: { email: string, password: string }) {
    return this.http.post<loginResponse>(environment.apiUrl + '/partners/login', credentials, { responseType: 'json' }).pipe(map((data: any) => {
      localStorage.setItem('accessToken', data.token);
      localStorage.setItem('userName',data?.partners?.name);
      localStorage.setItem('UserType',"partner");
      this.UserType.next('partner');
      this.userName.next(data?.partners?.name)
      this.userInfo.next(this.decodeToken(data.token));
    }));
  }
  

  logout() {
  this.viewHeader =true;  
  let userType = localStorage.getItem('UserType');
  let appToken = localStorage.getItem('appToken');
  if(userType == 'user'){
    if(appToken && appToken != null){
      this.http.post(environment.apiUrl + '/users/logout', {id: this.userInfo.value.id, appToken:appToken }, { responseType: 'json' }).subscribe((data)=>{
      })
    }
  }else if(userType == 'merchant'){
    if(appToken && appToken != null){
      this.http.post(environment.apiUrl + '/merchants/logout', {id: this.userInfo.value.id, appToken:appToken }, { responseType: 'json' }).subscribe((data)=>{
      })
    }
  }
  this.UserType.next('');
  var locationDetails = localStorage.getItem('location');
  var appTokens = localStorage.getItem('appToken');
  localStorage.clear();
  if(locationDetails && locationDetails != ""){
    localStorage.setItem('location',locationDetails);
  }
  localStorage.setItem('appToken', appTokens);
  }
  SignUp(credentials: { email: string, password: string,  firstName: string, lastnName: string,changePassword:boolean }) {
    return this.http.post<loginResponse>(environment.apiUrl + '/users/signup', credentials, { responseType: 'json' }).pipe(map((data: any) => {
     
    }));
  }

  getDataById(id: any) {
    return this.http.get(environment.apiUrl + `/users/get?id=${id}`);
  }


  update(credentials: { id :number,firstName: string, lastName: string, email: string }) { 
    return this.http.post<loginResponse>(environment.apiUrl + '/users/update', credentials, { responseType: 'json' }).pipe(map((data: any) => {

      localStorage.setItem('userName',data?.users?.firstname);
      this.userName.next(data?.users?.firstname);
  })); }

  validateGoogleUserSignIn(token:any){
    let data:any = token;
        if(localStorage.getItem('appToken') && localStorage.getItem('appToken') != null){
          data.appToken = localStorage.getItem('appToken');
        }
    return this.http.post<loginResponse>(environment.apiUrl + '/users/googleSignIn', data, { responseType: 'json' }).pipe(map((data: any) => {
      localStorage.setItem('accessToken', data.token);
      localStorage.setItem('userName',data?.users?.firstname);
      localStorage.setItem('UserType',"user");
      this.UserType.next('user');
      this.userName.next(data?.users?.firstname)
      this.userInfo.next(this.decodeToken(data.token));
    }));
  }

  validateWebAppleLogin(data:any){
    let datas:any = data;
    if(localStorage.getItem('appToken') && localStorage.getItem('appToken') != null){
      data.appToken = localStorage.getItem('appToken');
    }
    return this.http.post<loginResponse>(environment.apiUrl + '/users/webAppleSignIn', datas, { responseType: 'json' }).pipe(map((data: any) => {
      localStorage.setItem('accessToken', data.token);
      localStorage.setItem('userName',data?.users?.firstname);
      localStorage.setItem('UserType',"user");
      this.UserType.next('user');
      this.userName.next(data?.users?.firstname)
      this.userInfo.next(this.decodeToken(data.token));
    }));
  }

  validateGoogleMerchantSignIn(token:any){
    return this.http.post<loginResponse>(environment.apiUrl + '/merchants/googleSignIn', token, { responseType: 'json' }).pipe(map((data: any) => {
      localStorage.setItem('accessToken', data.token);
      localStorage.setItem('userName',data?.users?.firstname);
      localStorage.setItem('UserType',"user");
      this.UserType.next('user');
      this.userName.next(data?.users?.firstname)
      this.userInfo.next(this.decodeToken(data.token));
    }));
  }

}