<!-- footer -->

<div
  *ngIf="!ifMobileApp() && !router.url.includes('agentbooking')  && !router.url.includes('partnerbooking')  && (!bookingSource || UserType == 'partner')">
  <footer class="background">
    <div class="mx-md-5 mx-2 py-4 px-md-4 px-2">
      <div class="row">
        <div class="col-lg-3 col-md-6 col-12">
          <div class="">
            <div class="text-md-center" style="width: fit-content;">
              <img src="/assets/new-logo.svg" width="45px" alt="logo">
              <h1 class="logo mt-2 mb-0">Advenzone</h1>
            </div>
            <p class="footer-content mt-2">Welcome to Advenzone, your ultimate adventure activities hub! We're here to
              bring
              the thrill and excitement of epic adventures right to your fingertips.</p>
          </div>
        </div>
        <hr class="d-md-none d-block mb-0">
        <div class="col-lg-3 col-md-6  col-12">
          <h1 class=" mb-0 heading  d-md-block d-none">Quick Links</h1>
          <!-- Button to toggle dropdown (collapsible) -->
          <div class="d-flex justify-content-between my-3 d-md-none" (click)="collapseFooter('quicklinks')"  data-bs-toggle="collapse" data-bs-target="#quickLinks" aria-expanded="false" aria-controls="quickLinks">
            <h1 class="heading d-md-none  mb-0">
              Quick Links 
            </h1>
            <img src="/assets/footer/down-arrow.svg" *ngIf="fromQuickLinksCollapsed" width="20px" alt="downArrow" class="ms-2" />
            <img src="/assets/footer/up-arrow.svg" *ngIf="!fromQuickLinksCollapsed" width="20px" alt="updarrow" class="ms-2" />
          </div>

          <div class="collapse d-md-none" id="quickLinks">
            <ul class="list-unstyled mb-0  mt-3" *ngIf="UserType !== 'merchant' && UserType !== 'partner'"
            style="line-height:3em">
            <li>
              <a href="javascript:void(0)" (click)="navigateHome()" class="text-decoration-none  title">Home</a>
            </li>
            <li *ngIf="UserType !== 'user'" >
              <a href="javascript:void(0)" (click)="openProfile()"
                class="text-decoration-none title">Register/Sign in</a>
            </li>
            <li *ngIf="UserType !== 'user'" >
              <a href="javascript:void(0)" (click)="guestLogin()"
                class="text-decoration-none title">Guest Login</a>
            </li>
            <li *ngIf="UserType !== 'user'" >
              <a href="javascript:void(0)" (click)="openMerchantLoginDialogue()"
                class="text-decoration-none title">Merchant Login</a>
            </li>
            <li *ngIf="UserType !== 'user'" >
              <a href="javascript:void(0)" (click)="loginPartner()"
                class="text-decoration-none title">Partner Login </a>
            </li>
          </ul>
          <ul class="list-unstyled mb-0  mt-3" *ngIf="UserType === 'merchant'" style="line-height:3em">
            <li>
              <a href="/merchant-bookings" class="text-decoration-none  title">Dashboard</a>
            </li>
            <li>
              <a href="/merchant-reviews" class="text-decoration-none title">Reviews</a>
            </li>
            <li>
              <a href="/full-merchant-profile" class="text-decoration-none title">Profile</a>
            </li>
          </ul>
          </div>

          <ul class="list-unstyled mb-0  mt-3 d-none d-md-block" *ngIf="UserType !== 'merchant' && UserType !== 'partner'"
            style="line-height:3em">
            <li>
              <a href="javascript:void(0)" (click)="navigateHome()" class="text-decoration-none  title">Home</a>
            </li>
            <li  *ngIf="UserType !== 'user'">
              <a href="javascript:void(0)" (click)="openProfile()"
                class="text-decoration-none title">Register/Sign in</a>
            </li>
            <li  *ngIf="UserType !== 'user'">
              <a href="javascript:void(0)" (click)="guestLogin()"
                class="text-decoration-none title">Guest Login</a>
            </li>
            <li  *ngIf="UserType !== 'user'">
              <a href="javascript:void(0)" (click)="openMerchantLoginDialogue()"
                class="text-decoration-none title">Merchant Login</a>
            </li>
            <li  *ngIf="UserType !== 'user'">
              <a href="javascript:void(0)" (click)="loginPartner()"
                class="text-decoration-none title">Partner Login </a>
            </li>
          </ul>
          <ul class="list-unstyled mb-0  mt-3 d-none d-md-block" *ngIf="UserType === 'merchant'" style="line-height:3em">
            <li>
              <a href="/merchant-bookings" class="text-decoration-none  title">Dashboard</a>
            </li>
            <li>
              <a href="/merchant-reviews" class="text-decoration-none title">Reviews</a>
            </li>
            <li>
              <a href="/full-merchant-profile" class="text-decoration-none title">Profile</a>
            </li>
          </ul>
        </div>
        <hr class="d-md-none d-block mb-0">
        <div class="col-lg-3 col-md-6 col-12">
          <h1 class="mb-0 heading  d-md-block d-none">Support</h1>
          <!-- Button to toggle dropdown (collapsible) -->
          <div class="d-flex justify-content-between my-2 d-md-none" (click)="collapseFooter('support')"  data-bs-toggle="collapse" data-bs-target="#supportLinks" aria-expanded="false" aria-controls="supportLinks">
            <h1 class="heading d-md-none  mb-0">
              Support 
            </h1>
            <img src="/assets/footer/down-arrow.svg" *ngIf="fromSupportCollapsed" width="20px" alt="downArrow" class="ms-2" />
            <img src="/assets/footer/up-arrow.svg" *ngIf="!fromSupportCollapsed" width="20px" alt="updarrow" class="ms-2" />
          </div>
           
          <!-- Collapsible links for small screens -->
          <div class="collapse d-md-none" id="supportLinks">
            <ul class="list-unstyled mb-0 mt-3" style="line-height: 3em;">
              <li><a href="/terms-condition" class="text-decoration-none title">Terms & Conditions</a></li>
              <li><a href="/about-us" class="text-decoration-none title">About Us</a></li>
              <li><a href="/faq" class="text-decoration-none title">FAQ</a></li>
              <li><a href="/cancellation-policy" class="text-decoration-none title">Cancellation Policy</a></li>
              <li><a href="/privacy-policy" class="text-decoration-none title">Privacy Policy</a></li>
              <li><a href="/contact-us" class="text-decoration-none title">Contact Us</a></li>
            </ul>
          </div>
        
          <!-- Links for medium and large screens -->
          <ul class="list-unstyled mb-0 mt-3 d-none d-md-block" style="line-height: 3em;">
            <li><a href="/terms-condition" class="text-decoration-none title">Terms & Conditions</a></li>
            <li><a href="/about-us" class="text-decoration-none title">About Us</a></li>
            <li><a href="/faq" class="text-decoration-none title">FAQ</a></li>
            <li><a href="/cancellation-policy" class="text-decoration-none title">Cancellation Policy</a></li>
            <li><a href="/privacy-policy" class="text-decoration-none title">Privacy Policy</a></li>
            <li><a href="/contact-us" class="text-decoration-none title">Contact Us</a></li>
          </ul>
        </div>
        <hr class="d-md-none d-block my-2">
        <div class="col-lg-3 col-md-6 col-12">
          <h1 class="mb-0 heading">Download Our App</h1>

          <div class="d-inline-grid mt-3">
            <a class="btn px-0" (click)="openUrl('https://apps.apple.com/in/app/advenzone/id6451099198')"><img
                src="/assets/apple-available.png" alt="appleIcon"></a>
            <a class="btn px-0 "
              (click)="openUrl('https://play.google.com/store/apps/details?id=com.advenzone')"><img
                src="/assets/playstore-available.png" alt="playstore"></a>
          </div>
          <h2 class="follow-us">Follow us</h2>
          <div class="d-flex justify-content-start mobile-footer">
            <a href="https://www.facebook.com/AdvenzoneApp" target="_blank">
              <img src="/assets/fb.svg" width="38px" alt="fb" class="ms-0 mt-2"></a>
            <a href="https://www.instagram.com/advenzone/" target="_blank">
              <img src="/assets/instagram.svg" width="38px" alt="instagram" class="ms-2 mt-2"></a>
            <a href="https://www.linkedin.com/company/advenzone/" target="_blank">
              <img src="/assets/linkedIn.svg" width="38px" alt="linkedIn" class="ms-2 mt-2"></a>
          </div>
        </div>
      </div>
    </div>
    <!-- Copyright -->
    <div class="text-center p-3" style="background-color: white;">
      <a class="text-black text-decoration-none d-flex justify-content-center align-items-end" href="#">www.{{hostName}}
        | © copyright 2023 | All Rights Reserved |
        Made with Thrill <img src="/assets/thrill-factor.gif" class="ms-1" width="30px" height="30px"
          alt="thrillFactor"></a>
    </div>
    <!-- Copyright -->
  </footer>
</div>

<div *ngIf="router.url.includes('partnerbooking') || bookingSource && UserType != 'partner'">
  <footer class="text-center text-lg-start" style="background: #3EBDC6;">
    <!-- Grid container -->
    <div class="px-4 p-2">
      <!--Grid row-->
      <div class="row">
        <div class=" col-md-3 text-start mb-md-0">
          <div style="width: fit-content;" class="text-center">
            <img src="/assets/new-logo.svg" width="35px" alt="logo">
            <h5 class="text-dark title mt-2"><b>Advenzone</b></h5>
          </div>
        </div>
        <div class="col-lg-6 col-md-6 text-start mb-md-0">
          <div class="d-md-flex d-block mt-3 align-items-center">
            <h5 class="fw-bold text-light">Download Our App</h5>
            <a class="btn" (click)="openUrl('https://apps.apple.com/in/app/advenzone/id6451099198')"><img
                src="/assets/apple-available.png" alt="appleIcon"></a>
            <a class="btn" (click)="openUrl('https://play.google.com/store/apps/details?id=com.advenzone')"><img
                src="/assets/playstore-available.png" alt="playstore"></a>
          </div>
        </div>
        <div class="col-lg-3 col-md-6 text-md-end text-start mb-md-0">
          <div class="h-100 d-flex align-items-center justify-content-end">
            <button class="btn text-dark my-2 me-2" (click)="clear_localStorage()">Logout</button>
          </div>
        </div>
      </div>
    </div>
  </footer>
</div>