import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, Inject, ViewChild } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-mobile-reviews-show-more',
  templateUrl: './mobile-reviews-show-more.component.html',
  styleUrls: ['./mobile-reviews-show-more.component.css']
})
export class MobileReviewsShowMoreComponent {
  
  totalReview: any = 0;
  avgReview: any = 0;
  reviews:any =[];
  activityName:any ="";
  page:number = 2;
  activityid:any;
  loadMoreReviews:boolean = true;
  merchantId:any;

  @ViewChild('dialogContent', { static: false }) dialogContent: ElementRef;

  constructor(@Inject(MAT_BOTTOM_SHEET_DATA) public data: any, public PopupReviewsDialogue: MatBottomSheetRef<MobileReviewsShowMoreComponent>, private datePipe: DatePipe,  private http: HttpClient) {
        this.totalReview = data.totalReview;
        this.avgReview = data.avgReview;
        this.activityName = data.activityName;
        this.activityid = data.activityid;
        this.merchantId = data.merchantId;
        this.reviews = data.reviews;
        this.loadReviews();
  }

  
  ngAfterViewInit() {
    if (this.dialogContent) {
      this.dialogContent.nativeElement.addEventListener('scroll', this.onScroll.bind(this));
    }
  }

  onScroll(event: any) {
    const scrollTop = event.target.scrollTop;
    const scrollHeight = event.target.scrollHeight;
    const clientHeight = event.target.clientHeight;

    // If the user has scrolled to the bottom
    if (scrollTop + clientHeight >= scrollHeight) {
      if(this.loadMoreReviews){
        this.loadReviews(); 
      } 
    }
  }

  
  convertTimestampToDateFormat(timestamp: any): string {
    timestamp = Number(timestamp)
    const date = new Date(timestamp);
    const formattedDate = this.datePipe.transform(date, 'MMMM dd, yyyy');
    return formattedDate || '';
  }

  
  loadReviews() {
    this.loadMoreReviews = false;
    let data:any = { count: 3, page: this.page, sortColumn: 'id' };
    if(this.activityid){
      data.dataFor = 'activity';
      data.dataForId = this.activityid;
    }else{
      data.dataFor = 'merchant';
      data.dataForId = this.merchantId;
    }
    this.http.post(environment.apiUrl + `/reviews/list`, data, { responseType: 'json' }).subscribe(async (data: any) => {
      if (data.status === "SUCCESS") {
        data.reviews.forEach(async (review: any) => {
          review.src = "";
          try {
            this.http.get(environment.apiUrl + `/uploads/listbydatafor?datafor=userprofile&dataforid=${review.createdby}`).subscribe((data: any) => {
              if (data.status === "SUCCESS") {
                for (const upload of data.uploads) {
                  this.http.get(environment.apiUrl + `/uploads/getThumbnail?id=${upload.id}`).subscribe((res: any) => {
                    if (res.status === "SUCCESS") {
                      review.src = res.upload.thumbnail;
                    }
                  });
                }
              }
            })
          } catch (error) {
            console.log(error)
          }
        })
        this.reviews.push(...data.reviews);
        if (Number(data.total) > this.reviews.length) {
          this.loadMoreReviews = true;
          this.page += 1;
        } else {
          this.loadMoreReviews = false;
        }
      }
    });
  }

}
