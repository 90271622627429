import { HttpClient } from '@angular/common/http';
import { Component, Inject } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { Router } from '@angular/router';
import { ActivityFilterService } from 'src/app/services/activity-filter.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-categories-popup',
  templateUrl: './categories-popup.component.html',
  styleUrls: ['./categories-popup.component.css']
})
export class CategoriesPopupComponent {

  locationId: any;
  indexData: any = {};
  locationName: any = "";
  activityFilterSubscription:any;
  activityFilterSubscription2:any;
  category_labels:any =[];
  topAdventuristsCount:any =0;
  default_category_labels:any=[];

  constructor(private bottomSheetRef: MatBottomSheetRef<CategoriesPopupComponent>, @Inject(MAT_BOTTOM_SHEET_DATA) public data: any, private http: HttpClient, private router: Router, private activityFilterService: ActivityFilterService) {
    this.activityFilterSubscription = this.activityFilterService.category_index_count_data$.subscribe((data:any) => {
      this.indexData = data;
    })
    this.activityFilterSubscription2 = this.activityFilterService.category_label_data$.subscribe((data:any) => {
      if(data && data != null){
        this.category_labels = data.category_labels;
        this.topAdventuristsCount = data.topAdventuristsCount;
        this.default_category_labels = data.default_category_labels;
      }else{
        this.category_labels = [];
        this.topAdventuristsCount = 0;
        this.default_category_labels = [];
      }
    })
  }

  ngOnDestroy() {
    if (this.activityFilterSubscription) {
      this.activityFilterSubscription.unsubscribe();
    }
    if (this.activityFilterSubscription2) {
      this.activityFilterSubscription2.unsubscribe();
    }
  }

  
  showPhase(category: any) {
    sessionStorage.setItem('active_category', category);
    let location = localStorage.getItem('location');
    this.locationName = JSON.parse(location).locationname;
    this.locationId = JSON.parse(location).id;
    let name = this.locationName.trimEnd().toLowerCase().replaceAll(" ", "-");
    const currentUrl = this.router.url;
    if (currentUrl.includes('home/location')) {
      this.activityFilterService.setActiveCategoryData({ active_category: category });
    }else{
      this.activityFilterService.setActiveCategoryData({ active_category: category });
       this.router.navigate(['/home', 'location', this.locationId, name]);
    }
    this.bottomSheetRef.dismiss();
  }

  close(): void {
    this.bottomSheetRef.dismiss(); // This closes the bottom sheet
  }
}
