import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';


interface details {
  id: any;
  name: any;
  src: any;
  hasCoupon: any;
  min_price:any;
  locationid:any;
  availability:any;
  locationname:any;
}

@Component({
  selector: 'app-bike-rental-card',
  templateUrl: './bike-rental-card.component.html',
  styleUrls: ['./bike-rental-card.component.css']
})
export class BikeRentalCardComponent {
  @Input() data: details;

  constructor(private router: Router) { }

  openBikeRental(){
    sessionStorage.setItem('userData', JSON.stringify({category:"bike-rental"}));
    let name = this.data.locationname.trimEnd().toLowerCase().replaceAll(" ", "-");
    this.router.navigate(['/home', 'location', this.data.locationid, name]);
  }

}
