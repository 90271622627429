import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { EnquiryFormComponent } from 'src/app/enquiry-form/enquiry-form.component';
import { InfoComponent } from 'src/app/info/info.component';

interface details {
  id: any;
  name: any;
  category: any;
  src: any;
  raftingkm: any;
  rating: any;
  hasCoupon: any;
  description:any;
  min_price:any;
  locationid:any;
  locationname:any;
  difficulty:any;
  totaldistance:any;
  numberofdays:any;
  slot_available:any;
}

@Component({
  selector: 'app-tour-card',
  templateUrl: './tour-card.component.html',
  styleUrls: ['./tour-card.component.css']
})
export class TourCardComponent {
  @Input() data: details;
  
  constructor(public dialog: MatDialog, private router: Router) {}

  openInfoDialog(event:any) {
    event.stopPropagation();
    const isMobile = window.innerWidth < 768;
    let sizes = {
      height: '500px',
      width: '50%',
      maxWidth: 'unset',
    };
    if (isMobile) {
      sizes = {
        width: '100%',
        maxWidth: 'unset',
        height: '100%',
      }
    }
    let input : any ={};
        input.id = this.data.id;
        input.description = this.data.description;
        input.datafrom = 'tour',
        input.locationid = this.data.locationid;

    this.dialog.open(InfoComponent, {
      ...sizes,
      disableClose: true,
      data: input,
    });
  }

  
  openActivityPage(){
    let location = this.data.locationname.trimEnd().toLowerCase().replaceAll(" ", "-");
    let name = this.data.name.trimEnd().toLowerCase().replaceAll(" ", "-");
    this.router.navigate(['/location',this.data.locationid,'package-tour-detail',this.data.id,location,name])
  }

  openEnquiryDialog(){
      const isMobile = window.innerWidth < 768;
      let sizes = {
        height: '87%',
        width: '33%',
        maxWidth: 'unset',
      };
      if (isMobile) {
        sizes = {
          width: '100%',
          maxWidth: 'unset',
          height: '100%',
        };
      }
  
      this.dialog.open(EnquiryFormComponent, {
        ...sizes,
        disableClose: true,
        data: {
          datafrom: this.data.name,
          activityType: "0",
        },
      });
  }
}
